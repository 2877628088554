/**
 * Styled Checkboxes/Radiobuttons (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2014 flatlogic.com
 * @license MIT
 * @author flatlogic.com
 * @author Heiko Pfefferkorn (https://i-fabrik.de, Modifications 20160629)
 */

/* =============================================================================
 *
 * Checkboxen
 *
 * ========================================================================== */

@mixin checkbox-variant($parent, $bg-color, $border-color : $bg-color) {
	#{$parent} input[type="checkbox"]:checked + label,
	#{$parent} input[type="radio"]:checked + label {
		&::before {
			background-color : $bg-color;
			//border-color     : $border-color;
		}

		&::after { color : #fff; }
	}
}

@mixin checkbox-variant-indeterminate($parent, $bg-color, $border-color : $bg-color) {
	#{$parent} input[type="checkbox"]:indeterminate + label,
	#{$parent} input[type="radio"]:indeterminate + label {
		&::before {
			background-color : $bg-color;
			//border-color     : $border-color;
		}

		&::after { background-color : #fff; }
	}
}

.checkbox-styled {
	padding-left : $cbs-padding;

	> label {
		$-top-offset : ceil($cbs-size / 2);

		cursor         : $cursor-pointer;
		display        : inline-block;
		padding-left   : $cbs-label-padding;
		position       : relative;
		vertical-align : middle;

		&::before {
			background-color : $cbs-bg-color;
			border           : $cbs-border-width $cbs-border-style $cbs-border-color;
			border-radius    : $cbs-border-radius;
			content          : "";
			display          : inline-block;
			height           : $cbs-size;
			left             : 0;
			margin-left      : -#{$cbs-padding};
			margin-top       : -#{$-top-offset};
			position         : absolute;
			top              : 50%;
			transition       : border 0.15s ease-in-out, color 0.15s ease-in-out;
			width            : $cbs-size;
		}

		&::after {
			color        : $cbs-color;
			display      : inline-block;
			font-size    : $cbs-font-size;
			height       : $cbs-size;
			left         : 0;
			line-height  : $cbs-size;
			margin-left  : -#{$cbs-padding};
			margin-top   : -#{$-top-offset};
			overflow     : hidden;
			padding-left : 0;
			position     : absolute;
			text-align   : center;
			top          : 50%;
			width        : $cbs-size;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		opacity : 0;
		z-index : 1;

		&:focus + label::before {
			@include tab-focus();
		}

		&:checked + label::after {
			font-family : $cbs-icon-font-family;
			content     : $cbs-icon-check;
		}

		&:indeterminate + label::after {
			display          : block;
			content          : "";
			width            : 10px;
			height           : 3px;
			background-color : #555;
			border-radius    : 2px;
			margin-left      : -16.5px;
			margin-top       : 7px;
		}

		&:disabled + label {
			opacity : .65;

			&::before {
				background-color : $input-bg-color-disabled;
				cursor           : not-allowed;
			}
		}

	}

	&.checkbox-circle label::before { border-radius : 50%; }

	&.checkbox-inline { margin-top : 0; }

	@include checkbox-variant('&.is-primary', $brand-primary);
	@include checkbox-variant('&.is-secondary', $brand-secondary);
	@include checkbox-variant('&.is-tertimary', $brand-tertiary);
	@include checkbox-variant('&.is-danger', $brand-danger);
	@include checkbox-variant('&.is-invalid', $brand-invalid);
	@include checkbox-variant('&.is-info', $brand-info);
	@include checkbox-variant('&.is-warning', $brand-warning);
	@include checkbox-variant('&.is-success', $brand-success);

	@include checkbox-variant-indeterminate('&.is-primary', $brand-primary);
	@include checkbox-variant-indeterminate('&.is-secondary', $brand-secondary);
	@include checkbox-variant-indeterminate('&.is-tertimary', $brand-tertiary);
	@include checkbox-variant-indeterminate('&.is-danger', $brand-danger);
	@include checkbox-variant-indeterminate('&.is-invalid', $brand-invalid);
	@include checkbox-variant-indeterminate('&.is-info', $brand-info);
	@include checkbox-variant-indeterminate('&.is-warning', $brand-warning);
	@include checkbox-variant-indeterminate('&.is-success', $brand-success);
}

/* =============================================================================
 *
 * Radiobuttons
 *
 * ========================================================================== */

@mixin radio-variant($parent, $bg-color, $border-color : $bg-color) {
	#{$parent} input[type="radio"] {
		+ label {
			//color : $bg-color;

			&::after { background-color : $bg-color; }
		}

		&:checked + label {
			//color : $bg-color;

			&::before { border-color : $border-color; }

			&::after { background-color : $bg-color; }
		}
	}
}

.radio-styled {
	padding-left : $rbs-padding;

	label {
		cursor         : $cursor-pointer;
		display        : inline-block;
		vertical-align : middle;
		position       : relative;
		padding-left   : $rbs-label-padding;

		&::before {
			$-top-offset : ceil($rbs-size / 2);

			background-color : $rbs-bg-color;
			border           : $rbs-border-width $rbs-border-style $rbs-border-color;
			border-radius    : 50%;
			content          : "";
			display          : inline-block;
			height           : $rbs-size;
			left             : 0;
			margin-left      : -#{$rbs-padding};
			margin-top       : -#{$-top-offset};
			position         : absolute;
			top              : 50%;
			transition       : border 0.15s ease-in-out;
			width            : $rbs-size;
		}

		&::after {
			$-top-offset  : ceil($rbs-size-dot / 2);
			$-left-offset : ceil(($rbs-size - $rbs-size-dot) / 2);

			transform        : scale(0, 0);
			background-color : $rbs-color;
			border-radius    : 50%;
			content          : " ";
			display          : inline-block;
			height           : $rbs-size-dot;
			left             : $-left-offset;
			margin-left      : -#{$rbs-padding};
			margin-top       : -#{$-top-offset};
			position         : absolute;
			top              : 50%;
			transition       : transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
			width            : $rbs-size-dot;
		}
	}

	input[type="radio"] {
		opacity : 0;
		z-index : 1;

		&:focus + label::before {
			@include tab-focus();
		}

		&:checked + label::after { transform : scale(1, 1); }

		&:disabled + label {
			opacity : .65;

			&::before { cursor : $cursor-not-allowed; }
		}

	}

	&.radio-inline { margin-top : 0; }

	@include radio-variant('&.is-primary', $brand-primary, $rbs-border-color);
	@include radio-variant('&.is-secondary', $brand-secondary, $rbs-border-color);
	@include radio-variant('&.is-tertimary', $brand-tertiary, $rbs-border-color);
	@include radio-variant('&.is-danger', $brand-danger, $rbs-border-color);
	@include radio-variant('&.is-invalid', $brand-invalid, $rbs-border-color);
	@include radio-variant('&.is-info', $brand-info, $rbs-border-color);
	@include radio-variant('&.is-warning', $brand-warning, $rbs-border-color);
	@include radio-variant('&.is-success', $brand-success, $rbs-border-color);
}

input[type="checkbox"],
input[type="radio"] {
	&.styled:checked + label:after {
		font-family : $cbs-icon-font-family;
		content     : $cbs-icon-check;
	}

	.styled:checked + label {
		&::before { color : #fff; }

		&::after { color : #fff; }
	}
}

/* =============================================================================
 *
 * Global
 *
 * ========================================================================== */

.checkbox-styled.checkbox-inline,
.radio-styled.radio-inline {
	& + & {
		margin-top  : 0;
		margin-left : $cbs-rbs-inline-margin;
	}
}