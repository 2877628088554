/**
 * Video, Audio (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =========================================================================
 *
 * Audio, Video.
 *
 * -------------------------------------------------------------------------
 * Unsichtbar setzen bis der JS-Part zum Zuge kommt. Er stellt die
 * Sichtbarkeit dann wieder her (Vermeidung von unschoenen Browsermeldungen).
 * ====================================================================== */

//audio, video { visibility: hidden; }

video { background-color: transparent; }

/* -------------------------------------------------------------------------
 * Video
 * ---------------------------------------------------------------------- */


.figure {
	background-color : transparent;
	border           : 0 none;
	border-radius    : 0;
	display          : inline-block;
	margin           : $thumbnail-align-margin-vertical 0;
	padding          : 0;
	position         : relative;
	width            : $thumbnail-size;

	> .figure-media {
		&.embed-responsive {
			&-16by9 { padding-bottom : calc-aspect-ratio(16, 9); }

			&-4by3 { padding-bottom : calc-aspect-ratio(4, 3); }

			&-3by4 { padding-bottom : calc-aspect-ratio(3, 4); }

			&-3by2 { padding-bottom : calc-aspect-ratio(3, 2); }

			&-2by3 { padding-bottom : calc-aspect-ratio(2, 3); }

			&-square { padding-bottom : calc-aspect-ratio(1, 1); }
		}
	}
}


.figure-video {
	overflow : hidden;

	/*
	 * Ausrrichtung
	 */

	&.left {
		float  : left;
		margin : 0 $video-width-floated-horizontal-margin $thumbnail-align-margin-vertical 0;
		width  : $video-width-floated;
	}

	&.right {
		float  : right;
		margin : 0 0 $thumbnail-align-margin-vertical $video-width-floated-horizontal-margin;
		width  : $video-width-floated;
	}
}