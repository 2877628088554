/**
 * Vendors - Bootstrap Datetimepicker
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$bdp-bg-color     : $color-white;
$bdp-color        : $color-black;
$bdp-arrow-size   : 6px;

$bdp-item-today-mark-color       : $brand-secondary;
$bdp-item-today-mark-color-hover : $brand-secondary;

$bdp-item-bg-color        : transparent;
$bdp-item-color           : $bdp-color;
$bdp-item-bg-color-hover  : rgba($brand-secondary, 0.2);
$bdp-item-color-hover     : $brand-secondary;

$bdp-item-active-bg-color : $brand-secondary;
$bdp-item-active-color    : $color-white;

$bdp-item-disabled-bg-color : transparent;
$bdp-item-disabled-color    : lighten($color-black, 80%);

$bdp-item-past-bg-color : transparent;
$bdp-item-past-color    : lighten($color-black, 80%);

$bdp-item-upcoming-bg-color : transparent;
$bdp-item-upcoming-color    : lighten($color-black, 80%);

.bootstrap-datetimepicker-widget {
	$-arrow-offset : ($dropdown-menu-border-width + $bdp-arrow-size);
	
	font-size : 1em;
	
	&.dropdown-menu {
		background-color : $bdp-bg-color;
		box-shadow       : 0 0 9px -3px rgba($color-black, 0.2);
		color            : $bdp-color;
		font-size        : 1em;
		margin-bottom    : $bdp-arrow-size;
		margin-top       : $bdp-arrow-size;
		width            : 300px;
		z-index          : 10;
		
		&.top,
		&.bottom {
			&:after,
			&:before { border-width : $bdp-arrow-size; }
			
			&:before { display : none; }
		}
		
		&.top:after {
			border-top-color : $dropdown-menu-border-color;
			bottom           : -#{$-arrow-offset};
			left             : $dropdown-menu-border-width;
		}
		
		&.bottom:after {
			border-bottom-color : $dropdown-menu-border-color;
			left                : $dropdown-menu-border-width;
			top                 : -#{$-arrow-offset};
		}
	}
	
	ul > li {
		margin  : 0;
		padding : 0;
		
		&::before { display : none; }
	}
	
	table {
		> thead,
		> tbody {
			> tr {
				> th,
				> td {
					height      : auto;
					line-height : 1;
					padding     : $gap-base-small 1px;
					width       : auto;
				}
			}
		}
		
		> thead > tr {
			&:first-child {
				> th,
				> td {
					background-color : $bdp-item-bg-color;
					color            : $bdp-item-color;
					
					> span { font-size : .8125em; }
					
					&:hover {
						background-color : $bdp-item-bg-color-hover;
						color            : $bdp-item-color-hover;
					}
				}
			}
		}
		
		td {
			// Aktiv-Status
			&.active {
				&,
				&:hover {
					background-color : $bdp-item-active-bg-color;
					color            : $bdp-item-active-color;
				}
				
				&.today:before { border-color : $bdp-item-active-color; }
			}
			
			&.cw { }
			
			// Tageseintrag
			&.day {
				cursor      : pointer;
				height      : auto;
				line-height : 1;
				width       : 30px;
				
				&:hover {
					background-color : $bdp-item-bg-color-hover;
					color            : $bdp-item-color-hover;
					text-shadow      : none;
				}
			}
			
			// Tageseintrag voriger/kommender Monat.
			&.old,
			&.new {
				background-color : $bdp-item-past-bg-color;
				color            : $bdp-item-past-color;
			}
			
			// Tageseintrag aktuell.
			&.today {
				background-clip : padding-box; // FIX: https://redmine.ifabrik.de/issues/25849#note-6
				position        : relative;
				
				&:before {
					border-color : transparent transparent $bdp-item-today-mark-color $bdp-item-today-mark-color;
					border-style : solid;
					border-width : 0 0 $bdp-arrow-size $bdp-arrow-size;
					bottom       : 4px;
					right        : 4px;
				}
				
				&:hover {
					background-clip : padding-box; // FIX: https://redmine.ifabrik.de/issues/25849#note-6
					
					&:before { border-color: $bdp-item-today-mark-color-hover;}
				}
			}
			
			// Tageseintrag deaktiviert.
			&.disabled {
				&,
				&:hover {
					background-color : $bdp-item-disabled-bg-color;
					color            : $bdp-item-disabled-color;
				}
			}
			
			span {
				background-color : $bdp-item-bg-color;
				color            : $bdp-item-color;
				
				&:hover {
					background-color : $bdp-item-bg-color-hover;
					color            : $bdp-item-color-hover;
				}
				
				&.active {
					&,
					&:hover {
						background-color : $bdp-item-active-bg-color;
						color            : $bdp-item-active-color;
						text-shadow      : none;
					}
				}
				
				&.old {
					background-color : $bdp-item-past-bg-color;
					color            : $bdp-item-past-color;
				}
				
				&.disabled {
					&,
					&:hover {
						background-color : $bdp-item-disabled-bg-color;
						color            : $bdp-item-disabled-color;
					}
				}
			}
		}
	}
	
	.btn {
		color : $brand-tertiary;
		
		> span {
			display       : block;
			width         : 100%;
			height        : auto;
			line-height   : 40px;
			margin        : 0;
			border-radius : 0;
		}
		
		&:hover,
		&:active,
		&:focus {
			background-color : transparent;
			color            : $brand-secondary;
		}
	}
	
	a {
		&[data-action] {
			color   : $brand-secondary;
			display : block;
			height  : auto;
			padding : 0;
			
			&:hover,
			&:active,
			&:focus { background : transparent; }
		}
		
		&[data-action="togglePicker"] {
			color : $brand-primary;
			
			&:hover,
			&:active,
			&:focus { color : $btn-primary-bg-color-hover; }
		}
	}
	
	.btn[data-action="togglePeriod"] {
		color            : $btn-primary-color;
		background-color : $btn-primary-bg-color;
		border-color     : $btn-primary-border-color;
		
		&:hover,
		&:active,
		&:focus {
			color            : $btn-primary-color-hover;
			background-color : $btn-primary-bg-color-hover;
			border-color     : $btn-primary-border-color-hover;
		}
	}
	
	/* Reset zwecks vererbten Defintionen 'accordion-toggle'! */
	.picker-switch {
		font-size   : 1em;
		font-weight : $font-weight-normal;
		
		table td span {
			line-height : 36px;
			height      : 36px;
			
			&:hover { background : transparent; }
		}
		
		&::after,
		&::before { display : none; }
		
		&:hover,
		&:active,
		&:focus {
			background-color : transparent;
			color            : inherit;
		}
		
	}
	
	/* Datumsauswahl */
	.datepicker {
		border-bottom : 1px solid $color-gray-light;
		
		// Wochentage
		.dow {
			border-bottom : 1px solid $color-gray-light;
			color         : $color-gray-dark;
			font-size     : .875em;
			font-weight   : $font-weight-light;
		}
		
		/**
		 * Auswahl Monate, Jahre, Dekaden etc..
		 */
		
		&-months,
		&-years,
		&-decades {
			th {
				&.prev,
				&.next { min-width : 40px; }
			}
			
			td > span {
				height        : 36px;
				line-height   : 36px;
				margin        : 1px;
				min-width     : 40px;
				width         : 24%;
			}
		}
		
		/* Monatsauswahl */
		&-months {}
		
		/* Jahresauswahl */
		&-years {}
		
		/* Dekadenauswahl */
		&-decades {
			td {
				text-align : left;
				
				> span {
					display    : none;
					text-align : center;
					
					&.decade {
						display     : inline-block;
						height      : 36px;
						line-height : 36px !important;
						min-width   : 60px;
						width       : 49%;
					}
				}
			}
		}
	}
	
	/* Zeitauswahl. */
	.timepicker {
		border-top : 1px solid $color-gray-light;
		
		&-hour,
		&-minute,
		&-second {
			border-radius : 0;
			font-size     : 1.25em;
			font-weight   : $font-weight-normal;
			height        : $input-height;
			line-height   : $input-height;
			margin        : 0;
			width         : 100%;
		}
		
		table td { padding : 0; }
		
		.separator { line-height : $input-height; }
	}
}
