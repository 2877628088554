/**
 * Listengruppen (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Listengruppe 'Checkboxen, Radio'
 *
 * ========================================================================== */

.list-group-checkbox-radio {
	padding-left  : 15px;
	padding-right : 15px;

	p { margin : 0; }

	.list-group-item {
		border-left   : 0 none;
		border-right  : 0 none;
		border-top    : 0 none;
		border-color  : $list-groups-border-color;
		margin-bottom : 0;
		padding       : $list-groups-padding;

		&:first-child {
			border-top-left-radius  : $list-groups-border-radius;
			border-top-right-radius : $list-groups-border-radius;
		}

		&:last-child {
			border-bottom-left-radius  : $list-groups-border-radius;
			border-bottom-right-radius : $list-groups-border-radius;
		}
	}

	.checkbox,
	.radio {
		display     : block;
		min-height  : 0;
		padding-top : 0;

		label { display : block; }
	}

	&.has-grid {
		.list-group-item {
			padding-left  : 0;
			padding-right : 0;
		}
	}
}