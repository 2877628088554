/**
 * Small Devices, Tablets.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@include respond-to(sm) {

	#dev > .mq > .sm { display : inline-block; }

	.display-sm-block { display : block; }

	.nav-meta,
	.nav-primary { display : none; }

	/* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */

	.mm-menu.mm-offcanvas {
		width     : 50%;
		max-width : none;
	}

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-sm-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ----------------------------------------------------------------------

	.gallery[data-bind="gallery-showcase"] .swiper-slide {}*/

	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	//.googlemap:not(responsive-16by9):not(responsive-4by3):not(responsive-3by4):not(responsive-3by2):not(responsive-2by3) { height : 400px; }
	.googlemap:not([class*="responsive-"]) { height : 400px; }

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */

	//small,
	//.small,
	//.text-small { font-size : .9375em; }
	//
	//.xsmall,
	//.text-xsmall { font-size : .875em; }

	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */

	// Inhaltsspalten
	.main-page {
		> .main { width : 100%; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
	 * ---------------------------------------------------------------------- */

	.main-header {
		font-size : .75rem;
		padding   : $padding-base-vertical 0;

		.logo {
			&.-site {
				font-size : 5rem;
			}

			&.-client {
				font-size : 6.5rem;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	.main-footer {}

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	.main-showcase {
		$-slider-image-pagination-offset : $gap-base;
		$-slider-text-offset             : $gap-base-large;

		> .bar {
			> .bar-inner {
				> .col-gallery {
					width      : 100%;
				}
			}

			&-p { padding-top : 0; }

			&-s {
				.col-gallery {
					margin-top : -#{$-slider-text-offset};
				}
			}
		}

		.gallery-image-parts {
			.swiper {
				&-pagination {
					bottom        : ($-slider-image-pagination-offset + $-slider-text-offset);
					padding-right : $gap-base;
				}
			}

			.thumbnail > picture.image { padding-bottom: 56.25%; }
		}

		.gallery-text-parts {
			.swiper-slide-wrapper {
				padding-left  : $gap-base;
				padding-right : $gap-base;
			}

			.article { padding : $padding-base-horizontal-large; }
		}
	}

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

	/* =========================================================================
	 *
	 * Views
	 *
	 * ====================================================================== */

}