/**
 * Schrifteneinbindung
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

//@import '../../fonts/.../css/_...';

//'../fonts/open-sans/'

//$fonts-path : '../fonts/';
//
//// Open Sans
//$dir : $fonts-path + 'open-sans/';
@font-face {
	font-family  : "Open Sans";
	src          :
		url("../fonts/open-sans/open-sans-light.woff") format("woff"),
		url("../fonts/open-sans/open-sans-light.woff2") format("woff2");
	font-display : swap;
	font-style   : normal;
	font-weight  : 300;
}

@font-face {
	font-family  : "Open Sans";
	src          :
		url("../fonts/open-sans/open-sans-regular.woff") format("woff"),
		url("../fonts/open-sans/open-sans-regular.woff2") format("woff2");
	font-display : swap;
	font-style   : normal;
	font-weight  : 400;
}

@font-face {
	font-family  : "Open Sans";
	src          :
		url("../fonts/open-sans/open-sans-semibold.woff") format("woff"),
		url("../fonts/open-sans/open-sans-semibold.woff2") format("woff2");
	font-display : swap;
	font-style   : normal;
	font-weight  : 600;
}

@font-face {
	font-family  : "Open Sans";
	src          :
		url("../fonts/open-sans/open-sans-bold.woff") format("woff"),
		url("../fonts/open-sans/open-sans-bold.woff2") format("woff2");
	font-display : swap;
	font-style   : normal;
	font-weight  : 700;
}