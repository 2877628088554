/**
 * Listen (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

ul,
ol { @include list-reset; }

dl { @include list-reset(dl); }

ul,
ol,
dl {
	@extend %clear-both;

	&,
	ul,
	ol,
	dl {
		margin  : $list-margin;
		padding : $list-padding;
	}
}

/*
 * Listentyp der un- und geordneten Liste uberschreiben.
 */

ul,
ol {
	> li {
		margin       : $list-item-margin;
		padding-left : $comp-margin-horizontal-large;
		position     : relative;

		&::before {
			color    : $brand-primary;
			left     : 0;
			position : absolute;
			top      : -0.0625em;
		}
	}

	@at-root ul > li::before {
		@extend .fa-icon-helper;

		content     : "\f105";
		font-size   : cast-unit($font-unit-base, $font-size-xlarge);
	}

	@at-root ol > li {
		counter-increment : step-counter;
		padding-left      : $comp-margin-horizontal-large + 5px;

		&::before {
			content      : counter(step-counter);
			font-family  : Arial, Helvetica, sans-serif;
			font-size    : inherit;
			font-weight  : $font-weight-normal;
			top          : 0;
		}
	}

}

/* =============================================================================
 *
 * Bootstrap Listen
 *
 * -----------------------------------------------------------------------------
 * Siehe http://getbootstrap.com/css/#type-lists
 * ========================================================================== */

/* -----------------------------------------------------------------------------
 * Unstyled (Bootstrap Defaults)
 * -------------------------------------------------------------------------- */

//.list-unstyled {}

/* -----------------------------------------------------------------------------
 * Inline (Bootstrap Defaults)
 * -------------------------------------------------------------------------- */

//.list-inline {}

/* -----------------------------------------------------------------------------
 * Horizontal description
 * -------------------------------------------------------------------------- */

//.dl-horizontal {}

/* =============================================================================
 *
 * Eigene Listen
 *
 * ========================================================================== */

.list-clean {
	@include list-reset;

	> li::before { display : none; }
}

.list-inline {
	> li::before { display : none; }
}

.list-blank {
	> li {
		padding-left : 0;

		&::before { display : none; }
	}
}

/* -----------------------------------------------------------------------------
 * Social-Icons
 * -----------------------------------------------------------------------------
 * Verwendung Showcase, Seitenuss.
 * -------------------------------------------------------------------------- */

.list-social-icons {
	$-icon-size : 36px;
	$-icon-border-width : 2px;

	text-align : center;

	&,
	* {
		font-weight : $font-weight-normal;
		list-style  : none;
		padding     : 0;
		margin      : 0;
	}

	> li {
		display : inline-block;
		margin  : 0 $margin-base-horizontal;
		width   : $-icon-size;

		a {
			background-color : $brand-secondary;
			border-radius    : 50%;
			color            : $color-white;
			display          : block;
			//font-size        : cast-unit($font-unit-base, ceil($-icon-size/2));
			position         : relative;
			text-align       : center;
			text-indent      : 150%;
			white-space      : nowrap;

			&,
			> i,
			> i::before {
				display: block;
				height   : $-icon-size;
				overflow : hidden;
				width    : $-icon-size;
			}

			> i {
				font-size   : cast-unit($font-unit-base, 22px);
				left        : 0;
				position    : absolute;
				text-indent : initial;
				top         : 0;

				&::before { line-height : ($-icon-size + 1); }

				&.fa-xing::before,
				&.fa-facebook::before { padding-right : 3px; }
			}

			&:hover { background-color : $brand-primary; }
		}

		&:first-child { margin-left : 0; }

		&:last-child { margin-right : 0; }
	}
}

/* -----------------------------------------------------------------------------
 * Liste mit Links
 * -------------------------------------------------------------------------- */

.list-links {
	> li {
		border-bottom : $list-links-border-width $list-links-border-style $list-links-border-color;
		margin-bottom : 0;
		margin-top    : 0;
		padding       : $list-links-child-padding;

		&:last-child { border-bottom : 0; }

		&::before { display : none; }
	}

	a,
	a.link,
	span.link {
		$-padding : ($padding-base-horizontal-large + $padding-base-horizontal-small);

		// (1)
		@if (variable-exists(list-links-color) and $list-links-color != '') {
			color : $list-links-color;
		}

		display       : block;
		font-weight   : $font-weight-normal;
		position      : relative;
		padding-left  : 0;
		padding-right : $-padding;

		&::before {
			position   : absolute;
			right      : 0;
			text-align : center;
			top        : 4px;
			width      : $-padding;
		}

		&:hover,
		&:active,
		&:focus {
			// (1)
			@if (
				variable-exists(list-links-color-hover) and
				 $list-links-color-hover != ''
			) {
				color : $list-links-color-hover;
			}

			text-decoration : none;
		}
	}
}

.list-menu {
	@extend .list-links;

	a,
	a.link,
	span.link {
		$-padding : ($padding-base-horizontal-large + $padding-base-horizontal-small);

		padding-left  : $-padding;
		padding-right : 0;

		&::before {
			content : "\f105" !important;
			right   : auto;
			left    : 0;
		}
	}
}

/* -----------------------------------------------------------------------------
 * Liste mit Rahmen
 * -------------------------------------------------------------------------- */

.list-bordered {
	$-border : $list-bordered-border-width $list-bordered-border-style $list-bordered-border-color;

	border     : $-border;
	border-top : 0 none;

	> li::before { display : none; }

	> li,
	> dt,
	> dd {
		border-top : $-border;
		margin     : 0;
		padding    : $list-bordered-child-padding;
	}
}

/* -----------------------------------------------------------------------------
 * Liste Spaltigkeit
 * -------------------------------------------------------------------------- */

.list-col {
	margin-left  : -#{$padding-base-horizontal};
	margin-right : -#{$padding-base-horizontal};

	> li {
		float        : left;
		padding-left  : $padding-base-horizontal;
		padding-right : $padding-base-horizontal;
		width        : 50%;

		&::before { display : none; }

		&:nth-child(2n+1) { clear : both; }
	}

	&-3 > li {
		width : 33.3333333333%;

		&:nth-child(2n+1) { clear : none; }

		&:nth-child(3n+1) { clear : both; }
	}

	&-4 > li {
		width : 25%;

		&:nth-child(2n+1) { clear : none; }

		&:nth-child(4n+1) { clear : both; }
	}
}

/* -----------------------------------------------------------------------------
 * Liste (Standard-)Flex
 * -------------------------------------------------------------------------- */

.list-flex {
	display   : flex;
	flex-flow : row wrap;

	> li {
		flex         : 1 0 auto;
		padding-left : 0;

		&::before { display : none; }
	}
}

/* -----------------------------------------------------------------------------
 * Tabellenliste (dt = zeilenkopf, dd = Zeileninhalt)
 * -------------------------------------------------------------------------- */

.list-table {
	@extend .clearfix;

	> * {
		border-top : $list-table-border-width $list-table-border-style $list-table-border-color;
		float      : left;
		margin     : 0;
		padding    : $padding-base-vertical 0;

		&:nth-child(1),
		&:nth-child(2) { border-top : 0; }
	}

	> li::before {
		content : '';
		display : none;
	}

	> dt,
	> li:nth-child(2n+1) {
		clear         : both;
		font-weight   : $font-weight-normal;
		padding-right : $padding-base-horizontal;
		width         : 33%;
	}

	> dd,
	> li:nth-child(2n+2) { width : 66%; }
}