/**
 * Dropdown (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.dropdown-toggle {
	border-radius  : $comp-border-radius-base;
	display        : block;
	text-transform : $dropdown-menu-text-transform;
	width          : 100%;

	.caret {
		&,
		&::after {
			display : block;
			height  : 20px;
			width   : 20px;
		}

		@extend .fa-icon-helper;

		&::after {
			content     : $dropdown-caret-icon;
			line-height : 20px;
		}

		margin    : -10px 0 0;
		border    : 0 none;
		font-size : 1em;
		right     : 10px;
		top       : 50%;
		position  : absolute;
	}

	@at-root .open & {
		$-key : (default, primary, secondary, tertiary, success, info, warning);

		&.btn-default:hover,
		&.btn-default.focus,
		&.btn-default:focus {
			background-color : $btn-default-bg-color-hover;
			border-color     : $btn-default-border-color-hover;
			color            : $btn-default-color-hover;
		}

		&.btn-primary:hover,
		&.btn-primary.focus,
		&.btn-primary:focus {
			background-color : $btn-primary-bg-color-hover;
			border-color     : $btn-primary-border-color-hover;
			color            : $btn-primary-color-hover;
		}
	}

	@at-root .btn-group.open & {
		box-shadow : none;
	}
}

.dropdown-header {
	background-color : $dropdown-header-bg-color;
	color            : $dropdown-header-color;
	font-size        : cast-unit($font-unit-base, $dropdown-header-font-size);
	font-style       : $dropdown-menu-font-style;
	font-weight      : $dropdown-header-font-weight;
	line-height      : 1;
	margin           : $dropdown-header-margin;
	text-align       : $dropdown-header-text-align;

	> .text {
		display : inline-block;
		padding : $dropdown-header-padding;
	}
}

.dropdown-menu {
	background-color : $dropdown-menu-bg-color;
	border           : $dropdown-menu-border-width $dropdown-menu-border-style $dropdown-menu-border-color;
	border-radius    : $dropdown-menu-border-radius;
	box-shadow       : none;
	color            : $dropdown-menu-color;
	font-style       : $dropdown-menu-font-style;
	font-size        : cast-unit($font-unit-base, $dropdown-menu-font-size);
	margin           : 0;
	padding          : 0;

	&,
	> * { text-transform : $dropdown-menu-text-transform; }

	> li {
		padding-left : 0;
		margin       : 0;

		&::before { display : none; }

		> a {
			color       : $dropdown-menuitem-color;
			cursor      : $cursor-pointer;
			font-weight : $dropdown-menuitem-font-weight;
			line-height : 1;
			padding     : $dropdown-menuitem-padding;
			white-space : nowrap;

			&:hover,
			&:active,
			&:focus {
				background-color : $dropdown-menuitem-bg-color-hover;
				color            : $dropdown-menuitem-color-hover;
			}
		}


		&.active > a,
		&.selected > a {
			&,
			&:focus,
			&:hover {
				color            : $dropdown-menuitem-color-active;
				background-color : $dropdown-menuitem-bg-color-active;
			}
		}
	}

	.divider { margin : $dropdown-divider-margin; }

	@at-root .dropup & {
		margin-bottom : 0;
	}

	@at-root .open > .btn.dropdown-toggle {
		//border-bottom : 0 none;
		//border-color  : $dropdown-menu-border-color;
		box-shadow    : 0 0 6px rgba(red($input-border-color-focus), green($input-border-color-focus), blue($input-border-color-focus), 0.3);
	}

	@at-root .open > & {
		animation-duration        : .4s;
		animation-fill-mode       : forwards;
		animation-iteration-count : 1;
		animation-name            : dropdown-fade-in;
		animation-timing-function : ease;
		width                     : 100%;

		&.open { min-width : 180px; }
	}
}