/**
 * Galerien (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.gallery {
	@extend %clear-both;

	margin-bottom : $margin-base-vertical;
	margin-top    : $margin-base-vertical;
	position      : relative;

	.thumbnail {
		&,
		&.comp,
		> .image {
			display  : block;
			margin   : 0;
			overflow : hidden;
			width    : 100%;

			> img { display : none; }
		}

		> picture.image {
			> img { display : block; }
		}

		> .caption {
			background-color : rgba($color-black, 0.4);
			bottom           : 0;
			color            : $color-white;
			font-size        : cast-unit($font-unit-base, $font-size-small);
			font-style       : $font-style-normal;
			left             : 0;
			padding          : $padding-base-vertical-xsmall $padding-base-horizontal-small;
			position         : absolute;
			right            : 0;
		}
	}
}

/* =============================================================================
 *
 * Galerieliste
 *
 * ========================================================================== */

.gallery-list {
	margin-bottom : $margin-base-vertical;
	margin-top    : $margin-base-vertical;

	.thumbnail {
		display  : block;
		margin   : 0;
		overflow : hidden;
	}

	> .item {
		margin-bottom : $margin-base-vertical;
		margin-top    : $margin-base-vertical;
		padding-left  : $padding-base-horizontal;
		padding-right : $padding-base-horizontal;
		text-align    : center;

		&::before {
			content : '';
			display : none;
		}

		@extend %child-reset-margin-last-top;
	}
}