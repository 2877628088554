/**
 * Collapse (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.collapse {
	/* -------------------------------------------------------------------------
	 * Zuweisungen der Eigenschaften/Defintionen.
	 * ---------------------------------------------------------------------- */

	@extend %child-reset-margin-last-top;

	display : block;
	z-index : 1;

	&-toggle {
		&,
		&::before { @extend %transition-colors; }
	}

	&-toggle {
		@extend %transition-colors;

		background-color : $collapse-toggle-bg-color;
		border           : $collapse-toggle-border-width $collapse-toggle-border-style $collapse-toggle-border-color;
		border-radius    : $comp-border-radius-base;
		color            : $collapse-toggle-color;
		cursor           : $cursor-pointer;
		display          : block;
		margin           : $collapse-toggle-margin;
		line-height      : 1.5;
		outline          : 0 !important;
		padding          : 0;
		position         : relative;
		text-decoration  : none;

		// (1)(2)
		@if (
			variable-exists(collapse-toggle-font-size) and
			type-of($collapse-toggle-font-size) == 'number' and
			unitless($collapse-toggle-font-size) == false and
			unit($collapse-toggle-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $collapse-toggle-font-size);
		}

		// (1)
		@if (
			variable-exists(collapse-toggle-font-weight) and
			$collapse-toggle-font-weight != ''
		) {
			font-weight : $collapse-toggle-font-weight;
		}

		> * {
			display                                : block;
			padding                                : $collapse-toggle-padding;
			padding-#{$collapse-toggle-icon-align} : ($collapse-toggle-icon-space-around * 2 + $collapse-toggle-icon-size);
			text-decoration                        : none;
		}

		&::before {
			$-top-offset : ceil($collapse-toggle-icon-size / 2);

			@extend .fa-icon-helper;
			@extend %transition-colors;

			#{$collapse-toggle-icon-align} : $collapse-toggle-icon-space-around;

			// (1)
			@if (
				variable-exists(collapse-toggle-icon-bg-color) and
				$collapse-toggle-icon-bg-color != ''
			) {
				background-color : $collapse-toggle-icon-bg-color;
			}

			border-radius    : $collapse-toggle-icon-border-radius;
			bottom           : 0;
			color            : $collapse-toggle-icon-color;
			content          : $collapse-toggle-icon-content;
			font-size        : $collapse-toggle-icon-font-size;
			height           : $collapse-toggle-icon-size;
			line-height      : $collapse-toggle-icon-size;
			overflow         : hidden;
			position         : absolute;
			text-align       : center;
			top              : 50%;
			margin-top       : -#{$-top-offset};
			width            : $collapse-toggle-icon-size;
		}

		&:hover,
		&:focus {
			border-color : $collapse-toggle-border-color-hover;
			color        : $collapse-toggle-color-hover;

			> * {
				background-color : $collapse-toggle-bg-color-hover;
				color            : $collapse-toggle-color-hover;
				text-decoration  : none;
			}

			&::before {
				color   : $collapse-toggle-icon-color-hover;
				content : $collapse-toggle-icon-content-hover;
			}
		}

		&:active,
		&.active,
		&#{&}-active {
			border-color : $collapse-toggle-border-color-active;
			color        : $collapse-toggle-color-active;

			> * {
				background-color : $collapse-toggle-bg-color-active;
				color            : $collapse-toggle-color-active;
				text-decoration  : none;
			}

			&::before {
				color        : $collapse-toggle-icon-color-active;
				content      : $collapse-toggle-icon-content-active;
				line-height  : ($collapse-toggle-icon-size - 2px);
				padding-left : 0;
			}
		}

		&:first-child { margin-top: 0; }
	}

	&-panel {
		display : none;

		&-content {
			border     : $collapse-panel-border-width $collapse-panel-border-style $collapse-panel-border-color;
			border-top : 0 none;
			padding    : $collapse-panel-padding;

			@extend %child-reset-margin-last-top;
		}
	}
}