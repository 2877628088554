/**
 * Vendors - Bootstrap Select
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$bs-toggle-bg-color     : $input-bg-color !default;
$bs-toggle-border-color : $input-border-color !default;
$bs-toggle-border-width : $input-border-width;
$bs-toggle-border-style : $input-border-style;
$bs-toggle-box-shadow   : $input-box-shadow !default;
$bs-toggle-color        : $input-color !default;
$bs-toggle-font-size    : $input-font-size;
$bs-toggle-font-style   : $input-font-style;
$bs-toggle-font-weight  : $font-weight-light;
$bs-toggle-height       : $input-height;
$bs-toggle-height-large : $input-height-large;
$bs-toggle-height-small : $input-height-small;


$bs-toggle-bg-color-hover     : $input-bg-color-hover !default;
$bs-toggle-border-color-hover : $input-border-color-hover !default;
$bs-toggle-color-hover        : $input-color-hover !default;

$bs-toggle-bg-color-focus     : $input-bg-color-focus !default;
$bs-toggle-border-color-focus : $input-border-color-focus !default;
$bs-toggle-color-focus        : $input-color-focus !default;

$bs-toggle-placeholder-font-style : $form-placeholder-font-style !default;
$bs-toggle-placeholder-color      : $form-placeholder-color !default;

form .bootstrap-select {
	.dropdown-toggle {
		background-color : $bs-toggle-bg-color;
		border           : $bs-toggle-border-width $bs-toggle-border-style $bs-toggle-border-color;
		box-shadow       : $bs-toggle-box-shadow;
		color            : $bs-toggle-color;
		font-weight      : $bs-toggle-font-weight;
		height           : $bs-toggle-height;

		&:hover {
			color            : $bs-toggle-color-hover;
			background-color : $bs-toggle-bg-color-hover;
			border-color     : $bs-toggle-border-color-hover;
		}

		&:focus {
			$-rgba-border-color : rgba(red($bs-toggle-border-color), green($bs-toggle-border-color), blue($bs-toggle-border-color), 0.2);

			color            : $bs-toggle-color-focus;
			background-color : $bs-toggle-bg-color-focus;
			border-color     : $bs-toggle-border-color-focus;
			box-shadow       : 0 0 6px $-rgba-border-color;
		}

		&.bs-placeholder {
			color      : $bs-toggle-placeholder-color;
			font-style : $bs-toggle-placeholder-font-style;
		}
	}

	&.btn-group {
		.dropdown-toggle .caret {
			margin-top : -10px;
			right      : 10px;
		}

		.dropdown-menu {
			min-width : min-content;

			&.inner { overflow-x : hidden; }
		}

		&.show-tick .dropdown-menu li {
			a {
				.text { display : block; }
			}

			&.selected a span.check-mark {
				margin-top : -#{$gap-base};
				right      : $gap-base-small;
			}
		}
	}

	.bs-searchbox {
		padding : 0 0;

		.form-control {
			background-color   : $color-white;
			border-color       : $dropdown-menu-border-color;
			border-left-width  : 0;
			border-radius      : 0;
			border-right-width : 0;
			border-top-width   : 0;
			height             : auto;
			line-height        : normal;
		}
	}

	@at-root &.open > .btn.dropdown-toggle {
		$-rgba-border-color : rgba(red($bs-toggle-border-color), green($bs-toggle-border-color), blue($bs-toggle-border-color), 0.2);

		color            : $bs-toggle-color-focus;
		background-color : $bs-toggle-bg-color-focus;
		border-color     : $bs-toggle-border-color-focus;
		box-shadow       : 0 0 6px $-rgba-border-color;
	}
}