/**
 * Links (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =========================================================================
 *
 * Standardlink
 *
 * ====================================================================== */

a {
	@extend %transition-colors;

	color           : $link-color;
	font-weight     : $link-font-weight;
	position        : relative;
	text-decoration : $link-text-decoration;

	> .file-info {
		color        : $color-gray-dark !important;
		font-size    : cast-unit($font-unit-base, $font-size-small);
		font-weight  : $font-weight-normal;
		opacity      : .9;
		padding-left : $padding-base-horizontal-xsmall;
	}

	&:hover,
	&:active,
	&:focus {
		color           : $link-color-hover;
		text-decoration : $link-text-decoration-hover;
	}

	&[href^="tel"],
	&[href^="fax"] {
		color           : $body-text-color;
		cursor          : default;
		font-weight     : $font-weight-light;
		tab-index       : 0;
		text-decoration : none;

		&:hover {
			color           : $body-text-color;
			text-decoration : none;
		}
	}
}

a[class*="link-"],
span[class*="link-"] {
	display      : inline-block;
	padding-left : 4px;

	&::before {
		@extend .fa-icon-helper;

		//font-size    : 90%;
		margin-right : ($margin-base-horizontal-small - 4);
		top          : 0;
		position     : relative;
	}
}

a.link,
span.link {
	&-intern::before,
	&-page::before { content : "\f061"; }

	&-read-more::before { content : "\f138"; }

	&-anchor::before { content : "\f13d"; }

	&-back::before { content : "\f177"; }

	&-bars::before { content : "\f0c9"; }

	&-edit::before { content : "\f040"; }

	&-down::before { content : "\f063"; }

	&-email::before { content : "\f003"; }

	&-fax::before { content : "\f1ac"; }

	&-phone::before { content : "\f095"; }

	&-prev-circle::before { content : "\f137"; }

	&-next-circle::before { content : "\f138"; }

	&-arrow-left::before { content : "\f060"; }

	&-arrow-right::before { content : "\f061"; }

	&-view::before { content : "\f06e"; }

	&-file,
	&-media {
		&::before { content : "\f019"; }

		&-circle::before { content : "\f01a"; }
	}

	//&-gallery::before { content : "\f03e"; }

	&-googlemap::before { content : "\f041"; }

	//&-intern::before { content : "\f061"; }

	&-pageflip::before { content : "\f02d"; }

	&-popup::before { content : "\f0aa"; }

	&-print::before { content : "\f02f"; }

	&-star::before { content : "\f005"; }

	&-star-empty::before { content : "\f006"; }

	&-trash::before { content : "\f1f8"; }

	&-up::before { content : "\f062"; }

	&-web::before { content : "\f08e"; }

	&-globe::before { content : "\f0ac"; }

	&-loading::before { content : "\f110"; }

	&-pdf::before { content : "\f1c1"; }

	&-spin::before { animation : link-spin 2s infinite linear; }
}

/*
a.link,
span.link {
	display      : inline-block;
	padding-left : 4px;

	&::before {
		@extend .fa-icon-helper;

		content      : "\f061";
		//font-size    : 90%;
		margin-right : ($margin-base-horizontal-small - 4);
		top          : 0;
		position     : relative;
	}

	&-anchor::before { content : "\f13d"; }

	&-back::before { content : "\f177"; }

	&-bars::before { content : "\f0c9"; }

	&-edit::before { content : "\f040"; }

	&-down::before { content : "\f063"; }

	&-email::before { content : "\f003"; }

	&-fax::before { content : "\f1ac"; }

	&-phone::before { content : "\f095"; }

	&-prev-circle::before { content : "\f137"; }

	&-next-circle::before { content : "\f138"; }

	&-view::before { content : "\f06e"; }

	&-file,
	&-media {
		&::before { content : "\f019"; }

		&-circle::before { content : "\f01a"; }
	}

	//&-gallery::before { content : "\f03e"; }

	&-googlemap::before { content : "\f041"; }

	//&-intern::before { content : "\f061"; }

	&-pageflip::before { content : "\f02d"; }

	&-popup::before { content : "\f0aa"; }

	&-print::before { content : "\f02f"; }

	&-star::before { content : "\f005"; }

	&-star-empty::before { content : "\f006"; }

	&-up::before { content : "\f062"; }

	&-web::before { content : "\f08e"; }

	&-loading::before { content : "\f110"; }

	&-spin::before { animation : link-spin 2s infinite linear; }
}
*/

/* =============================================================================
 *
 * Inaktiver Link
 *
 * ========================================================================== */

a.crossed-out,
span.crossed-out,
a.crossed-out:hover,
span.crossed-out:hover {
	color           : $link-color-crossed-out !important;
	cursor          : $cursor-default !important;
	text-decoration : $link-text-decoration-crossed-out !important;
}