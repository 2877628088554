/**
 * Kindelemente anpassen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

%child-reset-margin-last-top {
	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }
}