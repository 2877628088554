/**
 * Animationen (Keyframes).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@keyframes dropdown-fade-in {
	from {
		opacity : 0;
		//top     : 120%;
	}
	to {
		opacity : 1;
		//top     : 100%;
	}
}

@keyframes fade-out {
	from {
		opacity : 1;
	}
	to {
		opacity : 0;
	}
}

@keyframes fade-in {
	from {
		opacity : 0;
	}
	to {
		opacity : 1;
	}
}

@keyframes link-spin {
	0% {
		transform : rotate(0deg);
	}
	100% {
		transform : rotate(359deg);
	}
}

@keyframes fade-in-out {
	0% {
		opacity : 1;
	}
	50% {
		opacity : 0;
	}
	100% {
		opacity : 1;
	}
}