/**
 * Artikel (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.article {
	position : relative;

	.title {
		margin-top : 0;
		overflow   : hidden;
	}

	.edited,
	.datetime {
		color     : $brand-secondary;
		font-size : cast-unit($font-unit-base, $font-size-small);
		display   : none;
	}

	.edited > .datetime {
		display   : inline;
		font-size : 1em;
	}

	> .article- {
		&header {
			background-color : $color-gray-light;
			margin-bottom    : $article-header-gap;
			padding          : $gap-base;

			> * { margin-top : 0; }

			.edited { color : $color-gray-dark; }

			.subtitle {
				color         : $brand-secondary;
				font-size     : cast-unit($font-unit-base, $h3-font-size);
				font-weight   : $h3-font-weight;
				font-family   : $font-family-headings;
				line-height   : 1.25em;
				margin-bottom : 0;
			}

			> :last-child { margin-bottom : 0; }
		}

		&footer { display : none; }

		&header,
		&footer {
			.datetime { display : none; }
		}
	}

	> .article-body {
		> *,
		> .article-body-section > * {
			//margin-bottom : $article-body-section-gap;
			//margin-top    : $article-body-section-gap;

			&:not(.gallery-list),
			&:not(.gallery-list):not(.gallery-list) {
				> :first-child { margin-top : 0; }

				> :last-child { margin-bottom : 0; }
			}
		}
	}

	/*
	 * Abstaende (Margin) in Bezug auf erste und letzte Kindelemente anpassen.
	 */

	> :first-child,
	> .article-header > :first-child,
	> .article-body > :first-child,
	> .article-body .article-body-section-wrap > :first-child,
	> .article-footer > :first-child { margin-top : 0; }

	> :last-child,
	> .article-header > :last-child,
	> .article-body > :last-child,
	> .article-body .article-body-section-wrap > :last-child,
	> .article-footer > :last-child { margin-bottom : 0; }
}