/**
 * Startseite
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.view-error {
	$-top-offset : ($gap-base * 2);

	align-content    : center;
	align-items      : flex-start;
	background-color : $color-gray-lighter;
	display          : flex;
	flex-wrap        : nowrap;
	justify-content  : center;
	padding          : $-top-offset $gap-base $gap-base;

	&::before {
		background   : {
			color    : $brand-primary;
			image    : url(../images/backgrounds/breadcrumb.jpg);
			repeat   : no-repeat;
			position : center center;
			size     : cover;
		};
		content  : "";
		display  : block;
		height   : $gap-base;
		left     : 0;
		right    : 0;
		position : fixed;
		top      : 0;
	}

	.box {
		align-self : center;
		flex       : 0 1 auto;
		max-width  : 400px;
		width      : 100%;
	}
}

@media screen and (max-height : 420px) {
	.view-error .box { align-self : start; }
}