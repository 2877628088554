/**
 * Bilder (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

img {
	border   : 0;
	display  : inline-block;
	height   : auto;
	overflow : hidden;
	width    : 100%;
}

.thumbnail {
	background-color : transparent;
	border           : 0 none;
	border-radius    : 0;
	display          : inline-block;
	margin           : $thumbnail-align-margin-vertical 0;
	padding          : 0;
	position         : relative;
	width            : $thumbnail-size;

	> .thumbnail-link,
	.image {
		border-radius : $thumbnail-border-radius;
		display       : block;
		overflow      : hidden;
	}

	.image {
		background-position : top center;
		background-repeat   : no-repeat;
		background-size     : cover;
		z-index             : 1;
	}

	.caption {
		@if (
			variable-exists(thumbnail-caption-font-size) and
			type-of($thumbnail-caption-font-size) == 'number' and
			unitless($thumbnail-caption-font-size) == false and
			unit($thumbnail-caption-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $thumbnail-caption-font-size);
		}

		background-color : $thumbnail-caption-bg-color;
		color            : $thumbnail-caption-color;
		display          : block;
		font-style       : $thumbnail-caption-font-style;
		font-weight      : $thumbnail-caption-font-weight;
		padding          : $thumbnail-caption-padding;
		text-align       : left;
		z-index          : 2;

		> .meta {
			display : block;

			&.photograph {
				color       : rgba($color-black, 0.4);
				font-size   : cast-unit($font-unit-base, $font-size-xsmall);
				font-weight : $font-weight-normal;
				text-align  : right;

				> a {
					color       : rgba($color-black, 0.4);
					font-weight : $font-weight-normal;
				}
			}
		}
	}

	.zoom {
		background-color : $thumbnail-zoom-bg-color;
		color            : $thumbnail-zoom-color;
		display          : none;
		line-height      : $thumbnail-zoom-size;
		overflow         : hidden;
		right            : 0;
		text-align       : center;
		text-indent      : 150%;
		top              : 0;
		white-space      : nowrap;
		z-index          : 5;

		// Schriftgroesse?
		@if (
			variable-exists(thumbnail-zoom-font-size) and
			type-of($thumbnail-zoom-font-size) == 'number' and
			unitless($thumbnail-zoom-font-size) == false and
			unit($thumbnail-zoom-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $thumbnail-zoom-font-size);
		}

		// Dimension?
		&,
		&::before {
			display     : block;
			height      : $thumbnail-zoom-size;
			line-height : $thumbnail-zoom-size;
			position    : absolute;
			width       : $thumbnail-zoom-size;
		}

		// Icon-Content?
		@if (variable-exists(thumbnail-zoom-icon-content) and $thumbnail-zoom-icon-content != '') {
			&::before {
				@extend .fa-icon-helper;

				content     : $thumbnail-zoom-icon-content;
				left        : 0;
				text-indent : 0;
				top         : 0;
			}
		}

		// Hover etc.?
		@if (variable-exists(thumbnail-zoom-bg-color-hover) or variable-exists(thumbnail-zoom-color-hover)) {
			&:hover {
				@if (variable-exists(thumbnail-zoom-bg-color-hover)) {
					background-color : $thumbnail-zoom-bg-color-hover;
				}

				@if (variable-exists(thumbnail-zoom-color-hover)) {
					color : $thumbnail-zoom-color-hover;
				}
			}
		}
	}

	/*
	 * Ausrichtung
	 */

	&.left {
		float        : left;
		margin-right : $thumbnail-align-margin-horizontal;
	}

	&.right {
		float       : right;
		margin-left : $thumbnail-align-margin-horizontal;
	}

	/*
	 * Groessen
	 */

	&.size {
		&-thumbnail { width : $thumbnail-size-small; }

		&-medium { width : $thumbnail-size-large; }

		&-full {
			display      : block;
			float        : none;
			margin-left  : 0;
			margin-right : 0;
			width        : $thumbnail-size-full;

			.zoom { display : none !important; }
		}
	}

	&.format {
		&-16by9,
		&-4by3,
		&-3by4,
		&-3by2,
		&-2by3,
		&-square {
			.image {
				overflow : hidden;
				position : relative;

				> img {
					left       : 0;
					position   : absolute;
					right      : 0;
					top        : 0;
					//visibility : hidden;
				}
			}
		}

		&-16by9 .image { padding-bottom : calc-aspect-ratio(16, 9); }

		&-4by3 .image { padding-bottom : calc-aspect-ratio(4, 3); }

		&-3by4 .image { padding-bottom : calc-aspect-ratio(3, 4); }

		&-3by2 .image { padding-bottom : calc-aspect-ratio(3, 2); }

		&-2by3 .image { padding-bottom : calc-aspect-ratio(2, 3); }

		&-square .image { padding-bottom : calc-aspect-ratio(1, 1); }
	}

	@at-root table .thumbnail {
		display      : block;
		margin-left  : 0;
		margin-right : 0;
		width        : 100%;
	}
}