/**
 * Vendors - MMenu
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

#mm-blocker {
	background-color : $color-white;
	opacity          : 0;
	transition       : all .5s cubic-bezier(0.165, 0.84, 0.44, 1) .1s;

	@at-root html.mm-opening {
		#mm-blocker { opacity : .7; }
	}
}

.mm-listview { font-size : 1em; }