/**
 * Startseite
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.view-index {
	.section.-type-events {
		.heading {
			margin-top : ($gap-base-large * 2);

			&:first-child { margin-top : 0; }
		}

		.event {
			border : 3px solid $color-gray-light;

			//&__header,
			//&__body,
			//&__footer {}
		}
	}
}