/**
 * Responsive Einbindungen (Komponente)
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.embed-responsive,
.aspect-ratio {
	position : relative;
	display  : block;
	height   : 0;
	padding  : 0;
	overflow : hidden;

	> *:first-child {
		position : absolute;
		top      : 0;
		bottom   : 0;
		left     : 0;
		width    : 100%;
		height   : 100%;
		border   : 0;
	}

	> img:first-child { height : auto; }

	&-16by9 { padding-bottom : calc-aspect-ratio(16, 9); }

	&-4by3 { padding-bottom : calc-aspect-ratio(4, 3); }

	&-3by4 { padding-bottom : calc-aspect-ratio(3, 4); }

	&-3by2 { padding-bottom : calc-aspect-ratio(3, 2); }

	&-2by3 { padding-bottom : calc-aspect-ratio(2, 3); }

	&-square { padding-bottom : calc-aspect-ratio(1, 1); }
}


*[data-ar] {
	position : relative;
	display  : block;
	height   : 0;
	padding  : 0;
	overflow : hidden;

	> *:first-child {
		position : absolute;
		top      : 0;
		bottom   : 0;
		left     : 0;
		width    : 100%;
		height   : 100%;
		border   : 0;
	}

	> img:first-child { height : auto; }
}

$-ar:  (16, 9), (4, 3), (3, 4), (3, 2), (2, 3), (1, 1);

@each $x, $y in $-ar {
	*[data-ar="#{$x}by#{$y}"] { padding-bottom : calc-aspect-ratio($x, $y); }
}