/**
 * Randspalte
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.aside {
	color : lighten($brand-tertiary, 21%);

	> * {
		$-margin : ($gap-base-small * 4);
		margin-bottom : $-margin;
		margin-top    : $-margin;

		&:first-child { margin-top : 0; }

		&:last-child { margin-bottom : 0; }
	}

	// Interne Links bekommen ein anders Icon.
	.link-intern,
	.link-page {
		&::before { content : "\f138"; }
	}

	.thumbnail {
		display      : block;
		float        : none;
		margin-left  : 0;
		margin-right : 0;
		width        : 100%;

		> .zoom { display : none !important; }
	}

	.vcard {
		background-color : transparent;
		border           : 0 none;
		padding          : 0;
	}

	.article {
		border   : 3px solid $color-gray;
		padding  : $gap-base;
		position : relative;;

		> .title,
		> .ce-title {
			@extend .h4;

			color: darken($brand-tertiary, 28%);
		}

		> :first-child { margin-top : 0 !important; }

		> :last-child { margin-bottom : 0 !important; }
	}
}

/* -----------------------------------------------------------------------------
 * DCE-Modifikationen
 * -------------------------------------------------------------------------- */

.aside .dce {
	margin-top : 0;

	&-tb {
		.dce-row > .dce-column {
			width         : 100%;
			margin-bottom : $gap-base;

			&:last-child { margin-bottom : 0; }
		}
	}
}