/**
 * Eigener Custom-Breakpoints (420px).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@include respond-to(c420) {

	#dev > .mq > .c420 { display : inline-block; }

	.display-c420-block { display : block; }

	.hidden-c420 { display : none; }

	@include grid-append-new-columns('c420', $gap-base);

	//.col-c420-12:not(:last-child) { margin-bottom : $gap-base; }

	/* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-c420-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'components/_collapse'
 	 * ---------------------------------------------------------------------- */

	.tab {
		&-to-collapse{
			.nav-tabs { display: none; }

			.toggle-tab-collapse { display : block; }
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_articles'
	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_vcards'
	 * ---------------------------------------------------------------------- */

	.vcard {
		max-width : none;

		&-group {
			& > &-item {
				float: none;
				width: 100%;
			}
		}
	}

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */

	.main-header {
		.logo {
			&.-site {
				font-size : 2.5rem;
			}

			&.-client {
				font-size : 4rem;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	.aside {
		> * { width : 100%; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'core/_elements'
	 * ---------------------------------------------------------------------- */

	// Nutzermenü
	.user-bar {
		.dropdown {
			position : static;

			&-menu {
				left      : $gap-base;
				right     : $gap-base;
				min-width : 0;
			}
		}
	}

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

	/* =========================================================================
	 *
	 * Views
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'views/index/_index'
	 * ---------------------------------------------------------------------- */
}