/**
 * Stapel
 *
 * Komponentendefinitionen
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.stack {
	@extend .clearfix;
	margin-bottom : ($gap-base-small * 2);
	margin-top    : ($gap-base-small * 2);

	.stack-item {
		margin   : nth($stack-item-gap, 1) 0;
		position : relative;

		*:first-child { margin-top : 0; }

		*:last-child { margin-bottom : 0; }

		// (1)
		@if (
			variable-exists(stack-delimiter-border) and
			$stack-delimiter-border != ''
		) {
			border-top  : $stack-delimiter-border;
			padding-top : nth($stack-item-gap, 1);

			&:first-child {
				border-top  : 0 none;
				margin-top  : 0;
				padding-top : 0;
			}
		} @else {
			&:first-child { margin-top : 0; }
		}

		&:last-child { margin-bottom : 0; }
	}
}

/* =============================================================================
 *
 * Artikel
 *
 * ========================================================================== */

.stack-article .stack-item {
	position      : relative;

	// (1)
	@if (variable-exists(stack-article-item-margin)) {
		margin-bottom : $stack-article-item-margin;
		margin-top    : $stack-article-item-margin;
	}

	.title {
		// (1)(2)
		@if (variable-exists(stack-article-title-color)) {
			color : $stack-article-title-color;
		}

		// (1)(2)
		@if (variable-exists(stack-article-title-font-weight)) {
			font-weight : $stack-article-title-font-weight;
		}

		// (1)(2)
		@if (variable-exists(stack-article-title-margin)) {
			margin : $stack-article-title-margin;
		}

		// (1)(2)
		@if (variable-exists(stack-article-title-text-transform)) {
			text-transform : $stack-article-title-text-transform;
		}

		// (1)(2)
		@if (
			variable-exists(stack-article-title-font-size) and
			type-of($stack-article-title-font-size) == 'number' and
			unitless($stack-article-title-font-size) == false and
			unit($stack-article-title-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $stack-article-title-font-size);
		}

		margin-top : 0;

		> a {
			color           : inherit;
			text-decoration : none;
		}
	}

	.edited {
		margin     : $gap-base-xsmall 0;
		font-style : italic;
	}

	.description {
		padding : 0;
		margin  : $margin-base-vertical 0;
	}

	.link-read-more {
		font-family : $font-family-secondary;
		display     : block;
		margin-top  : $gap-base;

		// (1)(2)
		@if (variable-exists(stack-article-readmore-color)) {
			color : $stack-article-readmore-color;
		}

		// (1)(2)
		@if (
			variable-exists(stack-article-readmore-font-size) and
			type-of($stack-article-readmore-font-size) == 'number' and
			unitless($stack-article-readmore-font-size) == false and
			unit($stack-article-readmore-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $stack-article-readmore-font-size);
		}

		// (1)(2)
		@if (variable-exists(stack-article-readmore-font-weight)) {
			font-weight : $stack-article-readmore-font-weight;
		}

		// (1)(2)
		@if (variable-exists(stack-article-readmore-text-decoration)) {
			text-decoration : $stack-article-readmore-text-decoration;
		}

		&::before {
			font-size : .75em;
			top       : -1px;
		}

		&.hover {
			color           : $link-color-hover;
			text-decoration : none;
		}
	}

	> .row > * > :first-child { margin-top : 0; }

	> .row > * > :last-child { margin-bottom : 0; }
}