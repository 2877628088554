/**
 * Warenkorb
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.order-stack {
	align-content   : flex-start;
	align-items     : flex-start;
	display         : flex;
	flex-direction  : row;
	flex-wrap       : wrap;
	justify-content : space-between;
	margin          : -#{$gap-base};

	&__item {
		order      : 0;
		flex       : 0 1 100%;
		align-self : stretch;

		> .padding-hack {
			padding : $gap-base;
		}
	}

	.event {
		background-color : $color-gray-lighter;
		border           : 3px solid $color-gray-light;

		&__body {
			.title {
				padding-right : $margin-base-horizontal-large;
			}
		}

		.alert {
			border      : 0;
			padding     : $gap-base-xsmall $gap-base-small;
			font-size   : .75em;
			font-weight : normal;
		}

		.btn[data-control="detail"] {
			background-color : $btn-default-bg-color;
			border-color     : $btn-default-border-color;
			color            : $btn-default-color;

			@include on-event() {
				background-color : $btn-default-bg-color-hover;
				border-color     : $btn-default-border-color-hover;
				color            : $btn-default-color-hover;
			}
		}

		&[data-is-booked],
		&[data-is-active] {
			background-color : rgba($state-valid-color, 0.05);
			border-color     : rgba($state-valid-color, 0.3);

			.btn[data-control="detail"] {
					background-color : $btn-success-bg-color;
					border-color     : $btn-success-border-color;
					color            : $btn-success-color;

				@include on-event() {
					background-color : $btn-success-bg-color-hover;
					border-color     : $btn-success-border-color-hover;
					color            : $btn-success-color-hover;
				}
			}
		}

		&[data-is-waiting] {
			background-color : rgba($state-warning-color, 0.05);
			border-color     : rgba($state-warning-color, 0.3);

			.btn[data-control="detail"] {
					background-color : $btn-warning-bg-color;
					border-color     : $btn-warning-border-color;
					color            : $btn-warning-color;

				@include on-event() {
					background-color : $btn-warning-bg-color-hover;
					border-color     : $btn-warning-border-color-hover;
					color            : $btn-warning-color-hover;
				}
			}
		}

		&[data-is-remove-time] {
			background-color : rgba($state-danger-color, 0.05);
			border-color     : rgba($state-danger-color, 0.3);

			.btn[data-control="detail"] {
					background-color : $btn-danger-bg-color;
					border-color     : $btn-danger-border-color;
					color            : $btn-danger-color;

				@include on-event() {
					background-color : $btn-danger-bg-color-hover;
					border-color     : $btn-danger-border-color-hover;
					color            : $btn-danger-color-hover;
				}
			}
		}

		&[data-is-removed] {
			opacity : 0.5;
		}
	}

	[data-order-remove] {
		color       : $brand-primary;
		cursor      : pointer;
		font-size   : 1.3125em;
		line-height : 1.25;
		position    : absolute;
		right       : $gap-base;
		top         : $gap-base;
	}

	.checkbox,
	.radio {
		margin: 0;

		&-styled > label::before {
			background-color : $color-white;
		}
	}
}

[data-module-basket="empty"] {
	.box__footer {
		text-align : left;
	}
}