/**
 * Visitenkarten (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* -----------------------------------------------------------------------------
 * Single Vcard
 * -------------------------------------------------------------------------- */

.vcard {
	display   : block;
	font-size : 1rem;
	max-width : $vcard-max-width;
	padding   : $vcard-padding;

	// (1)
	@if (
		variable-exists(vcard-border-width) and
		strip-unit($vcard-border-width) > 0
	) {
		border : $vcard-border-width $vcard-border-style $vcard-border-color;
	}

	// (1)
	@if (
		variable-exists(vcard-border-radius) and
		$vcard-border-radius != ''
	) {
		border-radius : $vcard-border-radius;
		overflow      : hidden;
	}

	// Hintergrundfarbe (1)
	@if (
		variable-exists(vcard-bg-color) and
		$vcard-bg-color != ''
	) {
		background-color : $vcard-bg-color;
	}

	// Textfarbe (1)
	@if (
		variable-exists(vcard-color) and
		$vcard-color != ''
	) {
		color : $vcard-color;
	}

	&-inner {
		display   : flex;
		flex-flow : column nowrap;

		> * {
			flex     : 1 0 auto;
			overflow : hidden;
		}
	}
}

.vcard-image {
	// (1)
	@if (
		variable-exists(vcard-image-bg-color) and
		$vcard-image-bg-color != ''
	) {
		background-color : $vcard-image-bg-color;
	}

	// (1)
	@if (
		variable-exists(vcard-image-padding) and
		$vcard-image-padding != ''
	) {
		padding : $vcard-image-padding;
	}

	> .thumbnail {
		margin  : 0;
		display : block;
	}

	&.is-empty > .thumbnail { opacity : .46; }

	+ .vcard-data { margin-top : nth($vcard-padding, 1); }
}

.vcard-data {
	> * {
		margin : 0 0 nth($vcard-padding, 1);

		&:last-child { margin-bottom : 0; }
	}

	a {
		display : block;

		// Linkfarbe (1)(2)
		@if (
			variable-exists(vcard-link-color) and
			$vcard-link-color != ''
		) {
			color : $vcard-link-color;

			// Events (1)(2)
			@if (
				variable-exists(vcard-link-color-hover) and
				$vcard-link-color-hover != ''
			) {
				&:hover,
				&:focus { color : $vcard-link-color-hover; }
			}
		}
	}

	.name {
		// Textfarbe (1)
		@if (
			variable-exists(vcard-name-color) and
			$vcard-name-color != ''
		) {
			color : $vcard-name-color;
		}

		// (1)(2)
		@if (
			variable-exists(vcard-name-font-size) and
			type-of($vcard-name-font-size) == 'number' and
			unitless($vcard-name-font-size) == false and
			unit($vcard-name-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $vcard-name-font-size);
		}

		// (1)(2)
		@if (
			variable-exists(vcard-name-font-weight) and
			$vcard-name-font-weight != ''
		) {
			font-weight : $vcard-name-font-weight;
		}

		// (1)(2)
		@if (
			variable-exists(vcard-name-font-style) and
			$vcard-name-font-style != ''
		) {
			font-style : $vcard-name-font-style;
		}

		text-transform : none;

		+ .function { margin-top : $gap-base-xsmall; }
	}

	.function {
		// (1)(2)
		@if (
			variable-exists(vcard-function-color) and
			$vcard-function-color != ''
		) {
			color : $vcard-function-color;
		}

		// (1)(2)
		@if (
			variable-exists(vcard-function-font-size) and
			type-of($vcard-function-font-size) == 'number' and
			unitless($vcard-function-font-size) == false and
			unit($vcard-function-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $vcard-function-font-size);
		}

		// (1)(2)
		@if (
			variable-exists(vcard-function-font-weight) and
			$vcard-function-font-weight != ''
		) {
			font-weight : $vcard-function-font-weight;
		}

		// (1)(2)
		@if (
			variable-exists(vcard-function-font-style) and
			$vcard-function-font-style != ''
		) {
			font-style : $vcard-function-font-style;
		}

		text-transform : none;
	}

	.address { padding-top : nth($vcard-padding, 1); }

	.reachable {
		padding-top : nth($vcard-padding, 1);

		> .network {
			display         : flex;
			flex-flow       : row wrap;
			justify-content : space-between;
			margin          : nth($vcard-padding, 1) 0 0;

			//> .link {
			//	&-email,
			//	&-web {}
			//}
		}
	}

	> *:first-child { margin-top : 0; }

	> *:last-child { margin-bottom : 0; }
}

/* -----------------------------------------------------------------------------
 * Vcard-Gruppe
 * -------------------------------------------------------------------------- */

.vcard-group {
	margin-left  : -#{$gap-base};
	margin-right : -#{$gap-base};
	margin-top   : -#{$gap-base} !important;

	> * {
		float         : left;
		margin-bottom : $gap-base;
		margin-top    : $gap-base;
		padding-left  : $gap-base;
		padding-right : $gap-base;
		position      : relative;
	}

	& > &-title {
		clear   : both;
		//display : none;
		width   : 100%;
	}

	& > &-item {
		float: left;
		width: 50%;
	}

	& > &-title:first-child { margin-top : 0; }

	.vcard {
		height    : 100%;
		margin    : 0 !important;
		max-width : none;
	}

	@at-root .aside & {
		> .vcard-group-item {
			float : none;
			width : 100%;
		}
	}
}