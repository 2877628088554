/**
 * Listen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

%list-reset {
	border     : 0 none;
	list-style : none;
	margin     : 0;
	padding    : 0;

	li {
		border   : 0 none;
		margin   : 0;
		padding  : 0;
	}
}