/**
 * Pagination (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.pagination {
	border-radius  : $pagination-border-radius;
	margin         : $pagination-margin;
	padding-left   : 70px;
	padding-right  : 70px;
	position       : relative;
	text-transform : $pagination-text-transform;

	// (1)
	@if (
		variable-exists(pagination-align) and
		$pagination-align != ''
	) {
		display    : block;
		text-align : $pagination-align;
	}

	// (1, 2)
	@if (
		variable-exists(pagination-font-size) and
		type-of($pagination-font-size) == 'number' and
		unitless($pagination-font-size) == false and
		unit($pagination-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $pagination-font-size);
	}

	> li {
		display : inline-block;
		margin  : $pagination-item-margin;

		@at-root .nav & {
			margin : $pagination-item-margin;
		}

		> a,
		> span {
			background-color : $pagination-item-bg-color;
			border-color     : $pagination-item-border-color;
			border-radius    : $pagination-border-radius;
			border-style     : $pagination-item-border-style;
			border-width     : $pagination-item-border-width;
			color            : $pagination-item-color;
			margin           : 0;
			padding          : $pagination-item-padding;

			// (1)(2)
			@if (
				variable-exists(pagination-item-font-weight) and
				$pagination-item-font-weight != ''
			) {
				font-weight : $pagination-item-font-weight;
			}

			// (1)(2)
			@if (
				variable-exists(pagination-line-height) and
				$pagination-line-height != ''
			) {
				line-height: $pagination-line-height;
			}

			> i { padding : 0; }

			&:hover,
			&:focus {
				color            : $pagination-item-color-hover;
				background-color : $pagination-item-bg-color-hover;
				border-color     : $pagination-item-border-color-hover;
			}
		}

		&:first-child {
			margin-left : 0;
			> a,
			> span {
				border-bottom-left-radius : $pagination-border-radius;
				border-top-left-radius    : $pagination-border-radius;
				margin-left               : 0;
			}
		}

		&:last-child {
			margin-right : 0;

			> a,
			> span {
				border-bottom-right-radius : $pagination-border-radius;
				border-top-right-radius    : $pagination-border-radius;
				margin-right               : 0;
			}
		}

		&.prev,
		&.next {
			margin-top : -20px;
			position   : absolute;
			top        : 50%;

			> a {
				background-color : $pagination-ctrl-bg-color;
				border-color     : $pagination-ctrl-border-color;
				color            : $pagination-ctrl-color;

				// (1)(2)
				@if (
					variable-exists(pagination-ctrl-font-weight) and
					$pagination-ctrl-font-weight != ''
				) {
					font-weight : $pagination-ctrl-font-weight;
				}

				&:hover,
				&:focus {
					color            : $pagination-ctrl-color-hover;
					background-color : $pagination-ctrl-bg-color-hover;
					border-color     : $pagination-ctrl-border-color-hover;
				}
			}
		}

		&.prev { left : 0; }

		&.next { right : 0; }

	}

	> .active > a,
	> .active > span {
		&,
		&:hover,
		&:focus {
			background-color : $pagination-item-bg-color-active;
			border-color     : $pagination-item-border-color-active;
			color            : $pagination-item-color-active;
			cursor           : $cursor-default;
		}
	}

	> .disabled {
		> span,
		> span:hover,
		> span:focus,
		> a,
		> a:hover,
		> a:focus {
			background-color : $pagination-item-bg-color-disabled;
			border-color     : $pagination-item-border-color-disabled;
			color            : $pagination-item-color-disabled;
			cursor           : $cursor-default;
		}

		// (1)
		@if (variable-exists(pagination-item-opacity-disabled)) {
			opacity : $pagination-item-opacity-disabled;
		}
	}

	@at-root .nav-pagination {
		margin : $pagination-margin;

		> .pagination {
			margin        : 0;
			padding-left  : 70px;
			padding-right : 70px;
		}

/*		// (1)(2)
		@if (
			variable-exists(pagination-align) and
			$pagination-align != ''
		) {
			text-align : $pagination-align;

			> .pagination { display : inline-block; }
		}*/
	}
}

/*
.pagination-lg {
	@include pagination-size($comp-padding-vertical-large, $comp-padding-horizontal-large, $font-size-large, $comp-border-radius-large);
}

.pagination-sm {
	@include pagination-size($comp-padding-vertical-small, $comp-padding-horizontal-small, $font-size-small, $comp-border-radius-small);
}*/

.pager {
	text-transform : none;

	li {
		> a,
		> span {
			@include button-size($comp-padding-vertical-base, $comp-padding-horizontal-base, $btn-border-radius-base);

			background-color : transparent;
			border           : $btn-border-width $btn-border-style darken($btn-default-border-color, 20%);
			color            : $color-gray-dark;
			display          : inline-block;
			font-size        : .875em;
			height           : $input-height;
			line-height      : $input-height;
			margin           : 0 $margin-base-horizontal-xsmall;
			padding-bottom   : 0;
			padding-top      : 0;
		}

		> a {
			&:hover,
			&:active,
			&:focus {
				background-color : transparent;
				color            : $color-gray-darker;
			}
		}

		&.pager {
			&-info > span {
				border-color : transparent;
				font-weight  : $font-weight-bold;
				margin       : 0;
			}
		}

		&:first-child > * { margin-left : 0; }

		&:last-child > * { margin-right : 0; }
	}

	&.left-aligned {
		text-align : left;

		> * { text-align : center; }
	}

	&.right-aligned {
		text-align : right;

		> * { text-align : center; }
	}
}

.pager-icons li {
	overflow : hidden;

	> a,
	> span {
		border        : 0 none;
		border-radius : 0;
		overflow      : hidden;
	}

	&.pager {
		&-info > * {
			padding-left  : 0;
			padding-right : 0;
		}

		&-prev > *,
		&-next > * {
			padding     : 0;
			text-indent : 150%;
			white-space : nowrap;
			width       : $input-height;

			&::before {
				@extend .fa-icon-helper;

				bottom      : 0;
				font-size   : 1.25em;
				left        : -2px;
				line-height : $input-height;
				position    : absolute;
				right       : 0;
				text-indent : 0;
				top         : -1px;
				width       : $input-height;
			}
		}

		&-prev > *::before {
			content : "\f104";
		}

		&-next > *::before {
			content : "\f105";
		}

	}
}