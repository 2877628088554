/**
 * Navigation
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Hauptmenue
 *
 * ========================================================================== */

.nav-primary {
	$-item-height       : 40px;
	$-item-border-width : 3px;

	background-color : $nav-primary-bg-color;
	color            : $nav-primary-item-color;

	// (1)
	@if (variable-exists(nav-primary-font-family) and $nav-primary-font-family != '') {
		font-family : $nav-primary-font-family;
	}

	// (1, 2)
	@if (
		variable-exists(nav-primary-font-size) and
		type-of($nav-primary-font-size) == 'number' and
		unitless($nav-primary-font-size) == false and
		unit($nav-primary-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-primary-font-size);
	}

	// (1)
	@if (variable-exists(nav-primary-font-style) and $nav-primary-font-style != '') {
		font-style : $nav-primary-font-style;
	}

	// (1)
	@if (variable-exists(nav-primary-text-transform) and $nav-primary-text-transform != '') {
		text-transform : $nav-primary-text-transform;
	}

	// (1)
	@if (variable-exists(nav-primary-font-variant) and $nav-primary-font-variant != '') {
		font-variant : $nav-primary-font-variant;
	}

	/* Standards für die gesamte Navigation. */
	li {
		display       : block;
		margin-bottom : $gap-base-small;
		margin-top    : $gap-base-small;
		padding       : 0;

		> ul { display : none; }

		/**
		 * Link-, Link-Event-Standards.
		 */

		a {
			background-color : $nav-primary-item-bg-color;
			border           : $-item-border-width solid transparent;
			border-radius    : $-item-height;
			color            : $nav-primary-item-color;
			display          : block;
			padding-left     : ($padding-base-horizontal + $padding-base-horizontal-large);
			position         : relative;

			&::before {
				@extend .fa-icon-helper;

				bottom       : 0;
				color        : $nav-primary-item-color-active;
				content      : "\f138";
				font-size    : 14px;
				left         : 0;
				line-height  : inherit;
				padding-left : 10px;
				position     : absolute;
				top          : 0;
			}
		}

		&:hover > a,
		&:focus > a,
		&.touch-hover-helper > a,
		&.is-open > a,
		a:hover,
		a:focus {
			background-color : $nav-primary-item-bg-color-hover;
			color            : $nav-primary-item-color-hover;
		}

		&.active {
			> ul { display : block; }

			> a {
				background-color : $nav-primary-item-bg-color-active !important;
				color            : $nav-primary-item-color-active !important;
			}
		}

		// Klappt nach unten per JS auf!
		//&:hover > ul,
		//&:focus > ul,
		//&.touch-hover-helper > ul { display : block; }
		// TODO: Theme
		//&.sjs {
		//	$-tc : map-get($theme-colors, 'sjs-brand-primary');
		//
		//	a::before { color : $-tc; }
		//
		//	&:hover > a,
		//	&:focus > a,
		//	&.touch-hover-helper > a,
		//	&.is-open > a,
		//	a:hover,
		//	a:focus { color : $-tc; }
		//
		//	&.active > a { color : $-tc !important; }
		//}
	}

	/* Hauptmenüebene. */
	> ul {
		$-ul-left : (10px + $-item-border-width);

		margin-left : -#{$-ul-left};

		> li {
			margin-bottom : $nav-primary-item-margin;
			margin-top    : $nav-primary-item-margin;

			> a {
				height      : $-item-height;
				line-height : ($-item-height - $-item-border-width);
				overflow    : hidden;
				white-space : nowrap;

				// (1)
				@if (variable-exists(nav-primary-font-weight) and $nav-primary-font-weight != '') {
					font-weight : $nav-primary-font-weight;
				}
			}

			&.active > a { border-color : $nav-primary-item-color-active; }

			&:first-child { margin-top : 0; }

			&:last-child { margin-bottom : 0; }
			// TODO: Theme
			//&.theme-sjs {
			//	&.active > a { border-color : map-get($theme-colors, 'sjs-brand-primary'); }
			//}
		}
	}

	/* 2-te Untermenüebene. */
	> ul ul {
		background-color : $nav-primary-sub-bg-color;
		display          : none;
		font-size        : .875em;

		// (1)
		@if (variable-exists(nav-primary-sub-font-style) and $nav-primary-sub-font-style != '') {
			font-style : $nav-primary-sub-font-style;
		}

		// (1)
		@if (variable-exists(nav-primary-sub-font-variant) and $nav-primary-sub-font-variant != '') {
			font-variant : $nav-primary-sub-font-variant;
		}

		// (1)
		@if (variable-exists(nav-primary-sub-text-transform) and $nav-primary-sub-text-transform != '') {
			text-transform : $nav-primary-sub-text-transform;
		}

		> li {
			> a {
				border-color : transparent;

				// (1)
				@if (variable-exists(nav-primary-sub-font-weight) and $nav-primary-sub-font-weight != '') {
				 	font-weight : $nav-primary-sub-font-weight;
				}

				&::before { display : none; }
			}

			&.sub {
				> a {
					&::before {
						content      : "\f105";
						display      : block;
						padding-left : 20px;
					}
				}

				&.active > a::before {
					content : "\f107";
				}
			}
		}
	}

	/* 3-te Untermenüebene. */
	> ul ul ul {
		font-size : 1em;

		> li {
			margin-bottom : 5px;
			margin-top    : 5px;
		}

		> li > a {
			border       : 0 none;
			padding-left : 50px;
		}
	}


	/* Globale themebasierte Farbgebungen setzen. */
	@each $t in map-keys($themes) {
		$-c : map-get-deep($themes, $t, 'brand-primary');

		li.theming-#{$t} {
			a::before { color : $-c; }

			&:hover > a,
			&:focus > a,
			&.touch-hover-helper > a,
			&.is-open > a,
			a:hover,
			a:focus { color : $-c; }

			&.active > a,
			li.active > a { color : $-c !important; }
		}

		> ul > li.theming-#{$t}.active > a { border-color : $-c; }
	}
}

/* -----------------------------------------------------------------------------
 * Panel-Menue
 * -------------------------------------------------------------------------- */

.nav-primary-mobile.mm-menu {
	$-padding              : $padding-base-horizontal-large;
	$-navbar-top-height    : 54px;
	$-navbar-bottom-height : 40px;

	$-navbar-item-height : 54px;

	// Farben des Standardmenüs.
	$-d-arrow-bg-color  : darken(desaturate(adjust-hue($brand-primary, 0.0814), 7.3683), 5.0980);
	$-d-border-color    : lighten(desaturate(adjust-hue($brand-primary, 0.0571), 14.2005), 17.0588);
	$-d-color           : $color-white;
	$-d-color-active    : $color-white;
	$-d-bg-color        : $brand-primary;
	$-d-bg-color-active : $-d-arrow-bg-color;
	$-d-bg-color-open   : $-d-border-color;

	// Farben des Metamenüs.
	$-m-arrow-bg-color  : lighten(saturate(adjust-hue($brand-secondary, 0.0000), 0.0000), 8.2353);
	$-m-border-color    : lighten(saturate(adjust-hue($brand-secondary, 0.0000), 0.0000), 23.9216);
	$-m-color           : $color-white;
	$-m-color-active    : $color-white;
	$-m-bg-color        : $brand-secondary;
	$-m-bg-color-active : $-m-arrow-bg-color;
	$-m-bg-color-open   : $-m-border-color;

	// Farben der Sportjugend.
	$-s-arrow-bg-color  : darken(desaturate(adjust-hue(map-get-deep($themes, 'sjs', 'brand-primary'), 0.0814), 7.3683), 5.0980);
	$-s-border-color    : lighten(desaturate(adjust-hue(map-get-deep($themes, 'sjs', 'brand-primary'), 0.0571), 14.2005), 17.0588);
	$-s-color           : $color-white;
	$-s-color-active    : $color-white;
	$-s-bg-color        : map-get-deep($themes, 'sjs', 'brand-primary');
	$-s-bg-color-active : $-s-arrow-bg-color;
	$-s-bg-color-open   : $-s-border-color;

	$-close-size      : 24px;
	$-close-size-half : ceil($-close-size / 2);

	background-color : $color-white;
	//box-shadow       : -5px 0 10px 5px rgba(0, 0, 0, 0.2);
	border-right     : 3px solid $color-white;
	color            : $-d-color;
	font-family      : $nav-primary-font-family;
	text-align       : left;

	&.mm-hasnavbar-top-1 {
		.mm-panels {
			top : $-navbar-top-height;

			> .mm-panel {
				&:before { display : none; }

				> .mm-listview:first-child,
				> .mm-navbar + .mm-listview {
					padding-top : 0;
					margin-top  : 0;
				}
			}
		}
	}

	.mm-navbar {
		> * {
			color   : $-d-bg-color;
			padding : 0;
		}

		&.mm-hasbtns {
			padding: 0 $-padding;
		}

		/* Navbar (Header) */
		&-top {
			background-color : $-d-color;
			border-bottom    : 0 none;
			color            : $-d-bg-color;
			height           : $-navbar-top-height;
			line-height      : 1;
			text-align       : left;

			.mm-title {
				font-size      : cast-unit($font-unit-base, $nav-primary-font-size);
				font-weight    : $font-weight-bold;
				height         : $-navbar-top-height;
				line-height    : $-navbar-top-height;
				text-transform : none;
			}

			.mm-close {
				height       : $-close-size;
				left         : initial;
				margin-top   : -#{$-close-size-half};
				padding-left : 0;
				position     : absolute;
				right        : $-padding;
				top          : 50%;
				width        : $-close-size;

				&:before,
				&:after {
					border-width : 4px;
					border-color : $-d-bg-color;
					height       : $-close-size-half;
					width        : $-close-size-half;
				}

				&:before {right: 0;}
				&:after {
					left  : 0;
					right : auto;
				}
			}
		}
	}

	/* Navbar (Footer)
	.mm-navbar-bottom {
		border-top-color : $nav-primary-item-color-hover;
		height           : $-navbar-bottom-height;
		padding          : 0 $-padding;
		text-align       : left;

		> * {
			color          : $nav-primary-item-color;
			font-weight    : $nav-primary-font-weight;
			line-height    : $-navbar-bottom-height;
			padding        : 0;
			text-transform : $nav-primary-text-transform;
			width          : auto;

			.btn-text { padding-left : $padding-base-horizontal-xsmall; }

			&:hover { color : $nav-primary-item-color-hover; }

			&:first-child { float : left; }

			&:last-child { float : right; }

			&:first-child:last-child {
				display    : block;
				float      : none;
				text-align : center;
				width      : 100%;
			}
		}

		.user-signin {
			.btn-text {
				padding-left  : 0;
				padding-right : $padding-base-horizontal-xsmall;
			}
		}
	}
	*/

	/* Navbar
	.mm-panels .mm-panel > .mm-navbar {
		background-color : $nav-primary-item-color-active;
		color            : $nav-primary-item-color;
		height           : auto;
		left             : auto;
		line-height      : 1;
		margin           : -20px -20px 15px;
		padding          : 0 $_padding;
		position         : relative;
		right            : auto;
		top              : auto;

		> a { color : $color-white; }

		.mm-title {
			display        : block;
			font-weight    : $nav-primary-font-weight;
			padding        : $padding-base-vertical 0 $padding-base-vertical $margin-base-horizontal-large;
			text-align     : left;
			text-transform : $nav-primary-text-transform;
			z-index        : 1;
		}

		> .mm-btn {
			padding    : 0;
			width      : 100%;
			z-index    : 2;
			text-align : left;

			&,
			&::before {
				border    : 0 none;
				height    : 30px;
				margin    : -15px 0 0;
				width     : 30px;
				transform : none;
				top       : 50%;
			}

			&::before {
				@extend .fa-icon-helper;

				content     : '';
				font-size   : 1.25em;
				line-height : 30px;
			}

			&.mm-prev::before {
				content : '\f104';
				left    : $_padding;
			}
		}
	} */

	/* Panel navbar
	.mm-panels .mm-panel.mm-hasnavbar {
		padding : $-padding;

		// Im ersten Panel die Navbar ausblenden.
		&:first-child {
			padding-top    : 0;
			text-transform : $nav-primary-text-transform;

			.mm-navbar { display : none; }

			.mm-listview > li > a { font-weight : $nav-primary-font-weight; }
		}
	} */

	.mm-panels > .mm-panel {
		&::after { display : none; }
	}

	@at-root html.mm-opened .mm-page {
		overflow : hidden;
	}

	.mm-listview {
		border        : 0 none;
		font-style    : $nav-primary-font-style;
		font-variant  : $nav-primary-font-variant;
		line-height   : 1;
		padding       : 0;
		margin-bottom : 0;

		/* Standards für Menüeinträge */
		li {
			border-bottom : 3px solid $-d-border-color;

			&::before,
			&:after { display : none !important; }

			> a,
			> span {
				background-color : $-d-bg-color;
				color            : $-d-color;
				font-size        : cast-unit($font-unit-base, $nav-primary-font-size);
				font-weight      : $nav-primary-font-weight;
				height           : $-navbar-item-height;
				line-height      : $-navbar-item-height;
				margin           : 0;
				padding          : 0 $-padding;
				white-space      : normal;
				z-index          : 1;

				&:hover {
					background-color : $-d-bg-color;
					color            : $-d-color;
				}
			}

			> a.active,
			> span.active {
				&,
				&:hover {
					background-color : $-d-bg-color-active;
					color            : $-d-color-active;
				}
			}

			/* Link: naechste Ebene. */
			> .mm-next {
				color   : $-d-color;
				padding : 0;
				width   : $-navbar-item-height;
				z-index : 2;

				&:before { display : none; }

				&:after {
					@extend .fa-icon-helper;

					background-color : $-d-arrow-bg-color;
					border           : 0 none;
					border-left      : 3px solid $-d-border-color;
					content          : "\f138";
					height           : $-navbar-item-height;
					line-height      : $-navbar-item-height;
					margin           : 0;
					right            : 0;
					text-align       : center;
					top              : 0;
					transform        : none;
					width            : $-navbar-item-height;
				}
			}

			&.is-fullsize-click {
				> .mm-next {
					background-color : transparent;
					left             : 0;
					width            : auto;
				}
			}

			&.mm-opened,
			&.mm-vertical.mm-opened {
				> .mm-next {
					color            : $-d-color-active;
					background-color : $-d-arrow-bg-color;

					&:after { content : "\f13a"; }
				}

				> .mm-panel {
					padding          : 0;
					background-color : $-d-bg-color-open;
				}

				&.is-fullsize-click {
					> .mm-next {
						background-color : transparent;
					}
				}
			}

			&:last-child { border-bottom : 0 none; }
		}

		/* Untermenüebene(n) */
		.mm-listview {
			padding : $-padding 0;

			li {
				$-subitem-color : $brand-secondary;
				$-subitem-height : ($-navbar-item-height - 16px);

				> a,
				> span {
					background-color : transparent;
					color            : $-subitem-color;
					font-size        : 1.0625em;
					font-weight      : $nav-primary-sub-font-weight;
					height           : $-subitem-height;
					line-height      : $-subitem-height;
					padding          : 0 ($-padding * 2);
					text-transform: none;

					&:hover,
					&:focus,
					&.active {
						background-color : transparent;
						color            : $-subitem-color;
					}

					&.active { font-weight : $font-weight-normal; }
				}

				> .mm-next {
					background-color : transparent;
					padding          : 0;

					&::after {
						background-color  : transparent;
						height            : $-subitem-height;
						line-height       : $-subitem-height;
					}
				}

				&.mm-opened > .mm-next {
					background-color : transparent;
					color            : $-subitem-color;

					&::after { background-color : transparent; }
				}
			}
		}

		.mm-listview .mm-listview {
			padding-bottom : $padding-base-horizontal;
			padding-top    : $padding-base-horizontal;

			li {
				> a,
				> span {
					padding-left : $-padding * 3;
				}

				> .mm-next { padding : 0; }
			}
		}
	}

	// Farbstyling - Meta
	.mm-listview li.meta {
		border-color : $-m-border-color;

		> a,
		> span {
			background-color : $-m-bg-color;
			color            : $-m-color;

			&:hover {
				background-color : $-m-bg-color;
				color            : $-m-color;
			}
		}

		> a.active,
		> span.active {
			&,
			&:hover {
				background-color : $-m-bg-color-active;
				color            : $-m-color-active;
			}
		}

		/* Link: naechste Ebene. */
		> .mm-next {
			color   : $-m-color;

			&:after {
				background-color : $-m-arrow-bg-color;
				border-color     : $-m-border-color;
			}
		}

		&.is-fullsize-click {
			> .mm-next { background-color : transparent; }
		}

		&.mm-opened,
		&.mm-vertical.mm-opened {
			> .mm-next {
				color            : $-m-color-active;
				background-color : $-m-arrow-bg-color;
			}

			> .mm-panel { background-color : $-m-bg-color-open; }

			&.is-fullsize-click {
				> .mm-next { background-color : transparent; }
			}
		}

		.mm-listview {
			li {
				$-subitem-color : $color-white;

				border-color : $-m-border-color;

				> a,
				> span {
					color : $-subitem-color;

					&:hover,
					&:focus,
					&.active {
						color : $-subitem-color;
					}
				}
			}
		}
	}

	// Farbstyling - SJS
	.mm-listview li.theming-sjs {
		border-color : $-s-border-color;

		> a,
		> span {
			background-color : $-s-bg-color;
			color            : $-s-color;

			&:hover {
				background-color : $-s-bg-color;
				color            : $-s-color;
			}
		}

		> a.active,
		> span.active {
			&,
			&:hover {
				background-color : $-s-bg-color-active;
				color            : $-s-color-active;
			}
		}

		/* Link: naechste Ebene. */
		> .mm-next {
			color   : $-s-color;

			&:after {
				background-color : $-s-arrow-bg-color;
				border-color     : $-s-border-color;
			}
		}

		&.is-fullsize-click {
			> .mm-next { background-color : transparent; }
		}

		&.mm-opened,
		&.mm-vertical.mm-opened {
			> .mm-next {
				color            : $-s-color-active;
				background-color : $-s-arrow-bg-color;
			}

			> .mm-panel { background-color : $-s-bg-color-open; }

			&.is-fullsize-click {
				> .mm-next { background-color : transparent; }
			}
		}

		.mm-listview {
			li {
				$-subitem-color : $color-white;

				border-color : $-s-border-color;

				> a,
				> span {
					color : $-subitem-color;

					&:hover,
					&:focus,
					&.active {
						color : $-subitem-color;
					}
				}
			}
		}
	}


	/* Menüeinträge
	.mm-listview li {
		border-color : $-d-border-color;

		> a,
		> span {
			background-color : $-d-bg-color;
			color            : $-d-color;
			font-size        : cast-unit($font-unit-base, $nav-primary-font-size);
			font-weight      : $nav-primary-font-weight;

			&:hover,
			&:focus,
			&.active {
				background-color : $-d-arrow-bg-color;
				color            : $-d-color;
			}
		}

		// Link: naechste Ebene.
		> .mm-next {
			background-color : $-d-arrow-bg-color;
			border-color     : $-d-border-color;
			color            : $-d-color;
		}

		&.mm-opened,
		&.mm-vertical.mm-opened {
			> .mm-next {
				background-color : $-d-arrow-bg-color;
			}

			> .mm-panel { background-color : $-d-bg-color-open; }
		}

		> .mm-panel {
			padding          : 0;
			background-color : $-d-arrow-bg-color;
		}

		&.meta {
			border-color : $-m-border-color;

			> a,
			> span {
				background-color : $-m-bg-color;
				color            : $-m-color;

				&:hover,
				&:focus,
				&.active {
					background-color : $-m-arrow-bg-color;
					color            : $-m-color;
				}
			}

			// Link: naechste Ebene.
			> .mm-next {
				background-color : $-m-arrow-bg-color;
				border-left-color : $-m-border-color;
				color            : $-m-color;
			}

			&.mm-opened,
			&.active.mm-opened {
				> .mm-next,
				> .mm-panel { background-color : $-m-bg-color-open; }
			}
		}
	} */

/*	.mm-listview .mm-listview li {
		border-bottom  : 0 none;

		font-style    : $nav-primary-font-style;
		font-weight   : $nav-primary-font-weight;

		> a,
		> span {
			font-weight    : $nav-primary-sub-font-weight;
			text-transform : $nav-primary-sub-text-transform;

			&:hover,
			&:focus,
			&.active {
				background-color : $-d-arrow-bg-color;
				color            : $-d-color;
			}
		}


	}*/


	/* Menüeintrage erste Ebene
	.mm-panels > .mm-panel > .mm-listview > li {} */
}

/* =============================================================================
 *
 * Meta
 *
 * ========================================================================== */

.nav-meta {
	color : $nav-meta-item-color;

	// (1)
	@if (variable-exists(nav-meta-font-family) and $nav-meta-font-family != '') {
		font-family : $nav-meta-font-family;
	}

	// (1, 2)
	@if (
		variable-exists(nav-meta-font-size) and
		type-of($nav-meta-font-size) == 'number' and
		unitless($nav-meta-font-size) == false and
		unit($nav-meta-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-meta-font-size);
	}

	// (1)
	@if (variable-exists(nav-meta-font-style) and $nav-meta-font-style != '') {
		font-style : $nav-meta-font-style;
	}

	// (1)
	@if (variable-exists(nav-meta-text-transform) and $nav-meta-text-transform != '') {
		text-transform : $nav-meta-text-transform;
	}

	// (1)
	@if (variable-exists(nav-primary-font-variant) and $nav-meta-font-variant != '') {
		font-variant : $nav-meta-font-variant;
	}

	li {
		> a { color : $nav-meta-item-color; }

		&:hover > a,
		&:focus > a,
		&.touch-helper > a,
		> a:hover,
		> a:focus { color : $nav-meta-item-color-hover; }

		&.active > a { color : $nav-meta-item-color-active !important; }

		&:hover > ul,
		&:focus > ul,
		&.touch-helper > ul { display : block; }
	}

	/*
	 * Hauptmenueebene.
	 */

	> ul {
		@include list-reset(ul, false, false);

		margin   : 0;
		position : relative;

		> li {
			display : inline-block;
			margin  : 0 $nav-meta-item-margin;
			padding : 0;

			> a {
				background-color : $nav-meta-item-bg-color;
				display          : block;
				padding          : ($gap-base + $gap-base-small) 0;

				// (1)
				@if (variable-exists(nav-meta-font-weight) and $nav-meta-font-weight != '') {
					font-weight : $nav-meta-font-weight;
				}
			}

			&:hover > a,
			&:focus > a,
			&.touch-hover-helper > a,
			> a:hover,
			> a:focus { background-color : $nav-meta-item-bg-color-hover; }

			&.active > a { background-color : $nav-meta-item-bg-color-active !important; }

			&:first-child { margin-left : 0; }

			&:last-child {
				margin-right : 0;

				> ul {
					left  : auto;
					right : 0;

					&::before {
						left  : auto;
						right : $padding-base-horizontal;
					}
				}
			}
		}
	}

	/**
	 * 2-te Untermenüebene.
	 */

	> ul ul {
		background-color : $nav-meta-sub-bg-color;
		display          : none;
		font-size        : .9375em;
		left             : 0;
		padding          : $padding-base-vertical-xsmall 0;
		position         : absolute;
		text-align       : left;
		text-transform   : none;
		top              : 100%;
		z-index          : 10; // Fix Position unterhalb des Hauptinhaltes!

		// (1)
		@if (variable-exists(nav-meta-sub-font-style) and $nav-meta-sub-font-style != '') {
			font-style : $nav-meta-sub-font-style;
		}

		// (1)
		@if (variable-exists(nav-meta-sub-text-transform) and $nav-meta-sub-text-transform != '') {
			text-transform : $nav-meta-sub-text-transform;
		}

		// (1)
		@if (variable-exists(nav-meta-sub-font-variant) and $nav-meta-sub-font-variant != '') {
			font-variant : $nav-meta-sub-font-variant;
		}

		> li {
			> a {
				background-color : $nav-meta-sub-item-bg-color;
				color            : $nav-meta-sub-item-color;
				display          : block;
				padding          : $padding-base-vertical-small $padding-base-horizontal-large;
				position         : relative;
				white-space      : nowrap;

				// (1)
				@if (variable-exists(nav-meta-sub-font-weight) and $nav-meta-sub-font-weight != '') {
					font-weight : $nav-meta-sub-font-weight;
				}
			}

			&:hover > a,
			&:focus > a,
			&.touch-hover-helper > a,
			> a:hover,
			> a:focus {
				background-color : $nav-meta-sub-item-bg-color-hover;
				color            : $nav-meta-sub-item-color-hover;
			}

			&.active > a {
				background-color : $nav-meta-sub-item-bg-color-active !important;
				color            : $nav-meta-sub-item-color-active !important;
			}
		}
	}

	> ul ul ul {
		left  : 100%;
		top   : -#{$padding-base-vertical-xsmall};
		width : 200px;

		> li > a { white-space : normal; }
	}
}

/* =============================================================================
 *
 * Service
 *
 * ========================================================================== */

.nav-service {
	@if (
		variable-exists(nav-service-font-size) and
		type-of($nav-service-font-size) == 'number' and
		unitless($nav-service-font-size) == false and
		unit($nav-service-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $nav-service-font-size);
	}

	@if (variable-exists(nav-service-text-transform)) {
		text-transform : $nav-service-text-transform;
	}

	> ul > li {
		display : inline-block;
		margin  : 0 $nav-service-item-margin;

		//&:after {
		//	content     : '|';
		//	font-weight : $font-weight-normal;
		//	padding     : 0 $padding-base-horizontal-xsmall;
		//}

		//&:last-child:after { display : none; }

		a {
			background-color : $nav-service-item-bg-color;
			color            : $nav-service-item-color;

			@if (variable-exists(nav-service-font-weight)) {
				font-weight : $nav-service-font-weight;
			}

			// Sind Hover- und Active-Farben gleich, dann fasse den Status
			// zusammen (weniger CSS).
			@if (
				$nav-service-item-bg-color-hover == $nav-service-item-bg-color-active and
				$nav-service-item-color-hover == $nav-service-item-color-active
			) {
				&:hover,
				&:focus,
				&:active,
				&.active {
					background-color : $nav-service-item-bg-color-hover;
					color            : $nav-service-item-color-hover;
				}
			} @else {
				&:hover,
				&:focus {
					background-color : $nav-service-item-bg-color-hover;
					color            : $nav-service-item-color-hover;
				}

				&:active,
				&.active {
					background-color : $nav-service-item-bg-color-active;
					color            : $nav-service-item-color-active;
				}
			}
		}

		@include respond-to(c640) {
			margin-left  : $gap-base-small;
			margin-right : $gap-base-small;
		}

		@include respond-to(c420) {
			display    : block;
			text-align : center;
			margin     : 0;
		}

		&:last-child { margin-right : 0; }

		&:first-child { margin-left : 0; }
	}
}