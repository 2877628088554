/**
 * Import von Platzhaltervorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@import 'placeholders/_transitions';
@import 'placeholders/_hidden';
@import 'placeholders/_childs';
@import 'placeholders/_center-block';
@import 'placeholders/_clear-float';
@import 'placeholders/_lists';
@import 'placeholders/_nav';