/**
 * Alerts (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.alert {
	background-color : $alert-bg-color;
	border           : $alert-border-width $alert-border-style $alert-border-color;
	color            : $alert-color;
	margin           : $alert-margin;
	padding          : $alert-padding;
	position         : relative;

	// (1)
	@if (
		variable-exists(alert-border-radius) and
		$alert-border-radius != ''
	) {
		border-radius : $alert-border-radius;
	}

	// (1)(2)
	@if (
		variable-exists(alert-font-size) and
		type-of($alert-font-size) == 'number' and
		unitless($alert-font-size) == false and
		unit($alert-font-size) == 'px'
	) {
		font-size : cast-unit($font-unit-base, $alert-font-size);
	}

	// (1)
	@if (
		variable-exists(alert-font-weight) and
		$alert-font-weight != ''
	) {
		font-weight : $alert-font-weight;
	}

	.alert-link,
	a:not(.btn) {
		// (1)
		@if (
			variable-exists(alert-link-font-weight) and
			$alert-link-font-weight != ''
		) {
			font-weight : $alert-link-font-weight;
		}

		// (1)
		@if (
			variable-exists(alert-link-text-decoration) and
			$alert-link-text-decoration != ''
		) {
			text-decoration : $alert-link-text-decoration;
		}
	}

	.close { display : none; }

	&-dismissible {
		$-offset-r : $alert-padding;
		$-offset-t : $alert-padding;

		@if (type-of($alert-padding) == 'list') {
			$-offset-r : nth($alert-padding, 2);
			$-offset-t : nth($alert-padding, 1);
		}

		padding-right : ($-offset-r * 2);

		.close {
			display  : block;
			position : absolute;
			right    : $-offset-r;
			top      : $-offset-t;
		}
	}

	&:first-child { margin-top : 0; }

	&:last-child { margin-bottom : 0; }

	@at-root {
		li.alert::before { display : none; }
	}
}

.alert-type-list {
	> * {
		&::before {
			content   : "\f0da";
			font-size : cast-unit($font-unit-base, $font-size-small);
			top       : .25em;
			color     : inherit;
		}
	}

	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }
}

.alert-success { @include alert-variant($alert-success-bg-color, $alert-success-border-color, $alert-success-color, $alert-success-color-link);}

.alert-info { @include alert-variant($alert-info-bg-color, $alert-info-border-color, $alert-info-color, $alert-info-color-link);}

.alert-warning { @include alert-variant($alert-warning-bg-color, $alert-warning-border-color, $alert-warning-color, $alert-warning-color-link);}

.alert-danger { @include alert-variant($alert-danger-bg-color, $alert-danger-border-color, $alert-danger-color, $alert-danger-color-link);}

.list-alert {
	margin: 0;
	padding: 0;

	> li {
		border-bottom-width : 0;
		border-top-width    : 0;
		margin              : 0;
		padding-bottom      : 5px;
		padding-top         : 5px;

		&:first-child {
			border-top-width : $alert-border-width;
			padding-top      : nth($alert-padding, 1);
		}

		&:last-child {
			border-bottom-width : $alert-border-width;
			padding-bottom      : nth($alert-padding, 1);
		}

		&::before { display : none; }
	}
}