/**
 * Modals (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Modal: Standard
 *
 * ========================================================================== */

.modal- {
	&open .modal {}

	&dialog {
		margin    : $modal-dialog-margin;
		max-width : 960px;
		padding   : $modal-dialog-padding;
		position  : relative;
		width     : auto;

		> * { z-index : 1; }

		> .close {
			bottom     : $modal-close-pos-bottom;
			color      : $brand-secondary;
			display    : block;
			height     : 30px;
			left       : $modal-close-pos-left;
			margin-top : 0;
			opacity    : 1;
			position   : absolute;
			right      : $modal-close-pos-right;
			top        : $modal-close-pos-top;
			width      : 30px;
			z-index    : 10;
		}
	}

	&backdrop { background-color : $modal-backdrop-bg-color; }
}

/* -----------------------------------------------------------------------------
 * Kopfzeile
 * -------------------------------------------------------------------------- */

.modal-header {
	//padding       : $modal-title-padding;
	//border-bottom : 1px solid $modal-header-border-color;

	.modal-title {}
}

/* -----------------------------------------------------------------------------
 * Inhalt
 * -------------------------------------------------------------------------- */

// Actual modal
.modal-content {
	background-color : $modal-bg-color;
	border           : $modal-border;
	border-radius    : $modal-border-radius;
	box-shadow       : $modal-box-shadow;
}

.modal-body { padding : $modal-inner-padding; }

/* -----------------------------------------------------------------------------
 * Fusszeile
 * -------------------------------------------------------------------------- */

.modal-footer {
	//padding    : $modal-inner-padding;
	//text-align : right; // right align buttons
	//border-top : 1px solid $modal-footer-border-color;
}

/* =============================================================================
 *
 * Modal: Special
 *
 * -----------------------------------------------------------------------------
 * Benoetigt wird ein zweiter 'modal-content'-Block fuer die Anzeige des
 * Ladestatus.
 *
 * <div class="modal-content modal-content-loading">
 * 		<div class="modal-header">
 * 			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
 * 				<span aria-hidden="true"><i class="fa fa-close"></i></span>
 * 			</button>
 * 		</div>
 * 		<span class="indicator"></span>
 * 	</div>
 * ========================================================================== */

.modal-special {
	.modal- {
		&header { display : none; }

		&body { z-index : 1; }

		&title { display : none; }

		&content-loading {
			display    : none;
			padding    : $modal-inner-padding;
			position   : relative;
			text-align : center;

			.indicator {
				display  : block;
				position : relative;
				width    : 100%;
			}
		}
	}

	&.is-loading .modal- {
		&content { display : none; }

		&content-loading { display : block; }
	}

	&-iframe {
		.modal-dialog,
		.modal-content,
		.modal-body,
		.modal-body > iframe { height : 100%; }

		.modal-dialog > .close {
			font-size   : 2.5em;
			height      : 40px;
			line-height : 40px;
			right       : 60px;
			top         : 30px;
			width       : 40px;
		}
	}
}