/**
 * Qualifikationrn
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

[data-module-user="qualification"] {
	.collapse {
		&-panel-content {
			padding-top : ($margin-base-vertical * 2);
			position    : relative;
		}

		[data-ctrl-qualification-remove] {
			background  : none;
			border      : 0;
			color       : $color-gray-darker;
			font-size   : 1.3125em;
			height      : auto;
			line-height : 1.25;
			padding     : 0;
			position    : absolute;
			right       : 15px;
			top         : 15px;
		}
	}
}
