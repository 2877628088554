/* Force output of above line by adding a unicode character. ♫ */
/**
 * Hauptdatei.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/*
 * Konfigurationen, Erweiterungen etc..
 */

@import 'config/_base';
@import 'helpers/_functions';
@import 'helpers/_placeholders';
@import 'helpers/_mixins';

/*
 * Abhängigkeiten.
 */

//@import 'dependencies/_normalize';
@import 'dependencies/_animations';
@import 'dependencies/_fonts';
@import 'dependencies/_icons';
@import 'dependencies/_utilities';

/*
 * Vendors.
 */

@import 'vendors/_vendors';

/*!
 * Komponenten.
 */

@import 'components/_components';

/*!
 * Module.
 */

@import 'module/_module';

/*!
 * Kernaufbau.
 */

@import 'core/_typo';
@import 'core/_scaffolding';
@import 'core/_header';
@import 'core/_showcase';
@import 'core/_breadcrumb';
@import 'core/_footer';
@import 'core/_navigation';
@import 'core/_aside';
@import 'core/_elements';

/*
 * Module.
 */

@import 'module/_module';

/*!
 * Ansichten.
 */

@import 'views/_views';

/*!
 * Mediaqueries.
 */

@import 'core/mediaqueries/_lg';
@import 'core/mediaqueries/_md';
@import 'core/mediaqueries/_sm';
@import 'core/mediaqueries/_xs';
@import 'core/mediaqueries/_c640';
@import 'core/mediaqueries/_c420';