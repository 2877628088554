/**
 * Googlemaps (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.googlemap {
	background-color    : #f2f2f2;
	backface-visibility : hidden; /* Fix Render-Visibility Chrome */
	display             : block;
	height              : $googlemap-default-height;
	margin              : 0;
	width               : auto;

	&[data-ar] { height : auto; }

	// (1)
	@if (variable-exists(googlemap-border) and $googlemap-border != '') {
		border : $googlemap-border;
	}

	// (1)
	@if (variable-exists(googlemap-padding) and $googlemap-padding != '') {
		padding : $googlemap-padding;
	}

	&::before,
	&::after {
		content  : '';
		display  : none;
		position : absolute;
		left     : 0;
		top      : 0;
		bottom   : 0;
		width    : 30px;
		z-index  : 5;
	}

	&::after {
		left  : auto;
		right : 0;
	}

	&-map {
		display  : block;
		height   : 100%;
		overflow : hidden;
		position : relative;
		width    : 100%;
		z-index  : 1;

		.gmap-marker-text {
			@if (
				variable-exists(googlemap-bubble-font-family) and
				$googlemap-bubble-font-family != ''
			) {
				font-family: $googlemap-bubble-font-family;
			}

			@if (
				variable-exists(googlemap-bubble-font-size) and
				$googlemap-bubble-font-size != '' and
				type-of($googlemap-bubble-font-size) == 'number' and
				unitless($googlemap-bubble-font-size) == false
			) {
				font-size : $googlemap-bubble-font-size;
			}

			line-height : normal;

			.title {
				color          : $brand-tertiary;
				font-weight    : bold;
				margin         : 0;
				padding-bottom : $gap-base-xsmall;
			}

			.text { margin : 0; }
		}
	}

	@at-root .is-mobile & {
		&::before,
		&::after { display : block; }
	}
}