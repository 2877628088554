/**
 * Vendors - Readspeaker
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Variablen.
 *
 * ========================================================================== */

/* =============================================================================
 *
 * Readspeaker-Standard-Definitionen über eigenen Selector setzen.
 *
 * ========================================================================== */

.rsbtn[data-readspeaker-listen] {
	margin  : $gap-base 0 !important;
	z-index : 1 !important;

	a.rsbtn {
		&_pause,
		&_stop,
		&_closer { transition : none; }
	}

	.rsbtn_powered { display : none; }
}

body .sync_ {
	&sent_highlighted {
		background-color : rgba($brand-secondary, 0.1) !important;
		font-size        : inherit;
		line-height      : inherit;
		color            : $brand-secondary !important;
	}

	&word_highlighted {
		background-color : $brand-primary !important;
		color            : $color-white !important;
	}
}

// TODO(Heiko): .btn-speak Verwendung prüfen
//.btn-speak {
//	> i + .btn-text { padding-left : 10px; }
//}