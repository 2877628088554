/**
 * Vendors - Swiper
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// Container.
.swiper {
	&-wrapper {
		margin  : 0;
		padding : 0;
	}

	&-slide {
		list-style : none;
		margin     : 0;
		padding    : 0;

		&::before { display : none; }
	}
}

// Buttons.
.swiper- {
	$-btn-fsize : 2.5em;
	$-btn-height : 44px;
	$-btn-height-half : ($-btn-height / 2);

	&button-prev,
	&button-next {
		font-size   : $-btn-fsize;
		height      : $-btn-height;
		margin-top  : -#{$-btn-height-half};
		width       : auto;

		&::before {
			@extend .fa-icon-helper;

			display     : block;
			line-height : $-btn-height;
			height      : $-btn-height;
		}

		&.swiper-button-disabled { color : $color-black; }
	}

	&button-prev::before { content : "\f104"; }

	&button-next::before { content : "\f105"; }

	@at-root & {
		/* Button 'zurück'. */
		&button-prev,
		&container-rtl &button-prev {
			background-image : none;
			color            : $brand-primary;
		}

		/* Button 'weiter'. */
		&button-next,
		&container-rtl &button-next {
			background-image : none;
			color            : $brand-primary;
		}
	}
}

// Paginate.
.swiper- {
	$-dot-size : 12px;

	&pagination-bullet {
		background : $color-white;
		height     : $-dot-size;
		opacity    : 1;
		overflow   : hidden;
		width      : $-dot-size;

		&-active { background : $brand-primary; }
	}

	&pagination-progress &pagination-progressbar { background : $brand-primary; }
}