/**
 * Seitenfuss
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.main-footer {
	$-gap-vertical : ($gap-base * 2);

	background-color : $main-footer-bg-color;
	color            : $main-footer-color;

	> .bar {
		> .bar-wrap {
			@extend .page-max-width;

			line-height     : 1;
			padding-bottom  : $padding-base-vertical;
			padding-top     : $padding-base-vertical;
		}

		&:nth-child(1) {
			> .bar-wrap {
				align-content   : center;
				display         : flex;
				flex-flow       : row wrap;
				justify-content : space-between;

				> * {
					align-items     : center;
					align-self      : center;
					//flex            : 0 1 auto;
				}

				@include respond-to(c640) {
					flex-flow : column wrap;
				}
			}
		}

		&:nth-child(2) { background-color : $color-gray-light; }
	}

	.nav-service li a {
		margin-bottom : $nav-service-item-margin;
		margin-top    : $nav-service-item-margin;

		@include respond-to(c420) {
			margin-bottom : $gap-base-small;
			margin-top    : $gap-base-small;
		}
	}

	.social {
		// (1, 2)
		@if (
			variable-exists(nav-font-size) and
			type-of($nav-font-size) == 'number' and
			unitless($nav-font-size) == false and
			unit($nav-font-size) == 'px'
		) {
			font-size : cast-unit($font-unit-base, $nav-font-size);
		}

		> a {
			color           : $nav-service-item-color;
			display         : inline-block;
			margin          : $nav-service-item-margin;
			text-decoration : none;

			&:first-child { margin-left : 0; }

			&:last-child { margin-right : 0; }

			&:hover,
			&:active,
			&:focus { color : $nav-service-item-color-hover; }
		}
	}

	dl.sponsors-supporters {
		clear : none;
		float : left;
		width : 50%;

		> dt {
			color     : darken(saturate(adjust-hue($color-gray-light, 0.0000), 0.0000), 16.8627);
			font-size : cast-unit($font-unit-base, ($h1-font-size - 10));
			margin    : 0;
		}

		> dd {
			float         : left;
			margin-top    : $gap-base-large;
			padding-right : $gap-base-large;
		}

		a {
			display : block;

			img {
				filter     : grayscale(100%);
				height     : 70px;
				opacity    : .45;
				transition : all .75s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
				width      : auto;
			}

			&:hover,
			&:focus {
				img {
					filter  : grayscale(0);
					opacity : 1;
				}
			}
		}

		&:first-child { padding-right: $gap-base; }

		&:last-child { padding-left : $gap-base; }

		&:first-child:last-child {
			width         : 100%;
			padding-left  : 0;
			padding-right : 0;
		}
	}
}