/**
 * Popover (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.popover {
	color : $popover-color;

	// Hintergrundfarbe (1)(2)
	@if (
		variable-exists(popover-bg-color) and
		$popover-bg-color != ''
	) {
		background-color : $popover-bg-color;
	}

	// Rahmen (1)(2)
	@if (
		variable-exists(popover-border-color) and
		$popover-border-color != '' and
		variable-exists(popover-border-style) and
		$popover-border-style != '' and
		variable-exists(popover-border-width) and
		$popover-border-width != ''
	) {
		border : $popover-border-width $popover-border-style $popover-border-color;
	} @else {
		@if (
			variable-exists(popover-border-color) and
			$popover-border-color != ''
		) {
			border-color: $popover-border-color;
		}

		@if (
			variable-exists(popover-border-style) and
			$popover-border-style != ''
		) {
			border-style: $popover-border-style;
		}

		@if (
			variable-exists(popover-border-width) and
			$popover-border-width != ''
		) {
			border-width: $popover-border-width;
		}
	}

	// Rahmenradius (1)(2)
	@if (
		variable-exists(popover-border-radius) and
		$popover-border-radius != ''
	) {
		border-radius : $popover-border-radius;
	}

	// Schatten (1)(2)
	@if (
		variable-exists(popover-box-shadow) and
		$popover-box-shadow != ''
	) {
		box-shadow : $popover-box-shadow;
	}

	// Schriftgröße (1)(2)
	@if (
		variable-exists(popover-font-size) and
		type-of($popover-font-size) == 'number' and
		unitless($popover-font-size) == false
	) {
		font-size : cast-unit($font-unit-base, $popover-font-size);;
	}

	// Schriftgewicht (1)(2)
	@if (
		variable-exists(popover-font-weight) and
		$popover-font-weight != ''
	) {
		font-weight : $popover-font-weight;
	}

	// Max. Breite (1)
	@if (
		variable-exists(popover-max-width) and
		$popover-max-width != ''
	) {
		max-width : $popover-max-width;
	}

	// Transparenz(eingeblendet) (1)
	@if (
		variable-exists(popover-opacity) and
		type-of($popover-opacity) == 'number' and
		$popover-opacity > 0
	) {
		&.fade.in { opacity : $popover-opacity; }
	}

	// Pfeilausrichtung(en) (1)(2)
	@if (
		variable-exists(popover-arrow-width) and
		$popover-arrow-width != ''
	) {
		&.top     { margin-top: -#{$popover-arrow-width}; }

		&.right   { margin-left: $popover-arrow-width; }

		&.bottom  { margin-top: $popover-arrow-width; }

		&.left    { margin-left: -#{$popover-arrow-width}; }
	}

	.popover-title {
		// Schriftgröße (1)(2)
		@if (
			variable-exists(popover-title-font-size) and
			type-of($popover-title-font-size) == 'number' and
			unitless($popover-title-font-size) == false
		) {
			font-size : cast-unit($font-unit-base, $popover-title-font-size);
		}

		// Hintergrundfarbe (1)(2)
		@if (
			variable-exists(popover-title-bg-color) and
			$popover-title-bg-color != ''
		) {
			background-color : $popover-title-bg-color;
		}

		// Rahmenfarbe (1)(2)
		@if (
			variable-exists(popover-title-border-color) and
			$popover-title-border-color != '' and
			variable-exists(popover-title-border-style) and
			$popover-title-border-style != '' and
			variable-exists(popover-title-border-width) and
			$popover-title-border-width != ''
		) {
			border-bottom : $popover-title-border-width $popover-title-border-style $popover-title-border-color;
		} @else {
			@if (
				variable-exists(popover-title-border-color) and
				$popover-title-border-color != ''
			) {
				border-bottom-color: $popover-title-border-color;
			}

			@if (
				variable-exists(popover-title-border-style) and
				$popover-title-border-style != ''
			) {
				border-bottom-style: $popover-title-border-style;
			}

			@if (
				variable-exists(popover-title-border-width) and
				$popover-title-border-width != ''
			) {
				border-bottom-width: $popover-title-border-width;
			}
		}

		// Rahmenradius (1)(2)
		@if (
			variable-exists(popover-border-radius) and
			$popover-border-radius != ''
		) {
			@if (
				variable-exists(popover-border-width) and
				$popover-border-width > 0
			) {
				border-radius : ($popover-border-radius - $popover-border-width) ($popover-border-radius - $popover-border-width) 0 0;
			} @else {
				border-radius : $popover-border-radius $popover-border-radius 0 0;
			}
		}

		// Innenabstand (1)(2)
		@if (
			variable-exists(popover-title-padding) and
			$popover-title-padding != ''
		) {
			padding : $popover-title-padding;
		}

		// Textausrichtung (1)(2)
		@if (
			variable-exists(popover-title-text-align) and
			$popover-title-text-align != ''
		) {
			text-align : $popover-title-text-align;
		}
	}

	// (1)(2)
	@if (
		variable-exists(popover-content-padding) and
		$popover-content-padding != ''
	) {
		.popover-content { padding : $popover-content-padding; }
	}

	// Pfeil (1)(2)
	@if (
		variable-exists(popover-arrow-width) and
		$popover-arrow-width != ''
	) {
		> .arrow {
			@if (
				variable-exists(popover-border-width) and
				$popover-border-width != ''
			) {
				border-width: ($popover-arrow-width + $popover-border-width);
			} @else {
				border-width: $popover-arrow-width;
			}

			&:after { border-width : $popover-arrow-width; }
		}
	}
}

/**
 * Ausrichtungen
 */

.popover {
	$-outer-arrow-width : 0 !default;

	// Pfeildefinitionen (1)(2)
	@if (
		variable-exists(popover-arrow-width) and
		$popover-arrow-width != ''
	) {
		@if (
			variable-exists(popover-border-width) and
			$popover-border-width != ''
		) {
			$-outer-arrow-width : ($popover-arrow-width + $popover-border-width);
		} @else {
			$-outer-arrow-width : $popover-arrow-width;
		}
	}

	&.top > .arrow {
		@if ($-outer-arrow-width > 0)
		{
			margin-left : -#{$-outer-arrow-width};
		}

		@if (
			variable-exists(popover-border-color) and
			$popover-border-color != ''
		) {
			border-top-color : $popover-border-color;
		}

		@if ($-outer-arrow-width > 0)
		{
			bottom : -#{$-outer-arrow-width};
		}

		&:after {
			margin-left : -$popover-arrow-width;

			@if (
				variable-exists(popover-bg-color) and
				$popover-bg-color != ''
			) {
				border-top-color : $popover-bg-color;
			}
		}
	}

	&.right > .arrow {
		@if ($-outer-arrow-width > 0)
		{
			left       : -#{$-outer-arrow-width};
			margin-top : -#{$-outer-arrow-width};
		}

		@if (
			variable-exists(popover-border-color) and
			$popover-border-color != ''
		) {
			border-right-color : $popover-border-color;
		}

		&:after {
			bottom : -$popover-arrow-width;

			@if (
				variable-exists(popover-border-color) and
				$popover-border-color != ''
			) {
				border-right-color : $popover-border-color;
			}
		}
	}

	&.bottom > .arrow {
		@if ($-outer-arrow-width > 0)
		{
			margin-left : -#{$-outer-arrow-width};
			top         : -#{$-outer-arrow-width};
		}

		@if (
			variable-exists(popover-border-color) and
			$popover-border-color != ''
		) {
			border-bottom-color : $popover-border-color;
		}

		&:after {
			margin-left : -$popover-arrow-width;

			@if (
				variable-exists(popover-border-color) and
				$popover-border-color != ''
			) {
				border-bottom-color : $popover-border-color;
			}
		}
	}

	&.left > .arrow {
		@if ($-outer-arrow-width > 0)
		{
			margin-top : -#{$-outer-arrow-width};
			right      : -#{$-outer-arrow-width};
		}

		@if (
			variable-exists(popover-border-color) and
			$popover-border-color != ''
		) {
			border-left-color : $popover-border-color;
		}

		&:after {
			bottom : -$popover-arrow-width;

			@if (
				variable-exists(popover-border-color) and
				$popover-border-color != ''
			) {
				border-left-color : $popover-border-color;
			}
		}
	}
}

/**
 * Alternative Stile
 */

.popover-primary {
	@include popover-variant($color-white, $brand-primary, $brand-primary, $popover-opacity);
}

.popover-secondary {
	@include popover-variant($color-white, $brand-secondary, $brand-secondary, $popover-opacity);
}

.popover-tertiary {
	@include popover-variant($color-white, $brand-tertiary, $brand-tertiary, $popover-opacity);
}