/**
 * Tabs (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.tab {
	@extend %child-reset-margin-last-top;

	display : block;
	z-index : 1;

	.nav-tabs {
		//border-bottom  : $tabs-pane-border-width $tabs-pane-border-style $tabs-pane-border-color;
		border-bottom  : 0 none;
		display        : flex;
		flex-flow      : row wrap;
		text-transform : none;

		> li {
			flex          : 1 0 auto;
			//margin-bottom : -#{$tabs-pane-border-width};

			&:last-child { margin-right : 0; }

			> a {
				background-color : $tabs-toggle-bg-color;
				border-color     : $tabs-toggle-border-color;
				border-radius    : $tabs-border-radius;
				border-style     : $tabs-toggle-border-style;
				border-width     : $tabs-toggle-border-width $tabs-toggle-border-width $tabs-toggle-border-width 0 !important;
				color            : $tabs-toggle-color;
				height           : $tabs-toggle-height;
				line-height      : $line-height-base;
				margin           : 0;
				padding          : $tabs-toggle-padding;
				text-align       : center;
				text-decoration  : $tabs-toggle-text-decoration;

				// (1)(2)
				@if (
					variable-exists(tabs-toggle-font-size) and
					type-of($tabs-toggle-font-size) == 'number' and
					unitless($tabs-toggle-font-size) == false and
					unit($tabs-toggle-font-size) == 'px'
				) {
					font-size : cast-unit($font-unit-base, $tabs-toggle-font-size);
				}

				// (1)
				@if (
					variable-exists(tabs-toggle-font-weight) and
					$tabs-toggle-font-weight != ''
				) {
					font-weight : $tabs-toggle-font-weight;
				}

				&:hover,
				&:focus {
					background-color : $tabs-toggle-bg-color-hover;
					border-color     : $tabs-toggle-border-color-hover;
					color            : $tabs-toggle-color-hover;
					text-decoration  : $tabs-toggle-text-decoration;
				}
			}

			&:first-child > a {
				border-bottom-left-radius : $tabs-border-radius;
				border-left               : $tabs-toggle-border-width $tabs-toggle-border-style $tabs-toggle-border-color !important;
				border-top-left-radius    : $tabs-border-radius;
			}

			&:last-child > a {
				border-bottom-right-radius : $tabs-border-radius;
				border-top-right-radius    : $tabs-border-radius;
			}

			&.active > a {
				&,
				&:hover,
				&:focus {
					background-color : $tabs-toggle-bg-color-active;
					border-color     : $tabs-toggle-border-color-active;
					border-width     : $tabs-toggle-border-width;
					border-style     : $tabs-toggle-border-style;
					color            : $tabs-toggle-color-active;
					cursor           : $cursor-default;
					text-decoration  : $tabs-toggle-text-decoration;
				}
			}
		}
	}

	.tab-pane {
		&-content {
			border     : $tabs-pane-border-width $tabs-pane-border-style $tabs-pane-border-color;
			border-top : 0 none;
			padding    : $tabs-pane-padding;

			@extend %child-reset-margin-last-top;
		}
	}

	&-to-collapse {
		.toggle-tab-collapse { display : none; }
	}
}