
/* Force output of above line by adding a unicode character. ♫ */
/**
 * Hauptdatei.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
 * Konfigurationen, Erweiterungen etc..
 */
/**
 * Basis
 *
 * Hauptkonfiguration mit zusaetzlichem Konfigurationsimport am Ende.
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * Sass Colour Function Calculator
 * http://razorltd.github.io/sasscolourfunctioncalculator/
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Schriftangaben
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Abstaende, Radius etc.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Breakpoints (Mediaqueries)
 *
 * -----------------------------------------------------------------------------
 * Desktop-First-Methode
 * ========================================================================== */
/* =============================================================================
 *
 * Cursor
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Farben
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Status
 * -------------------------------------------------------------------------- */
/* =============================================================================
 *
 * Theme-Map
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Konfigurationsimport
 *
 * -----------------------------------------------------------------------------
 * Zwecks Uebersichtlichkeit ausgegliedert/separiert.
 * ========================================================================== */
/**
 * Schrift
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Body
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Ueberschriften
 *
 * ========================================================================== */
/* =============================================================================
 *
 *  Sonstiges
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Code
 * -------------------------------------------------------------------------- */
/**
 * Grundgeruest
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Grundcontainer der Seite
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Seitenkopf
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Seitenfuss
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Showcase
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Breadcrumb
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Seite
 * -------------------------------------------------------------------------- */
/**
 * Navigation(en)
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Hauptnavigation.
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Untermenue.
 * -------------------------------------------------------------------------- */
/* =============================================================================
 *
 * Servicenavigation.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Metanavigation.
 *
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Untermenue.
 * -------------------------------------------------------------------------- */
/**
 * Komponenten
 *
 * Grundkonfiguration und Konfigurationsimport einzelner Komponenten.
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Globaler Innenabstaende einzelnder Komponenten (Buttons, Input).
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Globale Rahmenangaben.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Globaler Borderadius einzelner Komponenten.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Konfigurationsimport einzelner komponenten.
 *
 * ========================================================================== */
/**
 * Links
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Formulare
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Fieldset
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Label
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Eingabe-, Auswahlelemente
 * -------------------------------------------------------------------------- */
/*
 * Border radius
 */
/*
 * Events, Status
 */
/*
 * Input Group
 */
/* -----------------------------------------------------------------------------
 * Datepicker
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Platzhalter
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Trenner
 * -------------------------------------------------------------------------- */
/**
 * Styled Checkboxes/Radiobuttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Bilder
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Indikatoren (Ladeanzeige)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Rating stars
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Dropdowns
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Alerts
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Buttons
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Standard
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Primaer
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Sekundaer
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Tertiaer
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Success
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Info
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Disabled
 * -------------------------------------------------------------------------- */
/**
 * Navigationen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Global
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Breadcrumb
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Step Wizard
 *
 * ========================================================================== */
/**
 * Seitenblaettern (Pagination)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Vor- und Zurückbutton
 */
/**
 * Tabs
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Toggle
 */
/**
 * Pane
 */
/**
 * Tabellen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * @.table
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @.table-condensed
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @.table-bordered
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @.table-striped
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @Hover
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * @Hintergrundfarben
 * -------------------------------------------------------------------------- */
/**
 * Listen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Liste mit Links
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Liste mit Rahmen
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Tabellenliste
 * -------------------------------------------------------------------------- */
/**
 * Listengruppen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Panels
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Wells
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * 'Wells' scrollbar.
 * -------------------------------------------------------------------------- */
/**
 * Collapse
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Toggle
 */
/**
 * Panel
 */
/**
 * Modals
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Tooltipps
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Popver
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Video, Audio
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Visitenkarten
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Galerien
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Googlemaps
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Stapel
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Artikels
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Import von Funktionen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Be- und Umrechnungen (PX, EM, REM, ...).
 *
 * @author Stephen Rushing, eSiteful
 * @url http://github.com/stephenr85/sass-unity
 */
/**
 * If a number has a .00001 decimal, it's probably a rounding issue and needs
 * to be dropped.
 * Example:
 *     sub-px(13.00005px) = 13px
 *
 * @function sub-px
 * @param string/number $dim
 *
 * return number
 */
/**
 * Removes the unit from a number.
 * Example:
 *     strip-unit(16px) = 16
 *
 * @function strip-unit
 * @param string/number $num
 *
 * return number
 */
/**
 * Find the first unit in a list of numbers.
 *
 * Examples:
 *     first-unit(0 12% 10% 1px) = px
 *
 * @function first-unit
 * @param number/list $dims
 *
 * return string
 */
/**
 * Convert any number to a px, or ensure a px value.
 * Percentages are retained. Use percent() to calculate static values.
 *
 * Examples:
 *     px(1rem) = 16px
 *     px(1 4rem 2em 10%) = 1px 64px 26px 10%
 *
 * @function px
 * @param number/list $dim
 *
 * return number A px value
 */
/**
 * Convert a number to an rem, or ensure an rem value.
 * Percentages are retained. Use percent() to calculate static values.
 *
 * @function rem
 * @param number $dim
 *
 * return number A rem value
 */
/**
 * Sometimes em values are still viable, obviously, but you have to know the
 * context for calculations. The baseline is stored, so each subsequent call
 * will use the previous $baseline parameter, if none is passed.
 *
 * @function em
 * @param number $dim
 * @param number $baseline [global $em-baseline] However many px = 1em,
 *                                               or the relative em of the
 *                                               current context.
 * @param boolean $adjust  [false]               When true, adjusts the global
 *                                               $em-baseline to whatever the
 *                                               result is. This is useful if
 *                                               you want to set things
 *                                               font-size: em(12px).
 *
 * return number A em value
 */
/**
 * Convert arbitrary units to or from percentages.
 * This works a little differently than the other unit functions, because it
 * does not retain units of its own type. In other words, a % value will be
 * converted, rather than returned entact.
 *
 * Examples:
 *     percent(24px, 240px) = 10%
 *     percent(1.5rem, 240px) = 10%
 *     percent(10%, 240px) = 24px
 *     percent(10%, rem(240px)) = 1.5rem
 *
 * @function percent
 * @param number $dim     If a %
 * @param number $context The total width for the calculation of the percentages.
 *
 * @return number If $dim is a % value, returns the percent calculation in the
 *                same unit as $context.
 *                If $dim is another unit, a % will be returned.
 */
/**
 * Visually relative to the pixels per inch of a device, but provides a
 * consistent mathmatical formula for converting units to pts for print.
 * Totally untested with actual print at this point.
 *
 * Examples:
 *     pt(16px) = 12pt
 *
 * @function pt
 * @param number $dim
 * @param number $px-per-inch Defaults to global $px-per-inch (96).
 * @param number $pt-per-inch Defaults to global $pt-per-inch (72).
 *
 * @return number A pt value.
 */
/**
 * Visually relative to the pixels per inch of a device, but provides a
 * consistent mathmatical formula for converting units to pts for print.
 * Totally untested with actual print at this point.
 *
 * Examples:
 *     cast-unit(px, 3em, 4em, (5))
 *
 * @function cast-unit
 * @param string $unit [em,pt,px,rem]
 * @param number $dims
 */
/**
 * Convert all numbers to the same unit type. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     unity(12px, 1em) = unity(12px, 1em)
 *     unity(1em, 14px, 1.2rem, (16, 8%)) = 1em .25em 1.45em 16em 8%
 *
 * @function unity
 * @param number $dims
 *
 * @return number
 */
/**
 * Add numbers, normalizing them first. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     plus(1rem, 4px, 7em, 5)
 *
 * @function plus
 * @param number $dims
 *
 * @return number
 */
/**
 * Subtract numbers, normalizing them first. Percentages are ignored.
 * Convert them first with percent(), if necessary.
 *
 * Examples:
 *     minus(20rem, 4px, 7em, 5)
 *
 * @function minus
 * @param number $dims
 *
 * @return number
 */
/**
 * Use rem units with px fallback.
 * Specify a px, em, or rem value with each property. Unitless values will be
 * considered an rem.
 *
 * Examples:
 *     rem(font-size 12px, line-height 1.4em)
 *
 * @mixin rem
 * @param string $properties
 *
 * @return number
 */
/**
 * Nested Map-Funktionen.
 *
 * (https://github.com/at-import/Sassy-Maps/tree/0.x.x/sass/sassy-maps)
 *
 * @author Hugo Giraudel
 * @author Heiko Pfefferkorn (https://i-fabrik.de, Modifications)
 */
/**
 * Map Get Deep Warnungen.
 *
 * Ausgabe einer Warnung wenn abgerufener Wert `null` sein sollt.
 *
 * return null
 */
/**
 * Angefragte Werte in Nested-Maps finden und zurückgeben.
 *
 * return {*}
 */
/**
 * Einige Hilfsfunktionen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Zeilenhoehe kalkulieren.
 *
 * @param number $base
 * @param number $target
 *
 * return number
 */
/**
 * Kleinster gemeinsamer Teiler.
 *
 * @param number $a
 * @param number $b
 *
 * return number
 */
/**
 * Aspect-Ratio durch Angabe von Höhe und Breite bestimmen.
 *
 * @param number $x  Breite (Numerator 1)
 * @param number $y  Höhe  (Denominator 1)
 *
 * return number Prozent
 */
/**
 * Durch Angabe einer Originalbreite und -hoehe und einer Zielbreite oder -hoehe
 * wird per Berechnung des Seitenverhaeltnisses die fehlende Zielbreite- oder
 * -hoehe berechnet.
 *
 * @param number $x  Originalbreite (Numerator 1)
 * @param number $y  Originalhoehe  (Denominator 1)
 * @param number $x2 Zielbreite (Numerator 2)
 * @param number $y2 Zielhoehe (Denominator 2)
 *
 * return number Zielbreite oder -hoehe
 */
/**
 * Import von Platzhaltervorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Transitions.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.btn {
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

a, .collapse-toggle, .collapse-toggle::before {
  transition: background-color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, border-color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, color 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }

/**
 * Element ausblenden.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Kindelemente anpassen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.tab > :first-child, .tab .tab-pane-content > :first-child, .panel-body > :first-child, .panel-heading > :first-child, .panel-footer > :first-child, .collapse > :first-child, .collapse-panel-content > :first-child, .gallery-list > .item > :first-child {
  margin-top: 0; }

.tab > :last-child, .tab .tab-pane-content > :last-child, .panel-body > :last-child, .panel-heading > :last-child, .panel-footer > :last-child, .collapse > :last-child, .collapse-panel-content > :last-child, .gallery-list > .item > :last-child {
  margin-bottom: 0; }

/**
 * Block-Level-Element zentrieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

/**
 * Block-Level-Element zentrieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.clearfix:after, .nav:after, .list-table:after, .stack:after, .clearfloat, ul,
ol,
dl, .gallery, .box__footer:after, p,
blockquote,
pre,
hr {
  clear: both; }

/**
 * Listen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Navigationen
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.nav ul,
.nav ol {
  border: 0 none;
  list-style: none;
  margin: 0;
  padding: 0; }

.nav li {
  background-image: none;
  border: 0 none;
  margin: 0;
  padding: 0;
  position: relative; }

.nav a {
  display: inline-block;
  position: relative;
  text-decoration: none; }

/**
 * Import von Vorlagen, wiederverwendbaren Mustern.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Utilities
 * -------------------------------------------------------------------------- */
/**
 * Overflow-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Overflow setzen.
 *
 * Examples:
 *     .selector {
 *         @include overflow(x, scroll);
 *     }
 *
 * @param string  $direction 'x' oder 'y'
 * @param boolean $type      'hidden', 'scroll' etc..
 */
/**
 * Mediaqueries generieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Mediaqueries generieren.
 *
 * @param string  $name Schluessel in der $breakpopints-Map.
 * @param boolean $push Schluessel hinzufuegen.
 */
/**
 * Text.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Tab-Focus
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Events.
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Komponenten
 * -------------------------------------------------------------------------- */
/**
 * Alerts
 *
 * Bootstrap adaptierte und abgewandelte Variante.
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Alert variant.
 * (Bootstrap adaptiert)
 *
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe (wenn null dann autom. Bestimmung
 *                              per darken() anhand $bg-color)
 * @param string  $color        Textfarbe (wenn null dann autom. Bestimmung per
 *                              darken() anhand $bg-color)
 * @param string  $link-color   Textfarbe (wenn null dann autom. Bestimmung per
 *                              darken() anhand $bg-color)
 */
/**
 * Alerts.
 *
 * @author Bootstrap
 * @author Heiko Pfefferkorn (https://i-fabrik.de, Modifications)
 */
/**
 * Buttontyp.
 * (Bootstrap adaptiert)
 *
 * @param string  $color              Textfarbe
 * @param string  $bg-color            Hintergrundfarbe
 * @param string  $border-color       Rahmenfarbe
 * @param string  $color-hover        Textfarbe Mouseover etc. (Standard '$color').
 * @param string  $bg-color-hover      Hintergrundfarbe Mouseover. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()'.
 * @param string  $border-color-hover Rahmenfarbe Mouseover. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()'.
 */
/**
 * Buttongroesse.
 * (Bootstrap adaptiert)
 *
 * @param string  $padding-vertical
 * @param string  $padding-horizontal
 * @param string  $line-height
 * @param string  $border-radius
 */
/**
 * Hamburger.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Hamburger.
 *
 * @param string  $options  Map mit optionalen Einstellungen
 */
/**
 * Panels.
 *
 * Bootstrap adaptierte und abgewandelte Variante.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Panel variant.
 * (Bootstrap adaptiert)
 *
 * @param string  $border-color         Rahmenfarbe
 * @param string  $heading-color        Ueberschrift - Textfarbe
 * @param string  $heading-bg-color     Ueberschrift - Hintergrundfarbe
 * @param string  $heading-border-color Ueberschrift - Rahmenfarbe
 */
/**
 * Backgrounds.
 *
 * @author Bootstrap
 */
/**
 * Textfarbe plus :hover.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
/**
 * Textfarben.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
/**
 * Listen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Events einem Formularelement zuweisen.
 * (Bootstrap adaptiert)
 *
 * @param string  $event        'hover', 'active', 'focus'
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
/**
 * Mit Statusklassen versehene Formularelemente bezueglich ihrer Fabrgebung etc.
 * definieren.
 * (Bootstrap adaptiert)
 *
 * Beispiele:
 *     .form-control.info
 *     .form-control.valid
 *     .form-control.warning
 *
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
/*
 * Formularelemente-Validierungsstatus bezueglich ihrer Farbgebung etc.
 * definieren.
 * (Bootstrap adaptiert)
 *
 * Beispiele:
 *     .has-error [Formularelemnte]
 *     .has-success [Formularelemnte]
 *     .has-warning [Formularelemnte]
 *
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
/**
 * Tabellen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Farbvarianten (Zeile, Zelle).
 * (Bootstrap adaptiert)
 *
 * @param string  $state
 * @param string  $bg-color     Hintergrundfarbe
 */
/**
 * Pagination-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Pagination
 * (Bootstrap adaptiert)
 *
 * @param string  $padding-vertical   Vertikaler Innenabstand
 * @param string  $padding-horizontal Horizontaler Innenabstand
 * @param string  $font-size          Schriftgroesse
 * @param string  $border-radius      Border-Radius
 */
/**
 * Popover.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Popover-Variante.
 *
 * @param string  $color              Textfarbe
 * @param string  $bg-color            Hintergrundfarbe
 * @param string  $border-color       Rahmenfarbe. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()' von $bg-color.
 * @param string  $opacity            Transparenz.
 */
/* -----------------------------------------------------------------------------
 * Skins
 * -------------------------------------------------------------------------- */
/**
 * Backgrounds.
 *
 * @author Bootstrap
 */
/**
 * Buttontyp.
 * (Bootstrap adaptiert)
 *
 * @param string  $parent Selector
 * @param string  $color  Farbe
 */
/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Horizontaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 */
/**
 * Vertikaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 */
/**
 * Diagonaler Farbverlauf.
 *
 * @param string $start-color   HEX oder RGG
 * @param string $end-color     HEX oder RGG
 * @param string $start-percent Startposition (%, px)
 * @param string $end-percent   Endposition (%, px)
 * @param string $deg           Gradanzahl
 */
/* -----------------------------------------------------------------------------
 * Layout
 * -------------------------------------------------------------------------- */
/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Neue Grid-Spaltendefinitionen hinzufügen.
 *
 * @param array $gutter-width Spaltenabstand
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
/**
 * Grid-Spalten generieren.
 *
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
/**
 * Grid-Variante generieren.
 *
 * @param string $ident        Teil des neuen Selectors der an '.row-' angefuegt
 *                             wird. Bsp. (md): .row-md
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
/**
 * Box-Model-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Box-Model setzen.
 *
 * @param string  $val
 */
/**
 * Clearfix-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Clearfix fuer moderne Browser
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 *
 * Source: http://nicolasgallagher.com/micro-clearfix-hack/
 */
/**
 * Ausrichtungen/Zentrierung von Elementen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Element zentrieren.
 *
 * @param string $width
 */
/**
 * Listen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Liste zuruecksetzen.
 *
 * @param string  $type                Eine ungeordnete (ul) oder geordnete
 *                                     Liste (ol)
 * @param boolean $remove-list-margin  Listenaussenabstaende entfernen
 * @param boolean $remove-list-padding Listeninnenabstaende entfernen
 * @param boolean $remove-item-margin  Listeneintragaussenabstaende entfernen
 * @param boolean $remove-item-padding Listeneintraginnenabstaende entfernen
 */
/*
 * Abhängigkeiten.
 */
/**
 * Animationen (Keyframes).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
@keyframes dropdown-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes link-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes fade-in-out {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/**
 * Schrifteneinbindung
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/open-sans-light.woff") format("woff"), url("../fonts/open-sans/open-sans-light.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/open-sans-regular.woff") format("woff"), url("../fonts/open-sans/open-sans-regular.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/open-sans-semibold.woff") format("woff"), url("../fonts/open-sans/open-sans-semibold.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open-sans/open-sans-bold.woff") format("woff"), url("../fonts/open-sans/open-sans-bold.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 700; }

/**
 * Icons
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Hilfsklasse fuer '@extend' (Iconschriften: 'FontAwesome').
 *
 * ========================================================================== */
.fa-icon-helper, .swiper-button-prev::before, .swiper-button-next::before, a[class*="link-"]::before,
span[class*="link-"]::before, .form-control-datepicker::after, .thumbnail .zoom::before, .dropdown-toggle .caret, .pager-icons li.pager-prev > *::before,
.pager-icons li.pager-next > *::before, ul > li::before, .collapse-toggle::before, blockquote:before, .nav-primary li a::before, .nav-primary-mobile.mm-menu .mm-listview li > .mm-next:after {
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: optimizeSpeed; }

/**
 * Utilities
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
 * Floats
 */
.clearfix:before, .nav:before, .list-table:before, .stack:before, .clearfix:after, .nav:after, .list-table:after, .stack:after {
  content: ' ';
  /* 1 */
  display: table;
  /* 2 */ }

.float-left {
  float: left; }

.float-right {
  float: right; }

/*
 * Zentrierung von Bloecken.
 */
/*
 * Sichtbarkeit von Elementen.
 */
.show {
  display: block !important; }

.invisible {
  visibility: hidden !important; }

/* Ausblenden fuer Screenreader und Browser (Credit: HTML5 Boilerplate). */
.hidden {
  display: none !important;
  visibility: hidden !important; }

/*
 * Overflow-Eigenschaften.
 */
.prevent-overflow {
  overflow: hidden; }

.prevent-overflow-x {
  overflow-x: hidden; }

.prevent-overflow-y {
  overflow-y: hidden; }

/*
 * Zeilenumbruch verhindern.
 */
.prevent-text-break {
  white-space: nowrap; }

/*
 * Element klickbar (Cursor setzen).
 */
.is-clickable {
  cursor: pointer; }

/* =============================================================================
 *
 * Aussen-, Innenabstaende setzen/entfernen.
 *
 * ========================================================================== */
.no-margin {
  margin: 0; }

.no-bottom-margin {
  margin-bottom: 0; }

.no-left-margin {
  margin-left: 0; }

.no-right-margin {
  margin-right: 0; }

.no-top-margin {
  margin-top: 0; }

.padding-bottom-xs {
  padding-bottom: 3.75px; }

.padding-bottom-sm {
  padding-bottom: 7.5px; }

.padding-bottom-md {
  padding-bottom: 15px; }

.padding-bottom-lg {
  padding-bottom: 30px; }

.padding-bottom-xl {
  padding-bottom: 60px; }

/*
 * Vendors.
 */
/**
 * Vendors
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Vendors - Cookiebar
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Vendors - Readspeaker
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Variablen.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Readspeaker-Standard-Definitionen über eigenen Selector setzen.
 *
 * ========================================================================== */
.rsbtn[data-readspeaker-listen] {
  margin: 15px 0 !important;
  z-index: 1 !important; }
  .rsbtn[data-readspeaker-listen] a.rsbtn_pause, .rsbtn[data-readspeaker-listen] a.rsbtn_stop, .rsbtn[data-readspeaker-listen] a.rsbtn_closer {
    transition: none; }
  .rsbtn[data-readspeaker-listen] .rsbtn_powered {
    display: none; }

body .sync_sent_highlighted {
  background-color: rgba(42, 137, 207, 0.1) !important;
  font-size: inherit;
  line-height: inherit;
  color: #2A89CF !important; }

body .sync_word_highlighted {
  background-color: #92C5EB !important;
  color: #fff !important; }

/**
 * Vendors - Swiper
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.swiper-wrapper {
  margin: 0;
  padding: 0; }

.swiper-slide {
  list-style: none;
  margin: 0;
  padding: 0; }
  .swiper-slide::before {
    display: none; }

.swiper-button-prev, .swiper-button-next {
  font-size: 2.5em;
  height: 44px;
  margin-top: -22px;
  width: auto; }
  .swiper-button-prev::before, .swiper-button-next::before {
    display: block;
    line-height: 44px;
    height: 44px; }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    color: #000; }

.swiper-button-prev::before {
  content: "\f104"; }

.swiper-button-next::before {
  content: "\f105"; }

.swiper- {
  /* Button 'zurück'. */
  /* Button 'weiter'. */ }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-prev {
    background-image: none;
    color: #92C5EB; }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-next {
    background-image: none;
    color: #92C5EB; }

.swiper-pagination-bullet {
  background: #fff;
  height: 12px;
  opacity: 1;
  overflow: hidden;
  width: 12px; }
  .swiper-pagination-bullet-active {
    background: #92C5EB; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #92C5EB; }

/**
 * Vendors - Fancybox
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Variablen.
 *
 * ========================================================================== */
/* =============================================================================
 *
 * Fancybox-Standard-Definitionen über eigenen Selector setzen.
 *
 * ========================================================================== */
.fb-custom .fancybox-bg {
  background-color: #2A89CF; }

.fb-custom .fancybox-placeholder {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2); }

.fb-custom .fancybox-slide > * {
  padding: 15px; }

.fb-custom .fancybox-slide > .fancybox-content {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  margin: 0;
  max-width: 600px;
  padding: 15px;
  width: 95%; }

.fb-custom .fancybox-slide--iframe > .fancybox-content {
  height: 88% !important;
  max-width: 800px;
  padding: 0; }

.fb-custom .fancybox-infobar__body, .fb-custom .fancybox-button {
  background: #2A89CF;
  color: #fff; }

.fb-custom .fancybox-button--left, .fb-custom .fancybox-button--right {
  border-radius: 0; }

.fb-custom .fancybox-button:hover {
  background: #8baac2;
  color: white; }

.fb-custom .fancybox-close-small:after {
  color: #2A89CF;
  content: "\f00d";
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  text-rendering: optimizeSpeed; }

.fb-custom .fancybox-close-small:hover:after {
  color: #92C5EB;
  background-color: transparent; }

.fb-custom .fancybox-caption-wrap {
  background: none;
  padding: 30px 0 0; }
  .fb-custom .fancybox-caption-wrap > .fancybox-caption {
    background-color: rgba(42, 137, 207, 0.4);
    border-top: 0 none;
    font-family: inherit;
    font-size: 1em;
    line-height: inherit;
    padding: 15px; }

.fb-custom.fancybox-is-open .fancybox-bg {
  opacity: .7; }

.fb-custom.fancybox-is-modal .fancybox-bg {
  background-color: #fff; }

.fb-custom.fancybox-is-modal .fancybox-slide > .fancybox-content {
  width: auto; }

.fb-custom.fancybox-is-modal .fancybox-modal-content {
  min-width: 250px; }

.fb-custom.fancybox-is-modal .fb-modal-title {
  margin: -15px -15px 0 -15px;
  background-color: #92C5EB;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-size: 1.125em; }

.fb-custom.fancybox-is-modal .fb-modal-message {
  font-size: 0.875rem; }

.fb-custom.fancybox-is-modal .fb-modal-controls {
  text-align: center; }
  .fb-custom.fancybox-is-modal .fb-modal-controls > :first-child {
    float: left; }
  .fb-custom.fancybox-is-modal .fb-modal-controls > :last-child {
    float: right; }
  .fb-custom.fancybox-is-modal .fb-modal-controls > :first-child:last-child {
    float: none; }

.fb-custom.fancybox-is-modal.modal-type-info .fb-modal-title {
  background-color: #46a6b1; }

.fb-custom.fancybox-is-modal.modal-type-warning .fb-modal-title {
  background-color: #f3bb5b; }

.fb-custom.fancybox-is-modal.-hide-btn-cancel .fb-modal-controls-cancel {
  display: none; }

.fb-custom.fancybox-is-modal.-hide-btn-confirm .fb-modal-controls-confirm {
  display: none; }

/* =============================================================================
 *
 * Mediaqueries.
 *
 * ========================================================================== */
/*
		 * Schluessel existiert in der Map
		 */
/*
		 * Schluessel existiert in der Map
		 */
/*
		 * Schluessel existiert in der Map
		 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (max-width: 640px) {
  .fb-custom.fancybox-is-modal .fancybox-slide > .fancybox-content {
    max-width: 95%; } }

/*
		 * Schluessel existiert in der Map
		 */
/**
 * Vendors - MMenu
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
#mm-blocker {
  background-color: #fff;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s; }
  html.mm-opening #mm-blocker {
    opacity: .7; }

.mm-listview {
  font-size: 1em; }

/**
 * Vendors - Bootstrap Datetimepicker
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.bootstrap-datetimepicker-widget {
  font-size: 1em;
  /* Reset zwecks vererbten Defintionen 'accordion-toggle'! */
  /* Datumsauswahl */
  /* Zeitauswahl. */ }
  .bootstrap-datetimepicker-widget.dropdown-menu {
    background-color: #fff;
    box-shadow: 0 0 9px -3px rgba(0, 0, 0, 0.2);
    color: #000;
    font-size: 1em;
    margin-bottom: 6px;
    margin-top: 6px;
    width: 300px;
    z-index: 10; }
    .bootstrap-datetimepicker-widget.dropdown-menu.top:after, .bootstrap-datetimepicker-widget.dropdown-menu.top:before, .bootstrap-datetimepicker-widget.dropdown-menu.bottom:after, .bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
      border-width: 6px; }
    .bootstrap-datetimepicker-widget.dropdown-menu.top:before, .bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
      display: none; }
    .bootstrap-datetimepicker-widget.dropdown-menu.top:after {
      border-top-color: #dedcdc;
      bottom: -9px;
      left: 3px; }
    .bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
      border-bottom-color: #dedcdc;
      left: 3px;
      top: -9px; }
  .bootstrap-datetimepicker-widget ul > li {
    margin: 0;
    padding: 0; }
    .bootstrap-datetimepicker-widget ul > li::before {
      display: none; }
  .bootstrap-datetimepicker-widget table > thead > tr > th,
  .bootstrap-datetimepicker-widget table > thead > tr > td,
  .bootstrap-datetimepicker-widget table > tbody > tr > th,
  .bootstrap-datetimepicker-widget table > tbody > tr > td {
    height: auto;
    line-height: 1;
    padding: 10px 1px;
    width: auto; }
  .bootstrap-datetimepicker-widget table > thead > tr:first-child > th,
  .bootstrap-datetimepicker-widget table > thead > tr:first-child > td {
    background-color: transparent;
    color: #000; }
    .bootstrap-datetimepicker-widget table > thead > tr:first-child > th > span,
    .bootstrap-datetimepicker-widget table > thead > tr:first-child > td > span {
      font-size: .8125em; }
    .bootstrap-datetimepicker-widget table > thead > tr:first-child > th:hover,
    .bootstrap-datetimepicker-widget table > thead > tr:first-child > td:hover {
      background-color: rgba(42, 137, 207, 0.2);
      color: #2A89CF; }
  .bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #2A89CF;
    color: #fff; }
  .bootstrap-datetimepicker-widget table td.active.today:before {
    border-color: #fff; }
  .bootstrap-datetimepicker-widget table td.day {
    cursor: pointer;
    height: auto;
    line-height: 1;
    width: 30px; }
    .bootstrap-datetimepicker-widget table td.day:hover {
      background-color: rgba(42, 137, 207, 0.2);
      color: #2A89CF;
      text-shadow: none; }
  .bootstrap-datetimepicker-widget table td.old, .bootstrap-datetimepicker-widget table td.new {
    background-color: transparent;
    color: #cccccc; }
  .bootstrap-datetimepicker-widget table td.today {
    background-clip: padding-box;
    position: relative; }
    .bootstrap-datetimepicker-widget table td.today:before {
      border-color: transparent transparent #2A89CF #2A89CF;
      border-style: solid;
      border-width: 0 0 6px 6px;
      bottom: 4px;
      right: 4px; }
    .bootstrap-datetimepicker-widget table td.today:hover {
      background-clip: padding-box; }
      .bootstrap-datetimepicker-widget table td.today:hover:before {
        border-color: #2A89CF; }
  .bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
    background-color: transparent;
    color: #cccccc; }
  .bootstrap-datetimepicker-widget table td span {
    background-color: transparent;
    color: #000; }
    .bootstrap-datetimepicker-widget table td span:hover {
      background-color: rgba(42, 137, 207, 0.2);
      color: #2A89CF; }
    .bootstrap-datetimepicker-widget table td span.active, .bootstrap-datetimepicker-widget table td span.active:hover {
      background-color: #2A89CF;
      color: #fff;
      text-shadow: none; }
    .bootstrap-datetimepicker-widget table td span.old {
      background-color: transparent;
      color: #cccccc; }
    .bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
      background-color: transparent;
      color: #cccccc; }
  .bootstrap-datetimepicker-widget .btn {
    color: #2A89CF; }
    .bootstrap-datetimepicker-widget .btn > span {
      display: block;
      width: 100%;
      height: auto;
      line-height: 40px;
      margin: 0;
      border-radius: 0; }
    .bootstrap-datetimepicker-widget .btn:hover, .bootstrap-datetimepicker-widget .btn:active, .bootstrap-datetimepicker-widget .btn:focus {
      background-color: transparent;
      color: #2A89CF; }
  .bootstrap-datetimepicker-widget a[data-action] {
    color: #2A89CF;
    display: block;
    height: auto;
    padding: 0; }
    .bootstrap-datetimepicker-widget a[data-action]:hover, .bootstrap-datetimepicker-widget a[data-action]:active, .bootstrap-datetimepicker-widget a[data-action]:focus {
      background: transparent; }
  .bootstrap-datetimepicker-widget a[data-action="togglePicker"] {
    color: #92C5EB; }
    .bootstrap-datetimepicker-widget a[data-action="togglePicker"]:hover, .bootstrap-datetimepicker-widget a[data-action="togglePicker"]:active, .bootstrap-datetimepicker-widget a[data-action="togglePicker"]:focus {
      color: #d2e5f4; }
  .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"] {
    color: #fff;
    background-color: #92C5EB;
    border-color: #92C5EB; }
    .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]:hover, .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]:active, .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]:focus {
      color: white;
      background-color: #d2e5f4;
      border-color: #d2e5f4; }
  .bootstrap-datetimepicker-widget .picker-switch {
    font-size: 1em;
    font-weight: 400; }
    .bootstrap-datetimepicker-widget .picker-switch table td span {
      line-height: 36px;
      height: 36px; }
      .bootstrap-datetimepicker-widget .picker-switch table td span:hover {
        background: transparent; }
    .bootstrap-datetimepicker-widget .picker-switch::after, .bootstrap-datetimepicker-widget .picker-switch::before {
      display: none; }
    .bootstrap-datetimepicker-widget .picker-switch:hover, .bootstrap-datetimepicker-widget .picker-switch:active, .bootstrap-datetimepicker-widget .picker-switch:focus {
      background-color: transparent;
      color: inherit; }
  .bootstrap-datetimepicker-widget .datepicker {
    border-bottom: 1px solid #f4f4f4;
    /**
		 * Auswahl Monate, Jahre, Dekaden etc..
		 */
    /* Monatsauswahl */
    /* Jahresauswahl */
    /* Dekadenauswahl */ }
    .bootstrap-datetimepicker-widget .datepicker .dow {
      border-bottom: 1px solid #f4f4f4;
      color: #807f7f;
      font-size: .875em;
      font-weight: 300; }
    .bootstrap-datetimepicker-widget .datepicker-months th.prev, .bootstrap-datetimepicker-widget .datepicker-months th.next, .bootstrap-datetimepicker-widget .datepicker-years th.prev, .bootstrap-datetimepicker-widget .datepicker-years th.next, .bootstrap-datetimepicker-widget .datepicker-decades th.prev, .bootstrap-datetimepicker-widget .datepicker-decades th.next {
      min-width: 40px; }
    .bootstrap-datetimepicker-widget .datepicker-months td > span, .bootstrap-datetimepicker-widget .datepicker-years td > span, .bootstrap-datetimepicker-widget .datepicker-decades td > span {
      height: 36px;
      line-height: 36px;
      margin: 1px;
      min-width: 40px;
      width: 24%; }
    .bootstrap-datetimepicker-widget .datepicker-decades td {
      text-align: left; }
      .bootstrap-datetimepicker-widget .datepicker-decades td > span {
        display: none;
        text-align: center; }
        .bootstrap-datetimepicker-widget .datepicker-decades td > span.decade {
          display: inline-block;
          height: 36px;
          line-height: 36px !important;
          min-width: 60px;
          width: 49%; }
  .bootstrap-datetimepicker-widget .timepicker {
    border-top: 1px solid #f4f4f4; }
    .bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second {
      border-radius: 0;
      font-size: 1.25em;
      font-weight: 400;
      height: 56px;
      line-height: 56px;
      margin: 0;
      width: 100%; }
    .bootstrap-datetimepicker-widget .timepicker table td {
      padding: 0; }
    .bootstrap-datetimepicker-widget .timepicker .separator {
      line-height: 56px; }

/**
 * Vendors - Bootstrap Select
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
form .bootstrap-select .dropdown-toggle {
  background-color: #dedcdc;
  border: 3px solid #dedcdc;
  box-shadow: none;
  color: #000;
  font-weight: 300;
  height: 56px; }
  form .bootstrap-select .dropdown-toggle:hover {
    color: #000;
    background-color: #dedcdc;
    border-color: #2A89CF; }
  form .bootstrap-select .dropdown-toggle:focus {
    color: #000;
    background-color: #dedcdc;
    border-color: #2A89CF;
    box-shadow: 0 0 6px rgba(222, 220, 220, 0.2); }
  form .bootstrap-select .dropdown-toggle.bs-placeholder {
    color: #807f7f;
    font-style: italic; }

form .bootstrap-select.btn-group .dropdown-toggle .caret {
  margin-top: -10px;
  right: 10px; }

form .bootstrap-select.btn-group .dropdown-menu {
  min-width: min-content; }
  form .bootstrap-select.btn-group .dropdown-menu.inner {
    overflow-x: hidden; }

form .bootstrap-select.btn-group.show-tick .dropdown-menu li a .text {
  display: block; }

form .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  margin-top: -15px;
  right: 10px; }

form .bootstrap-select .bs-searchbox {
  padding: 0 0; }
  form .bootstrap-select .bs-searchbox .form-control {
    background-color: #fff;
    border-color: #dedcdc;
    border-left-width: 0;
    border-radius: 0;
    border-right-width: 0;
    border-top-width: 0;
    height: auto;
    line-height: normal; }

form .bootstrap-select.open > .btn.dropdown-toggle {
  color: #000;
  background-color: #dedcdc;
  border-color: #2A89CF;
  box-shadow: 0 0 6px rgba(222, 220, 220, 0.2); }

/*!
 * Komponenten.
 */
/**
 * Komponentenimport
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Links (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =========================================================================
 *
 * Standardlink
 *
 * ====================================================================== */
a {
  color: #2A89CF;
  font-weight: 700;
  position: relative;
  text-decoration: none; }
  a > .file-info {
    color: #807f7f !important;
    font-size: 0.875rem;
    font-weight: 400;
    opacity: .9;
    padding-left: 5px; }
  a:hover, a:active, a:focus {
    color: #50a1dc;
    text-decoration: underline; }
  a[href^="tel"], a[href^="fax"] {
    color: #000;
    cursor: default;
    font-weight: 300;
    tab-index: 0;
    text-decoration: none; }
    a[href^="tel"]:hover, a[href^="fax"]:hover {
      color: #000;
      text-decoration: none; }

a[class*="link-"],
span[class*="link-"] {
  display: inline-block;
  padding-left: 4px; }
  a[class*="link-"]::before,
  span[class*="link-"]::before {
    margin-right: 6px;
    top: 0;
    position: relative; }

a.link-intern::before, a.link-page::before,
span.link-intern::before,
span.link-page::before {
  content: "\f061"; }

a.link-read-more::before,
span.link-read-more::before {
  content: "\f138"; }

a.link-anchor::before,
span.link-anchor::before {
  content: "\f13d"; }

a.link-back::before,
span.link-back::before {
  content: "\f177"; }

a.link-bars::before,
span.link-bars::before {
  content: "\f0c9"; }

a.link-edit::before,
span.link-edit::before {
  content: "\f040"; }

a.link-down::before,
span.link-down::before {
  content: "\f063"; }

a.link-email::before,
span.link-email::before {
  content: "\f003"; }

a.link-fax::before,
span.link-fax::before {
  content: "\f1ac"; }

a.link-phone::before,
span.link-phone::before {
  content: "\f095"; }

a.link-prev-circle::before,
span.link-prev-circle::before {
  content: "\f137"; }

a.link-next-circle::before,
span.link-next-circle::before {
  content: "\f138"; }

a.link-arrow-left::before,
span.link-arrow-left::before {
  content: "\f060"; }

a.link-arrow-right::before,
span.link-arrow-right::before {
  content: "\f061"; }

a.link-view::before,
span.link-view::before {
  content: "\f06e"; }

a.link-file::before, a.link-media::before,
span.link-file::before,
span.link-media::before {
  content: "\f019"; }

a.link-file-circle::before, a.link-media-circle::before,
span.link-file-circle::before,
span.link-media-circle::before {
  content: "\f01a"; }

a.link-googlemap::before,
span.link-googlemap::before {
  content: "\f041"; }

a.link-pageflip::before,
span.link-pageflip::before {
  content: "\f02d"; }

a.link-popup::before,
span.link-popup::before {
  content: "\f0aa"; }

a.link-print::before,
span.link-print::before {
  content: "\f02f"; }

a.link-star::before,
span.link-star::before {
  content: "\f005"; }

a.link-star-empty::before,
span.link-star-empty::before {
  content: "\f006"; }

a.link-trash::before,
span.link-trash::before {
  content: "\f1f8"; }

a.link-up::before,
span.link-up::before {
  content: "\f062"; }

a.link-web::before,
span.link-web::before {
  content: "\f08e"; }

a.link-globe::before,
span.link-globe::before {
  content: "\f0ac"; }

a.link-loading::before,
span.link-loading::before {
  content: "\f110"; }

a.link-pdf::before,
span.link-pdf::before {
  content: "\f1c1"; }

a.link-spin::before,
span.link-spin::before {
  animation: link-spin 2s infinite linear; }

/*
a.link,
span.link {
	display      : inline-block;
	padding-left : 4px;

	&::before {
		@extend .fa-icon-helper;

		content      : "\f061";
		//font-size    : 90%;
		margin-right : ($margin-base-horizontal-small - 4);
		top          : 0;
		position     : relative;
	}

	&-anchor::before { content : "\f13d"; }

	&-back::before { content : "\f177"; }

	&-bars::before { content : "\f0c9"; }

	&-edit::before { content : "\f040"; }

	&-down::before { content : "\f063"; }

	&-email::before { content : "\f003"; }

	&-fax::before { content : "\f1ac"; }

	&-phone::before { content : "\f095"; }

	&-prev-circle::before { content : "\f137"; }

	&-next-circle::before { content : "\f138"; }

	&-view::before { content : "\f06e"; }

	&-file,
	&-media {
		&::before { content : "\f019"; }

		&-circle::before { content : "\f01a"; }
	}

	//&-gallery::before { content : "\f03e"; }

	&-googlemap::before { content : "\f041"; }

	//&-intern::before { content : "\f061"; }

	&-pageflip::before { content : "\f02d"; }

	&-popup::before { content : "\f0aa"; }

	&-print::before { content : "\f02f"; }

	&-star::before { content : "\f005"; }

	&-star-empty::before { content : "\f006"; }

	&-up::before { content : "\f062"; }

	&-web::before { content : "\f08e"; }

	&-loading::before { content : "\f110"; }

	&-spin::before { animation : link-spin 2s infinite linear; }
}
*/
/* =============================================================================
 *
 * Inaktiver Link
 *
 * ========================================================================== */
a.crossed-out,
span.crossed-out,
a.crossed-out:hover,
span.crossed-out:hover {
  color: #dedcdc !important;
  cursor: default !important;
  text-decoration: line-through !important; }

/**
 * Formulare (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
input,
legend {
  font-family: "Roboto", Helvetica, Arial, sans-serif; }

legend,
fieldset .legend {
  background-color: transparent;
  color: #2A89CF;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 20px 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%; }
  legend:first-child,
  fieldset .legend:first-child {
    margin-top: 0; }
  legend:last-child,
  fieldset .legend:last-child {
    margin-bottom: 0; }

fieldset {
  background-color: transparent;
  margin: 20px 0;
  padding: 0;
  position: relative;
  /*	> :first-child { margin-top : 0; }

	> :last-child { margin-bottom : 0; }*/ }
  .fieldset-row {
    margin: 20px 0; }
    .fieldset-row fieldset {
      margin-bottom: 0; }
    .fieldset-row > :nth-child(1) fieldset,
    .fieldset-row > :nth-child(2) fieldset {
      margin-top: 0; }

form {
  margin: 0 0; }
  form > :first-child,
  form > div:first-child + fieldset:first-of-type {
    margin-top: 0; }
  form > :last-child {
    margin-bottom: 0; }
  form .form-delim {
    font-weight: 400;
    clear: both;
    color: #92C5EB;
    display: block;
    line-height: 1;
    margin: 30px 0;
    position: relative;
    text-align: center; }
    form .form-delim > span {
      background-color: #fff;
      padding: 0 5px;
      z-index: 2; }
    form .form-delim::after {
      border-top: 2px solid #f4f4f4;
      content: " ";
      display: block;
      left: 0;
      margin-top: "-1px";
      position: absolute;
      right: 0;
      top: 50%;
      z-index: -1; }

label,
.checkbox label,
.radio label {
  color: #000;
  font-weight: 300;
  font-style: normal; }

input,
textarea,
button,
.btn {
  outline-style: none;
  outline-width: 0; }

/*input,
input[type="search"],
input[type="file"],
input.input-fileupload,
select,
textarea { -webkit-appearance : none; }*/
/*
 * Buttons bei Nummernfeldern entfernen.
 */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.help-block {
  color: #b3b3b3;
  font-size: 0.8125rem;
  font-weight: 300; }
  .help-block.state {
    display: none; }

.checkbox > .help-block,
.radio > .help-block {
  padding-left: 20px;
  margin-bottom: 0; }

.checkbox-styled > .help-block,
.radio-styled > .help-block {
  padding-left: 15px; }

/* -----------------------------------------------------------------------------
 * Form control (Bootstrap)
 * -------------------------------------------------------------------------- */
.form-control {
  background-color: #dedcdc;
  background-clip: padding-box;
  border: 3px solid #dedcdc;
  border-radius: 3px;
  box-shadow: none;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  height: 56px;
  line-height: 1.5;
  padding: 10px 15px; }
  .form-control:hover {
    background-color: #dedcdc;
    border-color: #2A89CF;
    color: #000;
    box-shadow: none; }
  .form-control:focus {
    background-color: #dedcdc;
    border-color: #2A89CF;
    color: #000;
    box-shadow: 0 0 6px rgba(42, 137, 207, 0.2); }
  .form-control.is-danger {
    background-color: #dedcdc;
    border-color: #ea4b77;
    color: #ea4b77; }
    .form-control.is-danger:hover, .form-control.is-danger:focus {
      border-color: #e51d54;
      color: #e51d54; }
    .form-control.is-danger:focus {
      outline: none;
      box-shadow: 0 0 8px #f5a6bc; }
  .form-control.is-info {
    background-color: #dedcdc;
    border-color: #46a6b1;
    color: #46a6b1; }
    .form-control.is-info:hover, .form-control.is-info:focus {
      border-color: #38848c;
      color: #38848c; }
    .form-control.is-info:focus {
      outline: none;
      box-shadow: 0 0 8px #8ccad1; }
  .form-control.is-invalid {
    background-color: #dedcdc;
    border-color: #ea4b77;
    color: #ea4b77; }
    .form-control.is-invalid:hover, .form-control.is-invalid:focus {
      border-color: #e51d54;
      color: #e51d54; }
    .form-control.is-invalid:focus {
      outline: none;
      box-shadow: 0 0 8px #f5a6bc; }
  .form-control.is-success {
    background-color: #dedcdc;
    border-color: #2A89CF;
    color: #2A89CF; }
    .form-control.is-success:hover, .form-control.is-success:focus {
      border-color: #216da5;
      color: #216da5; }
    .form-control.is-success:focus {
      outline: none;
      box-shadow: 0 0 8px #7bb7e4; }
  .form-control.is-valid {
    background-color: #dedcdc;
    border-color: #2A89CF;
    color: #2A89CF; }
    .form-control.is-valid:hover, .form-control.is-valid:focus {
      border-color: #216da5;
      color: #216da5; }
    .form-control.is-valid:focus {
      outline: none;
      box-shadow: 0 0 8px #7bb7e4; }
  .form-control.is-warning {
    background-color: #dedcdc;
    border-color: #f3bb5b;
    color: #f3bb5b; }
    .form-control.is-warning:hover, .form-control.is-warning:focus {
      border-color: #f0a72b;
      color: #f0a72b; }
    .form-control.is-warning:focus {
      outline: none;
      box-shadow: 0 0 8px #fae2ba; }
  .form-control-reset {
    border: 0 none;
    border-radius: 0;
    font-size: inherit;
    height: auto;
    line-height: normal;
    padding: 0; }
  .form-control-static {
    background-color: #f8f8f8;
    border: 3px solid #f8f8f8;
    clear: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    margin-top: 0;
    padding: 15px; }
  .form-control-hint {
    display: none;
    font-size: 0.875rem; }

.form-control-feedback {
  height: 30px;
  line-height: 30px;
  top: 13px;
  width: 30px; }
  .form-control-feedback.state {
    display: none; }

.form-control[readonly],
fieldset[readonly] .form-control {
  background-color: #cfcfcf;
  border-color: #bfbcbc;
  color: #807f7f;
  opacity: 1; }

.form-control[disabled],
fieldset[disabled] .form-control {
  background-color: #cfcfcf;
  border-color: #bfbcbc;
  color: #807f7f;
  opacity: 0.3; }

/* -----------------------------------------------------------------------------
 * Eingabefelder
 * -------------------------------------------------------------------------- */
textarea {
  min-height: 120px;
  overflow: auto;
  resize: vertical; }

/* Set the height of file controls to match text inputs. */
input[type="file"],
input[type="file"].form-control {
  display: block;
  font-size: .875em;
  height: auto;
  line-height: initial;
  padding: 0; }

/* Make range inputs behave like textual form controls. */
input[type="range"] {
  display: block;
  width: 100%; }

/* -----------------------------------------------------------------------------
 * Auswahlfelder
 * -------------------------------------------------------------------------- */
.input-group-btn {
  font-size: inherit; }

/*
 * Checkboxen, Radio
 * Better position for radios and checkboxes.
 */
input.radio, input[type="radio"], input.checkbox, input[type="checkbox"] {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
  cursor: pointer;
  height: auto;
  line-height: normal;
  padding: 0;
  width: auto; }

input.checkbox, input[type="checkbox"] {
  -webkit-appearance: checkbox; }

input.radio, input[type="radio"] {
  -webkit-appearance: radio; }

/*
 * Select
 */
select {
  cursor: pointer; }
  select[multiple], select[size] {
    height: auto; }
  select > option[disabled] {
    color: #807f7f; }
  select[data-rewrite][multiple] {
    height: 56px; }

/* -----------------------------------------------------------------------------
 * Status
 * -------------------------------------------------------------------------- */
.has-danger .control-label,
.has-danger .radio,
.has-danger .checkbox,
.has-danger .radio-inline,
.has-danger .checkbox-inline,
.has-danger.radio label,
.has-danger.checkbox label,
.has-danger.radio-inline label,
.has-danger.checkbox-inline label {
  color: #ea4b77; }

.has-danger .form-control {
  border-color: #ea4b77;
  box-shadow: none; }
  .has-danger .form-control:focus {
    box-shadow: none; }

.has-danger .input-group-addon {
  color: #ea4b77;
  border-color: #ea4b77;
  background-color: #dedcdc; }

.has-danger .form-control-feedback,
.has-danger .help-block {
  color: #ea4b77; }
  .has-danger .form-control-feedback.state,
  .has-danger .help-block.state {
    display: block; }

.has-info .control-label,
.has-info .radio,
.has-info .checkbox,
.has-info .radio-inline,
.has-info .checkbox-inline,
.has-info.radio label,
.has-info.checkbox label,
.has-info.radio-inline label,
.has-info.checkbox-inline label {
  color: #46a6b1; }

.has-info .form-control {
  border-color: #46a6b1;
  box-shadow: none; }
  .has-info .form-control:focus {
    box-shadow: none; }

.has-info .input-group-addon {
  color: #46a6b1;
  border-color: #46a6b1;
  background-color: #dedcdc; }

.has-info .form-control-feedback,
.has-info .help-block {
  color: #46a6b1; }
  .has-info .form-control-feedback.state,
  .has-info .help-block.state {
    display: block; }

.has-invalid .control-label,
.has-invalid .radio,
.has-invalid .checkbox,
.has-invalid .radio-inline,
.has-invalid .checkbox-inline,
.has-invalid.radio label,
.has-invalid.checkbox label,
.has-invalid.radio-inline label,
.has-invalid.checkbox-inline label {
  color: #ea4b77; }

.has-invalid .form-control {
  border-color: #ea4b77;
  box-shadow: none; }
  .has-invalid .form-control:focus {
    box-shadow: none; }

.has-invalid .input-group-addon {
  color: #ea4b77;
  border-color: #ea4b77;
  background-color: #dedcdc; }

.has-invalid .form-control-feedback,
.has-invalid .help-block {
  color: #ea4b77; }
  .has-invalid .form-control-feedback.state,
  .has-invalid .help-block.state {
    display: block; }

.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #2A89CF; }

.has-success .form-control {
  border-color: #2A89CF;
  box-shadow: none; }
  .has-success .form-control:focus {
    box-shadow: none; }

.has-success .input-group-addon {
  color: #2A89CF;
  border-color: #2A89CF;
  background-color: #dedcdc; }

.has-success .form-control-feedback,
.has-success .help-block {
  color: #2A89CF; }
  .has-success .form-control-feedback.state,
  .has-success .help-block.state {
    display: block; }

.has-valid .control-label,
.has-valid .radio,
.has-valid .checkbox,
.has-valid .radio-inline,
.has-valid .checkbox-inline,
.has-valid.radio label,
.has-valid.checkbox label,
.has-valid.radio-inline label,
.has-valid.checkbox-inline label {
  color: #2A89CF; }

.has-valid .form-control {
  border-color: #2A89CF;
  box-shadow: none; }
  .has-valid .form-control:focus {
    box-shadow: none; }

.has-valid .input-group-addon {
  color: #2A89CF;
  border-color: #2A89CF;
  background-color: #dedcdc; }

.has-valid .form-control-feedback,
.has-valid .help-block {
  color: #2A89CF; }
  .has-valid .form-control-feedback.state,
  .has-valid .help-block.state {
    display: block; }

.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #f3bb5b; }

.has-warning .form-control {
  border-color: #f3bb5b;
  box-shadow: none; }
  .has-warning .form-control:focus {
    box-shadow: none; }

.has-warning .input-group-addon {
  color: #f3bb5b;
  border-color: #f3bb5b;
  background-color: #dedcdc; }

.has-warning .form-control-feedback,
.has-warning .help-block {
  color: #f3bb5b; }
  .has-warning .form-control-feedback.state,
  .has-warning .help-block.state {
    display: block; }

/* -----------------------------------------------------------------------------
 * Input group
 * -------------------------------------------------------------------------- */
.input-group-addon {
  background-color: #f4f4f4;
  border: 3px solid #f4f4f4;
  border-radius: 3px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 15px; }
  .input-group-addon.input-sm {
    border-radius: 1px;
    font-size: 14px;
    padding: 5px 10px; }
  .input-group-addon.input-lg {
    border-radius: 6px;
    font-size: 18px;
    padding: 15px 20px; }

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:first-child {
  border-right: 0; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-addon:last-child {
  border-left: 0; }

/* -----------------------------------------------------------------------------
 * Platzhalter
 * -----------------------------------------------------------------------------
 * Selektoren muessen einzeln aufgefuehrt werden. Versteht ein Browser eine
 * Regel nicht, wuerde er automatisch auch alle anderen Regeln ignorieren.
 * -------------------------------------------------------------------------- */
/*input[placeholder] {
	color   : #979696;
	opacity : 1;
}

input[placeholder]:focus { color : #000; }*/
*::-webkit-input-placeholder {
  color: #807f7f !important;
  font-style: italic !important;
  opacity: 1; }

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #807f7f; }

*::-moz-placeholder {
  color: #807f7f !important;
  font-style: italic !important;
  opacity: 1; }

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: #807f7f !important; }

:-moz-placeholder {
  color: #807f7f !important;
  font-style: italic !important;
  opacity: 1; }

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: #807f7f !important; }

:-ms-input-placeholder {
  color: #807f7f !important;
  font-style: italic !important;
  opacity: 1; }

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: #807f7f !important; }

/* =============================================================================
 *
 * Abweichende Formulardefintionen
 *
 * -----------------------------------------------------------------------------
 * Defintionen von abweichenden Komponenteneigenschaften.
 * ========================================================================== */
.form:not(.form-horizontal) .control-label {
  display: block; }

.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 18px; }

.form-horizontal .form-group-lg .control-label {
  padding-top: 33px; }

.form-horizontal .form-group-sm .control-label {
  padding-top: 13px; }

.form-required-sign {
  color: #ea4b77;
  font-weight: 400; }

label > .form-required-sign {
  padding-left: 5px; }

/*.form-group.has-rangeslider {
	position: relative;

	> label {
		left       : 15px;
		position   : absolute;
		right      : 15px;
		text-align : center;
		top        : 0;
	}
}*/
.form-control-datepicker {
  position: relative; }
  .form-control-datepicker > * {
    z-index: 1; }
  .form-control-datepicker::after {
    content: "\f133";
    font-size: 1em;
    height: 30px;
    right: 15px;
    line-height: 30px;
    margin-top: -15px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 30px;
    z-index: 2; }
  .form-control-datepicker.only-time::after {
    content: "\f017"; }
  .form-control-datepicker .form-control {
    padding-right: 52.5px; }
  .form-control-datepicker[disabled],
  fieldset[disabled] .form-control-datepicker {
    cursor: not-allowed; }
    .form-control-datepicker[disabled]::after,
    fieldset[disabled] .form-control-datepicker::after {
      color: #807f7f;
      opacity: .3; }

.form .captcha img {
  height: auto;
  max-height: 56px;
  max-width: 100%;
  width: auto; }

.form-submit-ctrl {
  margin-top: 30px;
  text-align: right; }
  .form-submit-ctrl > * {
    float: left;
    margin: 0 10px; }
    .form-submit-ctrl > *:first-child {
      margin-left: 0; }
    .form-submit-ctrl > *:last-child {
      float: right;
      margin-right: 0; }
    .form-submit-ctrl > *:first-child:last-child {
      float: none;
      margin-left: 0;
      margin-right: 0; }

/**
 * Styled Checkboxes/Radiobuttons (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2014 flatlogic.com
 * @license MIT
 * @author flatlogic.com
 * @author Heiko Pfefferkorn (https://i-fabrik.de, Modifications 20160629)
 */
/* =============================================================================
 *
 * Checkboxen
 *
 * ========================================================================== */
.checkbox-styled {
  padding-left: 24px; }
  .checkbox-styled > label {
    cursor: pointer;
    display: inline-block;
    padding-left: 15px;
    position: relative;
    vertical-align: middle; }
    .checkbox-styled > label::before {
      background-color: #dedcdc;
      border: 3px solid #dedcdc;
      border-radius: 3px;
      content: "";
      display: inline-block;
      height: 24px;
      left: 0;
      margin-left: -24px;
      margin-top: -12px;
      position: absolute;
      top: 50%;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      width: 24px; }
    .checkbox-styled > label::after {
      color: #2A89CF;
      display: inline-block;
      font-size: 16px;
      height: 24px;
      left: 0;
      line-height: 24px;
      margin-left: -24px;
      margin-top: -12px;
      overflow: hidden;
      padding-left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 24px; }
  .checkbox-styled input[type="checkbox"],
  .checkbox-styled input[type="radio"] {
    opacity: 0;
    z-index: 1; }
    .checkbox-styled input[type="checkbox"]:focus + label::before,
    .checkbox-styled input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .checkbox-styled input[type="checkbox"]:checked + label::after,
    .checkbox-styled input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: ""; }
    .checkbox-styled input[type="checkbox"]:indeterminate + label::after,
    .checkbox-styled input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .checkbox-styled input[type="checkbox"]:disabled + label,
    .checkbox-styled input[type="radio"]:disabled + label {
      opacity: .65; }
      .checkbox-styled input[type="checkbox"]:disabled + label::before,
      .checkbox-styled input[type="radio"]:disabled + label::before {
        background-color: #cfcfcf;
        cursor: not-allowed; }
  .checkbox-styled.checkbox-circle label::before {
    border-radius: 50%; }
  .checkbox-styled.checkbox-inline {
    margin-top: 0; }
  .checkbox-styled.is-primary input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-primary input[type="radio"]:checked + label::before {
    background-color: #92C5EB; }
  .checkbox-styled.is-primary input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-primary input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-secondary input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-secondary input[type="radio"]:checked + label::before {
    background-color: #2A89CF; }
  .checkbox-styled.is-secondary input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-secondary input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-tertimary input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-tertimary input[type="radio"]:checked + label::before {
    background-color: #2A89CF; }
  .checkbox-styled.is-tertimary input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-tertimary input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-danger input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-danger input[type="radio"]:checked + label::before {
    background-color: #ea4b77; }
  .checkbox-styled.is-danger input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-danger input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-invalid input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-invalid input[type="radio"]:checked + label::before {
    background-color: #ea4b77; }
  .checkbox-styled.is-invalid input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-invalid input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-info input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-info input[type="radio"]:checked + label::before {
    background-color: #46a6b1; }
  .checkbox-styled.is-info input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-info input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-warning input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-warning input[type="radio"]:checked + label::before {
    background-color: #f3bb5b; }
  .checkbox-styled.is-warning input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-warning input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-success input[type="checkbox"]:checked + label::before,
  .checkbox-styled.is-success input[type="radio"]:checked + label::before {
    background-color: #2A89CF; }
  .checkbox-styled.is-success input[type="checkbox"]:checked + label::after,
  .checkbox-styled.is-success input[type="radio"]:checked + label::after {
    color: #fff; }
  .checkbox-styled.is-primary input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-primary input[type="radio"]:indeterminate + label::before {
    background-color: #92C5EB; }
  .checkbox-styled.is-primary input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-primary input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-secondary input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-secondary input[type="radio"]:indeterminate + label::before {
    background-color: #2A89CF; }
  .checkbox-styled.is-secondary input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-secondary input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-tertimary input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-tertimary input[type="radio"]:indeterminate + label::before {
    background-color: #2A89CF; }
  .checkbox-styled.is-tertimary input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-tertimary input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-danger input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-danger input[type="radio"]:indeterminate + label::before {
    background-color: #ea4b77; }
  .checkbox-styled.is-danger input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-danger input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-invalid input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-invalid input[type="radio"]:indeterminate + label::before {
    background-color: #ea4b77; }
  .checkbox-styled.is-invalid input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-invalid input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-info input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-info input[type="radio"]:indeterminate + label::before {
    background-color: #46a6b1; }
  .checkbox-styled.is-info input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-info input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-warning input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-warning input[type="radio"]:indeterminate + label::before {
    background-color: #f3bb5b; }
  .checkbox-styled.is-warning input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-warning input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }
  .checkbox-styled.is-success input[type="checkbox"]:indeterminate + label::before,
  .checkbox-styled.is-success input[type="radio"]:indeterminate + label::before {
    background-color: #2A89CF; }
  .checkbox-styled.is-success input[type="checkbox"]:indeterminate + label::after,
  .checkbox-styled.is-success input[type="radio"]:indeterminate + label::after {
    background-color: #fff; }

/* =============================================================================
 *
 * Radiobuttons
 *
 * ========================================================================== */
.radio-styled {
  padding-left: 24px; }
  .radio-styled label {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 15px; }
    .radio-styled label::before {
      background-color: #dedcdc;
      border: 3px solid #dedcdc;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 24px;
      left: 0;
      margin-left: -24px;
      margin-top: -12px;
      position: absolute;
      top: 50%;
      transition: border 0.15s ease-in-out;
      width: 24px; }
    .radio-styled label::after {
      transform: scale(0, 0);
      background-color: #2A89CF;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 10px;
      left: 7px;
      margin-left: -24px;
      margin-top: -5px;
      position: absolute;
      top: 50%;
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      width: 10px; }
  .radio-styled input[type="radio"] {
    opacity: 0;
    z-index: 1; }
    .radio-styled input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .radio-styled input[type="radio"]:checked + label::after {
      transform: scale(1, 1); }
    .radio-styled input[type="radio"]:disabled + label {
      opacity: .65; }
      .radio-styled input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
  .radio-styled.radio-inline {
    margin-top: 0; }
  .radio-styled.is-primary input[type="radio"] + label::after {
    background-color: #92C5EB; }
  .radio-styled.is-primary input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-primary input[type="radio"]:checked + label::after {
    background-color: #92C5EB; }
  .radio-styled.is-secondary input[type="radio"] + label::after {
    background-color: #2A89CF; }
  .radio-styled.is-secondary input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-secondary input[type="radio"]:checked + label::after {
    background-color: #2A89CF; }
  .radio-styled.is-tertimary input[type="radio"] + label::after {
    background-color: #2A89CF; }
  .radio-styled.is-tertimary input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-tertimary input[type="radio"]:checked + label::after {
    background-color: #2A89CF; }
  .radio-styled.is-danger input[type="radio"] + label::after {
    background-color: #ea4b77; }
  .radio-styled.is-danger input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-danger input[type="radio"]:checked + label::after {
    background-color: #ea4b77; }
  .radio-styled.is-invalid input[type="radio"] + label::after {
    background-color: #ea4b77; }
  .radio-styled.is-invalid input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-invalid input[type="radio"]:checked + label::after {
    background-color: #ea4b77; }
  .radio-styled.is-info input[type="radio"] + label::after {
    background-color: #46a6b1; }
  .radio-styled.is-info input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-info input[type="radio"]:checked + label::after {
    background-color: #46a6b1; }
  .radio-styled.is-warning input[type="radio"] + label::after {
    background-color: #f3bb5b; }
  .radio-styled.is-warning input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-warning input[type="radio"]:checked + label::after {
    background-color: #f3bb5b; }
  .radio-styled.is-success input[type="radio"] + label::after {
    background-color: #2A89CF; }
  .radio-styled.is-success input[type="radio"]:checked + label::before {
    border-color: #dedcdc; }
  .radio-styled.is-success input[type="radio"]:checked + label::after {
    background-color: #2A89CF; }

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: "FontAwesome";
  content: ""; }

input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff; }

input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff; }

/* =============================================================================
 *
 * Global
 *
 * ========================================================================== */
.checkbox-styled.checkbox-inline + .checkbox-styled.checkbox-inline,
.radio-styled.radio-inline + .checkbox-styled.checkbox-inline, .checkbox-styled.checkbox-inline +
.radio-styled.radio-inline,
.radio-styled.radio-inline +
.radio-styled.radio-inline {
  margin-top: 0;
  margin-left: 15px; }

/**
 * Bilder (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
img {
  border: 0;
  display: inline-block;
  height: auto;
  overflow: hidden;
  width: 100%; }

.thumbnail {
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  display: inline-block;
  margin: 15px 0;
  padding: 0;
  position: relative;
  width: 100%;
  /*
	 * Ausrichtung
	 */
  /*
	 * Groessen
	 */ }
  .thumbnail > .thumbnail-link,
  .thumbnail .image {
    border-radius: 0;
    display: block;
    overflow: hidden; }
  .thumbnail .image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1; }
  .thumbnail .caption {
    background-color: transparent;
    color: #949494;
    display: block;
    font-style: italic;
    font-weight: 300;
    padding: 10px 0 0;
    text-align: left;
    z-index: 2; }
    .thumbnail .caption > .meta {
      display: block; }
      .thumbnail .caption > .meta.photograph {
        color: rgba(0, 0, 0, 0.4);
        font-size: 0.8125rem;
        font-weight: 400;
        text-align: right; }
        .thumbnail .caption > .meta.photograph > a {
          color: rgba(0, 0, 0, 0.4);
          font-weight: 400; }
  .thumbnail .zoom {
    background-color: rgba(42, 137, 207, 0.1);
    color: #fff;
    display: none;
    line-height: 36px;
    overflow: hidden;
    right: 0;
    text-align: center;
    text-indent: 150%;
    top: 0;
    white-space: nowrap;
    z-index: 5;
    font-size: 1.375rem; }
    .thumbnail .zoom, .thumbnail .zoom::before {
      display: block;
      height: 36px;
      line-height: 36px;
      position: absolute;
      width: 36px; }
    .thumbnail .zoom::before {
      content: "";
      left: 0;
      text-indent: 0;
      top: 0; }
    .thumbnail .zoom:hover {
      background-color: rgba(146, 197, 235, 0.6);
      color: #fff; }
  .thumbnail.left {
    float: left;
    margin-right: 30px; }
  .thumbnail.right {
    float: right;
    margin-left: 30px; }
  .thumbnail.size-thumbnail {
    width: 18%; }
  .thumbnail.size-medium {
    width: 45%; }
  .thumbnail.size-full {
    display: block;
    float: none;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
    .thumbnail.size-full .zoom {
      display: none !important; }
  .thumbnail.format-16by9 .image, .thumbnail.format-4by3 .image, .thumbnail.format-3by4 .image, .thumbnail.format-3by2 .image, .thumbnail.format-2by3 .image, .thumbnail.format-square .image {
    overflow: hidden;
    position: relative; }
    .thumbnail.format-16by9 .image > img, .thumbnail.format-4by3 .image > img, .thumbnail.format-3by4 .image > img, .thumbnail.format-3by2 .image > img, .thumbnail.format-2by3 .image > img, .thumbnail.format-square .image > img {
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
  .thumbnail.format-16by9 .image {
    padding-bottom: 56.25%; }
  .thumbnail.format-4by3 .image {
    padding-bottom: 75%; }
  .thumbnail.format-3by4 .image {
    padding-bottom: 133.33333%; }
  .thumbnail.format-3by2 .image {
    padding-bottom: 66.66667%; }
  .thumbnail.format-2by3 .image {
    padding-bottom: 150%; }
  .thumbnail.format-square .image {
    padding-bottom: 100%; }
  table .thumbnail {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }

/**
 * Indicator (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.indicator {
  position: absolute;
  height: 24px; }
  .indicator::after {
    animation: rotate 0.8s infinite linear;
    border-color: #2A89CF;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-style: solid;
    content: '';
    left: 50%;
    position: absolute;
    top: 50%; }
  .indicator::after, .indicator-lg::after {
    border-width: 4px;
    height: 24px;
    margin: -12px 0 0 -12px;
    width: 24px; }
  .indicator-md {
    height: 20px; }
    .indicator-md::after {
      border-width: 3px;
      height: 20px;
      margin: -10px 0 0 -10px;
      width: 20px; }
  .indicator-sm {
    height: 16px; }
    .indicator-sm::after {
      border-width: 3px;
      height: 16px;
      margin: -8px 0 0 -8px;
      width: 16px; }
  .indicator-xs {
    height: 12px; }
    .indicator-xs::after {
      border-width: 2px;
      height: 12px;
      margin: -6px 0 0 -6px;
      width: 12px; }

/**
 * Rating stars
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.rating-stars {
  background-color: transparent;
  color: #2A89CF;
  display: inline-block; }
  .rating-stars > * {
    background-color: transparent;
    color: #2A89CF;
    margin: 0 1px; }
    .rating-stars > *:first-child {
      margin-left: 0; }
    .rating-stars > *:last-child {
      margin-right: 0; }

/**
 * Buttons (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Standardbutton
 *
 * ========================================================================== */
.btn {
  background-color: transparent;
  border: 3px solid transparent;
  color: #2A89CF;
  font-weight: 700;
  height: 56px;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 56px;
  padding: 13px 15px; }
  .btn i + .btn-text {
    margin-left: 10px; }
  .btn.btn-flexible {
    height: auto;
    white-space: normal; }
  .btn.hover, .btn:hover, .btn.active, .btn:active, .btn.active.hover, .btn:active:hover, .btn.active.focus, .btn:active:focus, .btn:focus, .btn.focus {
    color: #50a1dc;
    text-decoration: none; }
  .btn:active, .btn.active {
    box-shadow: none; }
  .btn.disabled, .btn[disabled],
  fieldset[disabled] .btn {
    box-shadow: none;
    cursor: default;
    opacity: 0.25; }
  .btn.btn-circle {
    width: 56px; }
    .btn.btn-circle > i {
      font-size: 1.25em; }

/* =============================================================================
 *
 * Link buttons
 *
 * ========================================================================== */
.btn-link {
  color: #2A89CF;
  border-radius: 0;
  text-decoration: none; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
  fieldset[disabled] .btn-link {
    box-shadow: none;
    background-color: transparent; }
  .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover, .btn-link:focus {
    background-color: transparent;
    color: #50a1dc;
    text-decoration: underline; }
  .btn-link[disabled]:hover, .btn-link[disabled]:focus,
  fieldset[disabled] .btn-link:hover,
  fieldset[disabled] .btn-link:focus {
    color: #f4f4f4;
    text-decoration: none; }

/* =============================================================================
 *
 * Alternative Buttons
 *
 * ========================================================================== */
.btn-default {
  color: #000;
  background-color: #807f7f;
  border-color: #807f7f; }
  .btn-default.hover, .btn-default:hover, .btn-default.active, .btn-default:active, .btn-default.active.hover, .btn-default:active:hover, .btn-default.active.focus, .btn-default:active:focus, .btn-default.focus, .btn-default:focus,
  .open > .btn-default.dropdown-toggle {
    color: #000;
    background-color: #949393;
    border-color: #949393; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none; }
  .btn-default.disabled, .btn-default.disabled.hover, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled].hover, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, .btn-default[disabled]:active, .btn-default[disabled].active,
  fieldset[disabled] .btn-default,
  fieldset[disabled] .btn-default.hover,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default.focus,
  fieldset[disabled] .btn-default:active,
  fieldset[disabled] .btn-default.active {
    color: #000;
    background-color: #807f7f;
    border-color: #807f7f; }
  .btn-default .badge {
    color: #807f7f;
    background-color: #000; }
  .btn-default.btn-link {
    color: #807f7f;
    background-color: transparent;
    border-color: transparent; }
    .btn-default.btn-link.hover, .btn-default.btn-link:hover, .btn-default.btn-link.active, .btn-default.btn-link:active, .btn-default.btn-link.active.hover, .btn-default.btn-link:active:hover, .btn-default.btn-link.active.focus, .btn-default.btn-link:active:focus, .btn-default.btn-link.focus, .btn-default.btn-link:focus,
    .open > .btn-default.btn-link.dropdown-toggle {
      color: #949393;
      background-color: transparent;
      border-color: transparent; }
    .btn-default.btn-link:active, .btn-default.btn-link.active,
    .open > .btn-default.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-default.btn-link.disabled, .btn-default.btn-link.disabled.hover, .btn-default.btn-link.disabled:hover, .btn-default.btn-link.disabled:focus, .btn-default.btn-link.disabled.focus, .btn-default.btn-link.disabled:active, .btn-default.btn-link.disabled.active, .btn-default.btn-link[disabled], .btn-default.btn-link[disabled].hover, .btn-default.btn-link[disabled]:hover, .btn-default.btn-link[disabled]:focus, .btn-default.btn-link[disabled].focus, .btn-default.btn-link[disabled]:active, .btn-default.btn-link[disabled].active,
    fieldset[disabled] .btn-default.btn-link,
    fieldset[disabled] .btn-default.btn-link.hover,
    fieldset[disabled] .btn-default.btn-link:hover,
    fieldset[disabled] .btn-default.btn-link:focus,
    fieldset[disabled] .btn-default.btn-link.focus,
    fieldset[disabled] .btn-default.btn-link:active,
    fieldset[disabled] .btn-default.btn-link.active {
      color: #807f7f;
      background-color: transparent;
      border-color: transparent; }
    .btn-default.btn-link .badge {
      color: transparent;
      background-color: #807f7f; }
  .btn-default.btn-bordered {
    color: #807f7f;
    background-color: transparent;
    border-color: #807f7f; }
    .btn-default.btn-bordered.hover, .btn-default.btn-bordered:hover, .btn-default.btn-bordered.active, .btn-default.btn-bordered:active, .btn-default.btn-bordered.active.hover, .btn-default.btn-bordered:active:hover, .btn-default.btn-bordered.active.focus, .btn-default.btn-bordered:active:focus, .btn-default.btn-bordered.focus, .btn-default.btn-bordered:focus,
    .open > .btn-default.btn-bordered.dropdown-toggle {
      color: #949393;
      background-color: transparent;
      border-color: #949393; }
    .btn-default.btn-bordered:active, .btn-default.btn-bordered.active,
    .open > .btn-default.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-default.btn-bordered.disabled, .btn-default.btn-bordered.disabled.hover, .btn-default.btn-bordered.disabled:hover, .btn-default.btn-bordered.disabled:focus, .btn-default.btn-bordered.disabled.focus, .btn-default.btn-bordered.disabled:active, .btn-default.btn-bordered.disabled.active, .btn-default.btn-bordered[disabled], .btn-default.btn-bordered[disabled].hover, .btn-default.btn-bordered[disabled]:hover, .btn-default.btn-bordered[disabled]:focus, .btn-default.btn-bordered[disabled].focus, .btn-default.btn-bordered[disabled]:active, .btn-default.btn-bordered[disabled].active,
    fieldset[disabled] .btn-default.btn-bordered,
    fieldset[disabled] .btn-default.btn-bordered.hover,
    fieldset[disabled] .btn-default.btn-bordered:hover,
    fieldset[disabled] .btn-default.btn-bordered:focus,
    fieldset[disabled] .btn-default.btn-bordered.focus,
    fieldset[disabled] .btn-default.btn-bordered:active,
    fieldset[disabled] .btn-default.btn-bordered.active {
      color: #807f7f;
      background-color: transparent;
      border-color: #807f7f; }
    .btn-default.btn-bordered .badge {
      color: #807f7f;
      background-color: #807f7f; }

.btn-primary {
  color: #fff;
  background-color: #92C5EB;
  border-color: #92C5EB; }
  .btn-primary.hover, .btn-primary:hover, .btn-primary.active, .btn-primary:active, .btn-primary.active.hover, .btn-primary:active:hover, .btn-primary.active.focus, .btn-primary:active:focus, .btn-primary.focus, .btn-primary:focus,
  .open > .btn-primary.dropdown-toggle {
    color: white;
    background-color: #d2e5f4;
    border-color: #d2e5f4; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-image: none; }
  .btn-primary.disabled, .btn-primary.disabled.hover, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled].hover, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary.hover,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    color: #fff;
    background-color: #92C5EB;
    border-color: #92C5EB; }
  .btn-primary .badge {
    color: #92C5EB;
    background-color: #fff; }
  .btn-primary.btn-link {
    color: #92C5EB;
    background-color: transparent;
    border-color: transparent; }
    .btn-primary.btn-link.hover, .btn-primary.btn-link:hover, .btn-primary.btn-link.active, .btn-primary.btn-link:active, .btn-primary.btn-link.active.hover, .btn-primary.btn-link:active:hover, .btn-primary.btn-link.active.focus, .btn-primary.btn-link:active:focus, .btn-primary.btn-link.focus, .btn-primary.btn-link:focus,
    .open > .btn-primary.btn-link.dropdown-toggle {
      color: #d2e5f4;
      background-color: transparent;
      border-color: transparent; }
    .btn-primary.btn-link:active, .btn-primary.btn-link.active,
    .open > .btn-primary.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-primary.btn-link.disabled, .btn-primary.btn-link.disabled.hover, .btn-primary.btn-link.disabled:hover, .btn-primary.btn-link.disabled:focus, .btn-primary.btn-link.disabled.focus, .btn-primary.btn-link.disabled:active, .btn-primary.btn-link.disabled.active, .btn-primary.btn-link[disabled], .btn-primary.btn-link[disabled].hover, .btn-primary.btn-link[disabled]:hover, .btn-primary.btn-link[disabled]:focus, .btn-primary.btn-link[disabled].focus, .btn-primary.btn-link[disabled]:active, .btn-primary.btn-link[disabled].active,
    fieldset[disabled] .btn-primary.btn-link,
    fieldset[disabled] .btn-primary.btn-link.hover,
    fieldset[disabled] .btn-primary.btn-link:hover,
    fieldset[disabled] .btn-primary.btn-link:focus,
    fieldset[disabled] .btn-primary.btn-link.focus,
    fieldset[disabled] .btn-primary.btn-link:active,
    fieldset[disabled] .btn-primary.btn-link.active {
      color: #92C5EB;
      background-color: transparent;
      border-color: transparent; }
    .btn-primary.btn-link .badge {
      color: transparent;
      background-color: #92C5EB; }
  .btn-primary.btn-bordered {
    color: #92C5EB;
    background-color: transparent;
    border-color: #92C5EB; }
    .btn-primary.btn-bordered.hover, .btn-primary.btn-bordered:hover, .btn-primary.btn-bordered.active, .btn-primary.btn-bordered:active, .btn-primary.btn-bordered.active.hover, .btn-primary.btn-bordered:active:hover, .btn-primary.btn-bordered.active.focus, .btn-primary.btn-bordered:active:focus, .btn-primary.btn-bordered.focus, .btn-primary.btn-bordered:focus,
    .open > .btn-primary.btn-bordered.dropdown-toggle {
      color: #d2e5f4;
      background-color: transparent;
      border-color: #d2e5f4; }
    .btn-primary.btn-bordered:active, .btn-primary.btn-bordered.active,
    .open > .btn-primary.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-primary.btn-bordered.disabled, .btn-primary.btn-bordered.disabled.hover, .btn-primary.btn-bordered.disabled:hover, .btn-primary.btn-bordered.disabled:focus, .btn-primary.btn-bordered.disabled.focus, .btn-primary.btn-bordered.disabled:active, .btn-primary.btn-bordered.disabled.active, .btn-primary.btn-bordered[disabled], .btn-primary.btn-bordered[disabled].hover, .btn-primary.btn-bordered[disabled]:hover, .btn-primary.btn-bordered[disabled]:focus, .btn-primary.btn-bordered[disabled].focus, .btn-primary.btn-bordered[disabled]:active, .btn-primary.btn-bordered[disabled].active,
    fieldset[disabled] .btn-primary.btn-bordered,
    fieldset[disabled] .btn-primary.btn-bordered.hover,
    fieldset[disabled] .btn-primary.btn-bordered:hover,
    fieldset[disabled] .btn-primary.btn-bordered:focus,
    fieldset[disabled] .btn-primary.btn-bordered.focus,
    fieldset[disabled] .btn-primary.btn-bordered:active,
    fieldset[disabled] .btn-primary.btn-bordered.active {
      color: #92C5EB;
      background-color: transparent;
      border-color: #92C5EB; }
    .btn-primary.btn-bordered .badge {
      color: #92C5EB;
      background-color: #92C5EB; }

.btn-secondary {
  color: #fff;
  background-color: #2A89CF;
  border-color: #2A89CF; }
  .btn-secondary.hover, .btn-secondary:hover, .btn-secondary.active, .btn-secondary:active, .btn-secondary.active.hover, .btn-secondary:active:hover, .btn-secondary.active.focus, .btn-secondary:active:focus, .btn-secondary.focus, .btn-secondary:focus,
  .open > .btn-secondary.dropdown-toggle {
    color: white;
    background-color: #8baac2;
    border-color: #8baac2; }
  .btn-secondary:active, .btn-secondary.active,
  .open > .btn-secondary.dropdown-toggle {
    background-image: none; }
  .btn-secondary.disabled, .btn-secondary.disabled.hover, .btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary.disabled:active, .btn-secondary.disabled.active, .btn-secondary[disabled], .btn-secondary[disabled].hover, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus, .btn-secondary[disabled]:active, .btn-secondary[disabled].active,
  fieldset[disabled] .btn-secondary,
  fieldset[disabled] .btn-secondary.hover,
  fieldset[disabled] .btn-secondary:hover,
  fieldset[disabled] .btn-secondary:focus,
  fieldset[disabled] .btn-secondary.focus,
  fieldset[disabled] .btn-secondary:active,
  fieldset[disabled] .btn-secondary.active {
    color: #fff;
    background-color: #2A89CF;
    border-color: #2A89CF; }
  .btn-secondary .badge {
    color: #2A89CF;
    background-color: #fff; }
  .btn-secondary.btn-link {
    color: #2A89CF;
    background-color: transparent;
    border-color: transparent; }
    .btn-secondary.btn-link.hover, .btn-secondary.btn-link:hover, .btn-secondary.btn-link.active, .btn-secondary.btn-link:active, .btn-secondary.btn-link.active.hover, .btn-secondary.btn-link:active:hover, .btn-secondary.btn-link.active.focus, .btn-secondary.btn-link:active:focus, .btn-secondary.btn-link.focus, .btn-secondary.btn-link:focus,
    .open > .btn-secondary.btn-link.dropdown-toggle {
      color: #8baac2;
      background-color: transparent;
      border-color: transparent; }
    .btn-secondary.btn-link:active, .btn-secondary.btn-link.active,
    .open > .btn-secondary.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-secondary.btn-link.disabled, .btn-secondary.btn-link.disabled.hover, .btn-secondary.btn-link.disabled:hover, .btn-secondary.btn-link.disabled:focus, .btn-secondary.btn-link.disabled.focus, .btn-secondary.btn-link.disabled:active, .btn-secondary.btn-link.disabled.active, .btn-secondary.btn-link[disabled], .btn-secondary.btn-link[disabled].hover, .btn-secondary.btn-link[disabled]:hover, .btn-secondary.btn-link[disabled]:focus, .btn-secondary.btn-link[disabled].focus, .btn-secondary.btn-link[disabled]:active, .btn-secondary.btn-link[disabled].active,
    fieldset[disabled] .btn-secondary.btn-link,
    fieldset[disabled] .btn-secondary.btn-link.hover,
    fieldset[disabled] .btn-secondary.btn-link:hover,
    fieldset[disabled] .btn-secondary.btn-link:focus,
    fieldset[disabled] .btn-secondary.btn-link.focus,
    fieldset[disabled] .btn-secondary.btn-link:active,
    fieldset[disabled] .btn-secondary.btn-link.active {
      color: #2A89CF;
      background-color: transparent;
      border-color: transparent; }
    .btn-secondary.btn-link .badge {
      color: transparent;
      background-color: #2A89CF; }
  .btn-secondary.btn-bordered {
    color: #2A89CF;
    background-color: transparent;
    border-color: #2A89CF; }
    .btn-secondary.btn-bordered.hover, .btn-secondary.btn-bordered:hover, .btn-secondary.btn-bordered.active, .btn-secondary.btn-bordered:active, .btn-secondary.btn-bordered.active.hover, .btn-secondary.btn-bordered:active:hover, .btn-secondary.btn-bordered.active.focus, .btn-secondary.btn-bordered:active:focus, .btn-secondary.btn-bordered.focus, .btn-secondary.btn-bordered:focus,
    .open > .btn-secondary.btn-bordered.dropdown-toggle {
      color: #8baac2;
      background-color: transparent;
      border-color: #8baac2; }
    .btn-secondary.btn-bordered:active, .btn-secondary.btn-bordered.active,
    .open > .btn-secondary.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-secondary.btn-bordered.disabled, .btn-secondary.btn-bordered.disabled.hover, .btn-secondary.btn-bordered.disabled:hover, .btn-secondary.btn-bordered.disabled:focus, .btn-secondary.btn-bordered.disabled.focus, .btn-secondary.btn-bordered.disabled:active, .btn-secondary.btn-bordered.disabled.active, .btn-secondary.btn-bordered[disabled], .btn-secondary.btn-bordered[disabled].hover, .btn-secondary.btn-bordered[disabled]:hover, .btn-secondary.btn-bordered[disabled]:focus, .btn-secondary.btn-bordered[disabled].focus, .btn-secondary.btn-bordered[disabled]:active, .btn-secondary.btn-bordered[disabled].active,
    fieldset[disabled] .btn-secondary.btn-bordered,
    fieldset[disabled] .btn-secondary.btn-bordered.hover,
    fieldset[disabled] .btn-secondary.btn-bordered:hover,
    fieldset[disabled] .btn-secondary.btn-bordered:focus,
    fieldset[disabled] .btn-secondary.btn-bordered.focus,
    fieldset[disabled] .btn-secondary.btn-bordered:active,
    fieldset[disabled] .btn-secondary.btn-bordered.active {
      color: #2A89CF;
      background-color: transparent;
      border-color: #2A89CF; }
    .btn-secondary.btn-bordered .badge {
      color: #2A89CF;
      background-color: #2A89CF; }

.btn-tertiary {
  color: #fff;
  background-color: #2A89CF;
  border-color: #2A89CF; }
  .btn-tertiary.hover, .btn-tertiary:hover, .btn-tertiary.active, .btn-tertiary:active, .btn-tertiary.active.hover, .btn-tertiary:active:hover, .btn-tertiary.active.focus, .btn-tertiary:active:focus, .btn-tertiary.focus, .btn-tertiary:focus,
  .open > .btn-tertiary.dropdown-toggle {
    color: white;
    background-color: #489cda;
    border-color: #489cda; }
  .btn-tertiary:active, .btn-tertiary.active,
  .open > .btn-tertiary.dropdown-toggle {
    background-image: none; }
  .btn-tertiary.disabled, .btn-tertiary.disabled.hover, .btn-tertiary.disabled:hover, .btn-tertiary.disabled:focus, .btn-tertiary.disabled.focus, .btn-tertiary.disabled:active, .btn-tertiary.disabled.active, .btn-tertiary[disabled], .btn-tertiary[disabled].hover, .btn-tertiary[disabled]:hover, .btn-tertiary[disabled]:focus, .btn-tertiary[disabled].focus, .btn-tertiary[disabled]:active, .btn-tertiary[disabled].active,
  fieldset[disabled] .btn-tertiary,
  fieldset[disabled] .btn-tertiary.hover,
  fieldset[disabled] .btn-tertiary:hover,
  fieldset[disabled] .btn-tertiary:focus,
  fieldset[disabled] .btn-tertiary.focus,
  fieldset[disabled] .btn-tertiary:active,
  fieldset[disabled] .btn-tertiary.active {
    color: #fff;
    background-color: #2A89CF;
    border-color: #2A89CF; }
  .btn-tertiary .badge {
    color: #2A89CF;
    background-color: #fff; }
  .btn-tertiary.btn-link {
    color: #2A89CF;
    background-color: transparent;
    border-color: transparent; }
    .btn-tertiary.btn-link.hover, .btn-tertiary.btn-link:hover, .btn-tertiary.btn-link.active, .btn-tertiary.btn-link:active, .btn-tertiary.btn-link.active.hover, .btn-tertiary.btn-link:active:hover, .btn-tertiary.btn-link.active.focus, .btn-tertiary.btn-link:active:focus, .btn-tertiary.btn-link.focus, .btn-tertiary.btn-link:focus,
    .open > .btn-tertiary.btn-link.dropdown-toggle {
      color: #489cda;
      background-color: transparent;
      border-color: transparent; }
    .btn-tertiary.btn-link:active, .btn-tertiary.btn-link.active,
    .open > .btn-tertiary.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-tertiary.btn-link.disabled, .btn-tertiary.btn-link.disabled.hover, .btn-tertiary.btn-link.disabled:hover, .btn-tertiary.btn-link.disabled:focus, .btn-tertiary.btn-link.disabled.focus, .btn-tertiary.btn-link.disabled:active, .btn-tertiary.btn-link.disabled.active, .btn-tertiary.btn-link[disabled], .btn-tertiary.btn-link[disabled].hover, .btn-tertiary.btn-link[disabled]:hover, .btn-tertiary.btn-link[disabled]:focus, .btn-tertiary.btn-link[disabled].focus, .btn-tertiary.btn-link[disabled]:active, .btn-tertiary.btn-link[disabled].active,
    fieldset[disabled] .btn-tertiary.btn-link,
    fieldset[disabled] .btn-tertiary.btn-link.hover,
    fieldset[disabled] .btn-tertiary.btn-link:hover,
    fieldset[disabled] .btn-tertiary.btn-link:focus,
    fieldset[disabled] .btn-tertiary.btn-link.focus,
    fieldset[disabled] .btn-tertiary.btn-link:active,
    fieldset[disabled] .btn-tertiary.btn-link.active {
      color: #2A89CF;
      background-color: transparent;
      border-color: transparent; }
    .btn-tertiary.btn-link .badge {
      color: transparent;
      background-color: #2A89CF; }
  .btn-tertiary.btn-bordered {
    color: #2A89CF;
    background-color: transparent;
    border-color: #2A89CF; }
    .btn-tertiary.btn-bordered.hover, .btn-tertiary.btn-bordered:hover, .btn-tertiary.btn-bordered.active, .btn-tertiary.btn-bordered:active, .btn-tertiary.btn-bordered.active.hover, .btn-tertiary.btn-bordered:active:hover, .btn-tertiary.btn-bordered.active.focus, .btn-tertiary.btn-bordered:active:focus, .btn-tertiary.btn-bordered.focus, .btn-tertiary.btn-bordered:focus,
    .open > .btn-tertiary.btn-bordered.dropdown-toggle {
      color: #489cda;
      background-color: transparent;
      border-color: #489cda; }
    .btn-tertiary.btn-bordered:active, .btn-tertiary.btn-bordered.active,
    .open > .btn-tertiary.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-tertiary.btn-bordered.disabled, .btn-tertiary.btn-bordered.disabled.hover, .btn-tertiary.btn-bordered.disabled:hover, .btn-tertiary.btn-bordered.disabled:focus, .btn-tertiary.btn-bordered.disabled.focus, .btn-tertiary.btn-bordered.disabled:active, .btn-tertiary.btn-bordered.disabled.active, .btn-tertiary.btn-bordered[disabled], .btn-tertiary.btn-bordered[disabled].hover, .btn-tertiary.btn-bordered[disabled]:hover, .btn-tertiary.btn-bordered[disabled]:focus, .btn-tertiary.btn-bordered[disabled].focus, .btn-tertiary.btn-bordered[disabled]:active, .btn-tertiary.btn-bordered[disabled].active,
    fieldset[disabled] .btn-tertiary.btn-bordered,
    fieldset[disabled] .btn-tertiary.btn-bordered.hover,
    fieldset[disabled] .btn-tertiary.btn-bordered:hover,
    fieldset[disabled] .btn-tertiary.btn-bordered:focus,
    fieldset[disabled] .btn-tertiary.btn-bordered.focus,
    fieldset[disabled] .btn-tertiary.btn-bordered:active,
    fieldset[disabled] .btn-tertiary.btn-bordered.active {
      color: #2A89CF;
      background-color: transparent;
      border-color: #2A89CF; }
    .btn-tertiary.btn-bordered .badge {
      color: #2A89CF;
      background-color: #2A89CF; }

.btn-success {
  color: #fff;
  background-color: #2A89CF;
  border-color: #2A89CF; }
  .btn-success.hover, .btn-success:hover, .btn-success.active, .btn-success:active, .btn-success.active.hover, .btn-success:active:hover, .btn-success.active.focus, .btn-success:active:focus, .btn-success.focus, .btn-success:focus,
  .open > .btn-success.dropdown-toggle {
    color: white;
    background-color: #489cda;
    border-color: #489cda; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-image: none; }
  .btn-success.disabled, .btn-success.disabled.hover, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled].hover, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success.hover,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    color: #fff;
    background-color: #2A89CF;
    border-color: #2A89CF; }
  .btn-success .badge {
    color: #2A89CF;
    background-color: #fff; }
  .btn-success.btn-link {
    color: #2A89CF;
    background-color: transparent;
    border-color: transparent; }
    .btn-success.btn-link.hover, .btn-success.btn-link:hover, .btn-success.btn-link.active, .btn-success.btn-link:active, .btn-success.btn-link.active.hover, .btn-success.btn-link:active:hover, .btn-success.btn-link.active.focus, .btn-success.btn-link:active:focus, .btn-success.btn-link.focus, .btn-success.btn-link:focus,
    .open > .btn-success.btn-link.dropdown-toggle {
      color: #489cda;
      background-color: transparent;
      border-color: transparent; }
    .btn-success.btn-link:active, .btn-success.btn-link.active,
    .open > .btn-success.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-success.btn-link.disabled, .btn-success.btn-link.disabled.hover, .btn-success.btn-link.disabled:hover, .btn-success.btn-link.disabled:focus, .btn-success.btn-link.disabled.focus, .btn-success.btn-link.disabled:active, .btn-success.btn-link.disabled.active, .btn-success.btn-link[disabled], .btn-success.btn-link[disabled].hover, .btn-success.btn-link[disabled]:hover, .btn-success.btn-link[disabled]:focus, .btn-success.btn-link[disabled].focus, .btn-success.btn-link[disabled]:active, .btn-success.btn-link[disabled].active,
    fieldset[disabled] .btn-success.btn-link,
    fieldset[disabled] .btn-success.btn-link.hover,
    fieldset[disabled] .btn-success.btn-link:hover,
    fieldset[disabled] .btn-success.btn-link:focus,
    fieldset[disabled] .btn-success.btn-link.focus,
    fieldset[disabled] .btn-success.btn-link:active,
    fieldset[disabled] .btn-success.btn-link.active {
      color: #2A89CF;
      background-color: transparent;
      border-color: transparent; }
    .btn-success.btn-link .badge {
      color: transparent;
      background-color: #2A89CF; }
  .btn-success.btn-bordered {
    color: #2A89CF;
    background-color: transparent;
    border-color: #2A89CF; }
    .btn-success.btn-bordered.hover, .btn-success.btn-bordered:hover, .btn-success.btn-bordered.active, .btn-success.btn-bordered:active, .btn-success.btn-bordered.active.hover, .btn-success.btn-bordered:active:hover, .btn-success.btn-bordered.active.focus, .btn-success.btn-bordered:active:focus, .btn-success.btn-bordered.focus, .btn-success.btn-bordered:focus,
    .open > .btn-success.btn-bordered.dropdown-toggle {
      color: #489cda;
      background-color: transparent;
      border-color: #489cda; }
    .btn-success.btn-bordered:active, .btn-success.btn-bordered.active,
    .open > .btn-success.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-success.btn-bordered.disabled, .btn-success.btn-bordered.disabled.hover, .btn-success.btn-bordered.disabled:hover, .btn-success.btn-bordered.disabled:focus, .btn-success.btn-bordered.disabled.focus, .btn-success.btn-bordered.disabled:active, .btn-success.btn-bordered.disabled.active, .btn-success.btn-bordered[disabled], .btn-success.btn-bordered[disabled].hover, .btn-success.btn-bordered[disabled]:hover, .btn-success.btn-bordered[disabled]:focus, .btn-success.btn-bordered[disabled].focus, .btn-success.btn-bordered[disabled]:active, .btn-success.btn-bordered[disabled].active,
    fieldset[disabled] .btn-success.btn-bordered,
    fieldset[disabled] .btn-success.btn-bordered.hover,
    fieldset[disabled] .btn-success.btn-bordered:hover,
    fieldset[disabled] .btn-success.btn-bordered:focus,
    fieldset[disabled] .btn-success.btn-bordered.focus,
    fieldset[disabled] .btn-success.btn-bordered:active,
    fieldset[disabled] .btn-success.btn-bordered.active {
      color: #2A89CF;
      background-color: transparent;
      border-color: #2A89CF; }
    .btn-success.btn-bordered .badge {
      color: #2A89CF;
      background-color: #2A89CF; }

.btn-info {
  color: #fff;
  background-color: #46a6b1;
  border-color: #46a6b1; }
  .btn-info.hover, .btn-info:hover, .btn-info.active, .btn-info:active, .btn-info.active.hover, .btn-info:active:hover, .btn-info.active.focus, .btn-info:active:focus, .btn-info.focus, .btn-info:focus,
  .open > .btn-info.dropdown-toggle {
    color: white;
    background-color: #60b6c0;
    border-color: #60b6c0; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-image: none; }
  .btn-info.disabled, .btn-info.disabled.hover, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled].hover, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info.hover,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    color: #fff;
    background-color: #46a6b1;
    border-color: #46a6b1; }
  .btn-info .badge {
    color: #46a6b1;
    background-color: #fff; }
  .btn-info.btn-link {
    color: #46a6b1;
    background-color: transparent;
    border-color: transparent; }
    .btn-info.btn-link.hover, .btn-info.btn-link:hover, .btn-info.btn-link.active, .btn-info.btn-link:active, .btn-info.btn-link.active.hover, .btn-info.btn-link:active:hover, .btn-info.btn-link.active.focus, .btn-info.btn-link:active:focus, .btn-info.btn-link.focus, .btn-info.btn-link:focus,
    .open > .btn-info.btn-link.dropdown-toggle {
      color: #60b6c0;
      background-color: transparent;
      border-color: transparent; }
    .btn-info.btn-link:active, .btn-info.btn-link.active,
    .open > .btn-info.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-info.btn-link.disabled, .btn-info.btn-link.disabled.hover, .btn-info.btn-link.disabled:hover, .btn-info.btn-link.disabled:focus, .btn-info.btn-link.disabled.focus, .btn-info.btn-link.disabled:active, .btn-info.btn-link.disabled.active, .btn-info.btn-link[disabled], .btn-info.btn-link[disabled].hover, .btn-info.btn-link[disabled]:hover, .btn-info.btn-link[disabled]:focus, .btn-info.btn-link[disabled].focus, .btn-info.btn-link[disabled]:active, .btn-info.btn-link[disabled].active,
    fieldset[disabled] .btn-info.btn-link,
    fieldset[disabled] .btn-info.btn-link.hover,
    fieldset[disabled] .btn-info.btn-link:hover,
    fieldset[disabled] .btn-info.btn-link:focus,
    fieldset[disabled] .btn-info.btn-link.focus,
    fieldset[disabled] .btn-info.btn-link:active,
    fieldset[disabled] .btn-info.btn-link.active {
      color: #46a6b1;
      background-color: transparent;
      border-color: transparent; }
    .btn-info.btn-link .badge {
      color: transparent;
      background-color: #46a6b1; }
  .btn-info.btn-bordered {
    color: #46a6b1;
    background-color: transparent;
    border-color: #46a6b1; }
    .btn-info.btn-bordered.hover, .btn-info.btn-bordered:hover, .btn-info.btn-bordered.active, .btn-info.btn-bordered:active, .btn-info.btn-bordered.active.hover, .btn-info.btn-bordered:active:hover, .btn-info.btn-bordered.active.focus, .btn-info.btn-bordered:active:focus, .btn-info.btn-bordered.focus, .btn-info.btn-bordered:focus,
    .open > .btn-info.btn-bordered.dropdown-toggle {
      color: #60b6c0;
      background-color: transparent;
      border-color: #60b6c0; }
    .btn-info.btn-bordered:active, .btn-info.btn-bordered.active,
    .open > .btn-info.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-info.btn-bordered.disabled, .btn-info.btn-bordered.disabled.hover, .btn-info.btn-bordered.disabled:hover, .btn-info.btn-bordered.disabled:focus, .btn-info.btn-bordered.disabled.focus, .btn-info.btn-bordered.disabled:active, .btn-info.btn-bordered.disabled.active, .btn-info.btn-bordered[disabled], .btn-info.btn-bordered[disabled].hover, .btn-info.btn-bordered[disabled]:hover, .btn-info.btn-bordered[disabled]:focus, .btn-info.btn-bordered[disabled].focus, .btn-info.btn-bordered[disabled]:active, .btn-info.btn-bordered[disabled].active,
    fieldset[disabled] .btn-info.btn-bordered,
    fieldset[disabled] .btn-info.btn-bordered.hover,
    fieldset[disabled] .btn-info.btn-bordered:hover,
    fieldset[disabled] .btn-info.btn-bordered:focus,
    fieldset[disabled] .btn-info.btn-bordered.focus,
    fieldset[disabled] .btn-info.btn-bordered:active,
    fieldset[disabled] .btn-info.btn-bordered.active {
      color: #46a6b1;
      background-color: transparent;
      border-color: #46a6b1; }
    .btn-info.btn-bordered .badge {
      color: #46a6b1;
      background-color: #46a6b1; }

.btn-warning {
  color: #000;
  background-color: #f3bb5b;
  border-color: #f3bb5b; }
  .btn-warning.hover, .btn-warning:hover, .btn-warning.active, .btn-warning:active, .btn-warning.active.hover, .btn-warning:active:hover, .btn-warning.active.focus, .btn-warning:active:focus, .btn-warning.focus, .btn-warning:focus,
  .open > .btn-warning.dropdown-toggle {
    color: #212121;
    background-color: #f6cb81;
    border-color: #f6cb81; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-image: none; }
  .btn-warning.disabled, .btn-warning.disabled.hover, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled].hover, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning.hover,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    color: #000;
    background-color: #f3bb5b;
    border-color: #f3bb5b; }
  .btn-warning .badge {
    color: #f3bb5b;
    background-color: #000; }
  .btn-warning.btn-link {
    color: #f3bb5b;
    background-color: transparent;
    border-color: transparent; }
    .btn-warning.btn-link.hover, .btn-warning.btn-link:hover, .btn-warning.btn-link.active, .btn-warning.btn-link:active, .btn-warning.btn-link.active.hover, .btn-warning.btn-link:active:hover, .btn-warning.btn-link.active.focus, .btn-warning.btn-link:active:focus, .btn-warning.btn-link.focus, .btn-warning.btn-link:focus,
    .open > .btn-warning.btn-link.dropdown-toggle {
      color: #f6cb81;
      background-color: transparent;
      border-color: transparent; }
    .btn-warning.btn-link:active, .btn-warning.btn-link.active,
    .open > .btn-warning.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-warning.btn-link.disabled, .btn-warning.btn-link.disabled.hover, .btn-warning.btn-link.disabled:hover, .btn-warning.btn-link.disabled:focus, .btn-warning.btn-link.disabled.focus, .btn-warning.btn-link.disabled:active, .btn-warning.btn-link.disabled.active, .btn-warning.btn-link[disabled], .btn-warning.btn-link[disabled].hover, .btn-warning.btn-link[disabled]:hover, .btn-warning.btn-link[disabled]:focus, .btn-warning.btn-link[disabled].focus, .btn-warning.btn-link[disabled]:active, .btn-warning.btn-link[disabled].active,
    fieldset[disabled] .btn-warning.btn-link,
    fieldset[disabled] .btn-warning.btn-link.hover,
    fieldset[disabled] .btn-warning.btn-link:hover,
    fieldset[disabled] .btn-warning.btn-link:focus,
    fieldset[disabled] .btn-warning.btn-link.focus,
    fieldset[disabled] .btn-warning.btn-link:active,
    fieldset[disabled] .btn-warning.btn-link.active {
      color: #f3bb5b;
      background-color: transparent;
      border-color: transparent; }
    .btn-warning.btn-link .badge {
      color: transparent;
      background-color: #f3bb5b; }
  .btn-warning.btn-bordered {
    color: #f3bb5b;
    background-color: transparent;
    border-color: #f3bb5b; }
    .btn-warning.btn-bordered.hover, .btn-warning.btn-bordered:hover, .btn-warning.btn-bordered.active, .btn-warning.btn-bordered:active, .btn-warning.btn-bordered.active.hover, .btn-warning.btn-bordered:active:hover, .btn-warning.btn-bordered.active.focus, .btn-warning.btn-bordered:active:focus, .btn-warning.btn-bordered.focus, .btn-warning.btn-bordered:focus,
    .open > .btn-warning.btn-bordered.dropdown-toggle {
      color: #f6cb81;
      background-color: transparent;
      border-color: #f6cb81; }
    .btn-warning.btn-bordered:active, .btn-warning.btn-bordered.active,
    .open > .btn-warning.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-warning.btn-bordered.disabled, .btn-warning.btn-bordered.disabled.hover, .btn-warning.btn-bordered.disabled:hover, .btn-warning.btn-bordered.disabled:focus, .btn-warning.btn-bordered.disabled.focus, .btn-warning.btn-bordered.disabled:active, .btn-warning.btn-bordered.disabled.active, .btn-warning.btn-bordered[disabled], .btn-warning.btn-bordered[disabled].hover, .btn-warning.btn-bordered[disabled]:hover, .btn-warning.btn-bordered[disabled]:focus, .btn-warning.btn-bordered[disabled].focus, .btn-warning.btn-bordered[disabled]:active, .btn-warning.btn-bordered[disabled].active,
    fieldset[disabled] .btn-warning.btn-bordered,
    fieldset[disabled] .btn-warning.btn-bordered.hover,
    fieldset[disabled] .btn-warning.btn-bordered:hover,
    fieldset[disabled] .btn-warning.btn-bordered:focus,
    fieldset[disabled] .btn-warning.btn-bordered.focus,
    fieldset[disabled] .btn-warning.btn-bordered:active,
    fieldset[disabled] .btn-warning.btn-bordered.active {
      color: #f3bb5b;
      background-color: transparent;
      border-color: #f3bb5b; }
    .btn-warning.btn-bordered .badge {
      color: #f3bb5b;
      background-color: #f3bb5b; }

.btn-danger {
  color: #000;
  background-color: #ea4b77;
  border-color: #ea4b77; }
  .btn-danger.hover, .btn-danger:hover, .btn-danger.active, .btn-danger:active, .btn-danger.active.hover, .btn-danger:active:hover, .btn-danger.active.focus, .btn-danger:active:focus, .btn-danger.focus, .btn-danger:focus,
  .open > .btn-danger.dropdown-toggle {
    color: #212121;
    background-color: #ee7093;
    border-color: #ee7093; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-image: none; }
  .btn-danger.disabled, .btn-danger.disabled.hover, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled].hover, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger.hover,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    color: #000;
    background-color: #ea4b77;
    border-color: #ea4b77; }
  .btn-danger .badge {
    color: #ea4b77;
    background-color: #000; }
  .btn-danger.btn-link {
    color: #ea4b77;
    background-color: transparent;
    border-color: transparent; }
    .btn-danger.btn-link.hover, .btn-danger.btn-link:hover, .btn-danger.btn-link.active, .btn-danger.btn-link:active, .btn-danger.btn-link.active.hover, .btn-danger.btn-link:active:hover, .btn-danger.btn-link.active.focus, .btn-danger.btn-link:active:focus, .btn-danger.btn-link.focus, .btn-danger.btn-link:focus,
    .open > .btn-danger.btn-link.dropdown-toggle {
      color: #ee7093;
      background-color: transparent;
      border-color: transparent; }
    .btn-danger.btn-link:active, .btn-danger.btn-link.active,
    .open > .btn-danger.btn-link.dropdown-toggle {
      background-image: none; }
    .btn-danger.btn-link.disabled, .btn-danger.btn-link.disabled.hover, .btn-danger.btn-link.disabled:hover, .btn-danger.btn-link.disabled:focus, .btn-danger.btn-link.disabled.focus, .btn-danger.btn-link.disabled:active, .btn-danger.btn-link.disabled.active, .btn-danger.btn-link[disabled], .btn-danger.btn-link[disabled].hover, .btn-danger.btn-link[disabled]:hover, .btn-danger.btn-link[disabled]:focus, .btn-danger.btn-link[disabled].focus, .btn-danger.btn-link[disabled]:active, .btn-danger.btn-link[disabled].active,
    fieldset[disabled] .btn-danger.btn-link,
    fieldset[disabled] .btn-danger.btn-link.hover,
    fieldset[disabled] .btn-danger.btn-link:hover,
    fieldset[disabled] .btn-danger.btn-link:focus,
    fieldset[disabled] .btn-danger.btn-link.focus,
    fieldset[disabled] .btn-danger.btn-link:active,
    fieldset[disabled] .btn-danger.btn-link.active {
      color: #ea4b77;
      background-color: transparent;
      border-color: transparent; }
    .btn-danger.btn-link .badge {
      color: transparent;
      background-color: #ea4b77; }
  .btn-danger.btn-bordered {
    color: #ea4b77;
    background-color: transparent;
    border-color: #ea4b77; }
    .btn-danger.btn-bordered.hover, .btn-danger.btn-bordered:hover, .btn-danger.btn-bordered.active, .btn-danger.btn-bordered:active, .btn-danger.btn-bordered.active.hover, .btn-danger.btn-bordered:active:hover, .btn-danger.btn-bordered.active.focus, .btn-danger.btn-bordered:active:focus, .btn-danger.btn-bordered.focus, .btn-danger.btn-bordered:focus,
    .open > .btn-danger.btn-bordered.dropdown-toggle {
      color: #ee7093;
      background-color: transparent;
      border-color: #ee7093; }
    .btn-danger.btn-bordered:active, .btn-danger.btn-bordered.active,
    .open > .btn-danger.btn-bordered.dropdown-toggle {
      background-image: none; }
    .btn-danger.btn-bordered.disabled, .btn-danger.btn-bordered.disabled.hover, .btn-danger.btn-bordered.disabled:hover, .btn-danger.btn-bordered.disabled:focus, .btn-danger.btn-bordered.disabled.focus, .btn-danger.btn-bordered.disabled:active, .btn-danger.btn-bordered.disabled.active, .btn-danger.btn-bordered[disabled], .btn-danger.btn-bordered[disabled].hover, .btn-danger.btn-bordered[disabled]:hover, .btn-danger.btn-bordered[disabled]:focus, .btn-danger.btn-bordered[disabled].focus, .btn-danger.btn-bordered[disabled]:active, .btn-danger.btn-bordered[disabled].active,
    fieldset[disabled] .btn-danger.btn-bordered,
    fieldset[disabled] .btn-danger.btn-bordered.hover,
    fieldset[disabled] .btn-danger.btn-bordered:hover,
    fieldset[disabled] .btn-danger.btn-bordered:focus,
    fieldset[disabled] .btn-danger.btn-bordered.focus,
    fieldset[disabled] .btn-danger.btn-bordered:active,
    fieldset[disabled] .btn-danger.btn-bordered.active {
      color: #ea4b77;
      background-color: transparent;
      border-color: #ea4b77; }
    .btn-danger.btn-bordered .badge {
      color: #ea4b77;
      background-color: #ea4b77; }

/* -----------------------------------------------------------------------------
 * Buttongrößen
 * -------------------------------------------------------------------------- */
.btn-lg {
  border-radius: 56px;
  padding: 15px 20px;
  height: auto;
  line-height: 1.33333;
  font-size: 1.125rem; }
  .btn-lg.is-rounded {
    border-radius: 18px; }

.btn-sm {
  border-radius: 29px;
  padding: 5px 10px;
  height: auto;
  line-height: 1.25;
  font-size: 0.875rem; }
  .btn-sm.is-rounded {
    border-radius: 18px; }

.btn-xs {
  border-radius: 29px;
  padding: 1px 5px;
  height: auto;
  line-height: 1.125;
  font-size: 0.8125rem; }
  .btn-xs.is-rounded {
    border-radius: 18px; }

/* =============================================================================
 *
 * Abweichende Buttondefintionen
 *
 * -----------------------------------------------------------------------------
 * Defintionen von abweichenden Komponenteneigenschaften.
 * ========================================================================== */
.btn.radio label {
  min-height: 0; }

.btn.radio input[type="radio"],
.btn.radio input[type="checkbox"] {
  margin-top: 1px; }

/**
 * Alerts (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.alert {
  background-color: rgba(128, 127, 127, 0.25);
  border: 2px solid #807f7f;
  color: #807f7f;
  margin: 30px 0;
  padding: 15px 20px;
  position: relative;
  border-radius: 0;
  font-weight: 700; }
  .alert .alert-link,
  .alert a:not(.btn) {
    font-weight: 700;
    text-decoration: underline; }
  .alert .close {
    display: none; }
  .alert-dismissible {
    padding-right: 40px; }
    .alert-dismissible .close {
      display: block;
      position: absolute;
      right: 20px;
      top: 15px; }
  .alert:first-child {
    margin-top: 0; }
  .alert:last-child {
    margin-bottom: 0; }
  li.alert::before {
    display: none; }

.alert-type-list > *::before {
  content: "\f0da";
  font-size: 0.875rem;
  top: .25em;
  color: inherit; }

.alert-type-list > :first-child {
  margin-top: 0; }

.alert-type-list > :last-child {
  margin-bottom: 0; }

.alert-success {
  background-color: rgba(42, 137, 207, 0.25);
  border-color: #2A89CF;
  color: #2A89CF; }
  .alert-success hr {
    border-top-color: #2A89CF; }
  .alert-success .alert-link,
  .alert-success a:not(.btn) {
    color: #2A89CF; }
  .alert-success > .close {
    color: #2A89CF;
    opacity: .4; }
    .alert-success > .close:hover, .alert-success > .close:active, .alert-success > .close:focus {
      opacity: 1; }

.alert-info {
  background-color: rgba(70, 166, 177, 0.25);
  border-color: #46a6b1;
  color: #46a6b1; }
  .alert-info hr {
    border-top-color: #46a6b1; }
  .alert-info .alert-link,
  .alert-info a:not(.btn) {
    color: #46a6b1; }
  .alert-info > .close {
    color: #46a6b1;
    opacity: .4; }
    .alert-info > .close:hover, .alert-info > .close:active, .alert-info > .close:focus {
      opacity: 1; }

.alert-warning {
  background-color: rgba(243, 187, 91, 0.25);
  border-color: #f3bb5b;
  color: #f3bb5b; }
  .alert-warning hr {
    border-top-color: #f3bb5b; }
  .alert-warning .alert-link,
  .alert-warning a:not(.btn) {
    color: #f3bb5b; }
  .alert-warning > .close {
    color: #f3bb5b;
    opacity: .4; }
    .alert-warning > .close:hover, .alert-warning > .close:active, .alert-warning > .close:focus {
      opacity: 1; }

.alert-danger {
  background-color: rgba(234, 75, 119, 0.25);
  border-color: #ea4b77;
  color: #ea4b77; }
  .alert-danger hr {
    border-top-color: #ea4b77; }
  .alert-danger .alert-link,
  .alert-danger a:not(.btn) {
    color: #ea4b77; }
  .alert-danger > .close {
    color: #ea4b77;
    opacity: .4; }
    .alert-danger > .close:hover, .alert-danger > .close:active, .alert-danger > .close:focus {
      opacity: 1; }

.list-alert {
  margin: 0;
  padding: 0; }
  .list-alert > li {
    border-bottom-width: 0;
    border-top-width: 0;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px; }
    .list-alert > li:first-child {
      border-top-width: 2px;
      padding-top: 15px; }
    .list-alert > li:last-child {
      border-bottom-width: 2px;
      padding-bottom: 15px; }
    .list-alert > li::before {
      display: none; }

/**
 * Dropdown (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.dropdown-toggle {
  border-radius: 3px;
  display: block;
  text-transform: none;
  width: 100%; }
  .dropdown-toggle .caret {
    margin: -10px 0 0;
    border: 0 none;
    font-size: 1em;
    right: 10px;
    top: 50%;
    position: absolute; }
    .dropdown-toggle .caret, .dropdown-toggle .caret::after {
      display: block;
      height: 20px;
      width: 20px; }
    .dropdown-toggle .caret::after {
      content: "";
      line-height: 20px; }
  .open .dropdown-toggle.btn-default:hover, .open .dropdown-toggle.btn-default.focus, .open .dropdown-toggle.btn-default:focus {
    background-color: #949393;
    border-color: #949393;
    color: #000; }
  .open .dropdown-toggle.btn-primary:hover, .open .dropdown-toggle.btn-primary.focus, .open .dropdown-toggle.btn-primary:focus {
    background-color: #d2e5f4;
    border-color: #d2e5f4;
    color: white; }
  .btn-group.open .dropdown-toggle {
    box-shadow: none; }

.dropdown-header {
  background-color: #f8f8f8;
  color: #000;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  text-align: center; }
  .dropdown-header > .text {
    display: inline-block;
    padding: 5px 0; }

.dropdown-menu {
  background-color: #fff;
  border: 3px solid #dedcdc;
  border-radius: 3px;
  box-shadow: none;
  color: #000;
  font-style: normal;
  font-size: 0.9375rem;
  margin: 0;
  padding: 0; }
  .dropdown-menu,
  .dropdown-menu > * {
    text-transform: none; }
  .dropdown-menu > li {
    padding-left: 0;
    margin: 0; }
    .dropdown-menu > li::before {
      display: none; }
    .dropdown-menu > li > a {
      color: #000;
      cursor: pointer;
      font-weight: 300;
      line-height: 1;
      padding: 10px 15px;
      white-space: nowrap; }
      .dropdown-menu > li > a:hover, .dropdown-menu > li > a:active, .dropdown-menu > li > a:focus {
        background-color: #f8f8f8;
        color: #000; }
    .dropdown-menu > li.active > a, .dropdown-menu > li.active > a:focus, .dropdown-menu > li.active > a:hover,
    .dropdown-menu > li.selected > a,
    .dropdown-menu > li.selected > a:focus,
    .dropdown-menu > li.selected > a:hover {
      color: #000;
      background-color: #f8f8f8; }
  .dropdown-menu .divider {
    margin: 0; }
  .dropup .dropdown-menu {
    margin-bottom: 0; }
  .open > .btn.dropdown-toggle {
    box-shadow: 0 0 6px rgba(42, 137, 207, 0.3); }
  .open > .dropdown-menu {
    animation-duration: .4s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: dropdown-fade-in;
    animation-timing-function: ease;
    width: 100%; }
    .open > .dropdown-menu.open {
      min-width: 180px; }

/**
 * Navigation (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Standard
 *
 * ========================================================================== */
.nav {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  text-transform: uppercase;
  font-variant: normal; }
  .nav a {
    display: inline-block;
    text-decoration: none;
    position: relative;
    font-weight: 700; }
    .nav a > i {
      padding-right: 10px;
      position: relative; }
    .nav a:hover, .nav a:active, .nav a:focus {
      text-decoration: none; }
  .nav li {
    padding-left: 0;
    position: relative; }
    .nav li::before {
      display: none; }

/* =============================================================================
 *
 * Breadcrumb
 *
 * ========================================================================== */
.nav-breadcrumb * {
  clear: none;
  display: inline;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-transform: none; }

.nav-breadcrumb > .title {
  color: #fff;
  display: inline-block;
  font-weight: 300;
  margin: 0;
  padding-right: 5px;
  font-size: 0.875rem; }
  .nav-breadcrumb > .title::after {
    content: ':'; }

.nav-breadcrumb li {
  display: inline-block;
  padding-right: 5px; }
  .nav-breadcrumb li > * {
    color: #fff;
    font-weight: 300;
    font-size: 0.875rem; }
  .nav-breadcrumb li > a {
    color: #fff; }
    .nav-breadcrumb li > a::after {
      color: #fff;
      content: '/';
      padding-left: 5px; }
    .nav-breadcrumb li > a:hover, .nav-breadcrumb li > a:focus {
      color: #fff; }
      .nav-breadcrumb li > a:hover::after, .nav-breadcrumb li > a:focus::after {
        color: #fff; }
    .nav-breadcrumb li > a.active {
      color: #fff; }
      .nav-breadcrumb li > a.active::after {
        color: #fff; }
  .nav-breadcrumb li:last-child {
    padding-right: 0; }
    .nav-breadcrumb li:last-child a::after {
      display: none; }

/* =============================================================================
 *
 * Step Wizard
 *
 * ========================================================================== */
/**
 * Pagination (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.pagination {
  border-radius: 3px;
  margin: 60px 0;
  padding-left: 70px;
  padding-right: 70px;
  position: relative;
  text-transform: none;
  display: block;
  text-align: center;
  font-size: 1rem; }
  .pagination > li {
    display: inline-block;
    margin: 0 10px 0 0; }
    .nav .pagination > li {
      margin: 0 10px 0 0; }
    .pagination > li > a,
    .pagination > li > span {
      background-color: #f8f8f8;
      border-color: #dedcdc;
      border-radius: 3px;
      border-style: solid;
      border-width: 3px;
      color: #2A89CF;
      margin: 0;
      padding: 5px 10px;
      font-weight: 700;
      line-height: 1; }
      .pagination > li > a > i,
      .pagination > li > span > i {
        padding: 0; }
      .pagination > li > a:hover, .pagination > li > a:focus,
      .pagination > li > span:hover,
      .pagination > li > span:focus {
        color: #2A89CF;
        background-color: #f4f4f4;
        border-color: #dedcdc; }
    .pagination > li:first-child {
      margin-left: 0; }
      .pagination > li:first-child > a,
      .pagination > li:first-child > span {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        margin-left: 0; }
    .pagination > li:last-child {
      margin-right: 0; }
      .pagination > li:last-child > a,
      .pagination > li:last-child > span {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        margin-right: 0; }
    .pagination > li.prev, .pagination > li.next {
      margin-top: -20px;
      position: absolute;
      top: 50%; }
      .pagination > li.prev > a, .pagination > li.next > a {
        background-color: transparent;
        border-color: transparent;
        color: #807f7f;
        font-weight: 700; }
        .pagination > li.prev > a:hover, .pagination > li.prev > a:focus, .pagination > li.next > a:hover, .pagination > li.next > a:focus {
          color: #2A89CF;
          background-color: transparent;
          border-color: transparent; }
    .pagination > li.prev {
      left: 0; }
    .pagination > li.next {
      right: 0; }
  .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    background-color: #f4f4f4;
    border-color: #2A89CF;
    color: #2A89CF;
    cursor: default; }
  .pagination > .disabled {
    opacity: 0.4; }
    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > span:focus,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover,
    .pagination > .disabled > a:focus {
      background-color: transparent;
      border-color: #dedcdc;
      color: #807f7f;
      cursor: default; }
  .nav-pagination {
    margin: 60px 0;
    /*		// (1)(2)
		@if (
			variable-exists(pagination-align) and
			$pagination-align != ''
		) {
			text-align : $pagination-align;

			> .pagination { display : inline-block; }
		}*/ }
    .nav-pagination > .pagination {
      margin: 0;
      padding-left: 70px;
      padding-right: 70px; }

/*
.pagination-lg {
	@include pagination-size($comp-padding-vertical-large, $comp-padding-horizontal-large, $font-size-large, $comp-border-radius-large);
}

.pagination-sm {
	@include pagination-size($comp-padding-vertical-small, $comp-padding-horizontal-small, $font-size-small, $comp-border-radius-small);
}*/
.pager {
  text-transform: none; }
  .pager li > a,
  .pager li > span {
    border-radius: 0;
    padding: 10px 15px;
    background-color: transparent;
    border: 3px solid #4d4c4c;
    color: #807f7f;
    display: inline-block;
    font-size: .875em;
    height: 56px;
    line-height: 56px;
    margin: 0 5px;
    padding-bottom: 0;
    padding-top: 0; }
  .pager li > a:hover, .pager li > a:active, .pager li > a:focus {
    background-color: transparent;
    color: #605f5f; }
  .pager li.pager-info > span {
    border-color: transparent;
    font-weight: 700;
    margin: 0; }
  .pager li:first-child > * {
    margin-left: 0; }
  .pager li:last-child > * {
    margin-right: 0; }
  .pager.left-aligned {
    text-align: left; }
    .pager.left-aligned > * {
      text-align: center; }
  .pager.right-aligned {
    text-align: right; }
    .pager.right-aligned > * {
      text-align: center; }

.pager-icons li {
  overflow: hidden; }
  .pager-icons li > a,
  .pager-icons li > span {
    border: 0 none;
    border-radius: 0;
    overflow: hidden; }
  .pager-icons li.pager-info > * {
    padding-left: 0;
    padding-right: 0; }
  .pager-icons li.pager-prev > *,
  .pager-icons li.pager-next > * {
    padding: 0;
    text-indent: 150%;
    white-space: nowrap;
    width: 56px; }
    .pager-icons li.pager-prev > *::before,
    .pager-icons li.pager-next > *::before {
      bottom: 0;
      font-size: 1.25em;
      left: -2px;
      line-height: 56px;
      position: absolute;
      right: 0;
      text-indent: 0;
      top: -1px;
      width: 56px; }
  .pager-icons li.pager-prev > *::before {
    content: "\f104"; }
  .pager-icons li.pager-next > *::before {
    content: "\f105"; }

/**
 * Video, Audio (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =========================================================================
 *
 * Audio, Video.
 *
 * -------------------------------------------------------------------------
 * Unsichtbar setzen bis der JS-Part zum Zuge kommt. Er stellt die
 * Sichtbarkeit dann wieder her (Vermeidung von unschoenen Browsermeldungen).
 * ====================================================================== */
video {
  background-color: transparent; }

/* -------------------------------------------------------------------------
 * Video
 * ---------------------------------------------------------------------- */
.figure {
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  display: inline-block;
  margin: 15px 0;
  padding: 0;
  position: relative;
  width: 100%; }
  .figure > .figure-media.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .figure > .figure-media.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .figure > .figure-media.embed-responsive-3by4 {
    padding-bottom: 133.33333%; }
  .figure > .figure-media.embed-responsive-3by2 {
    padding-bottom: 66.66667%; }
  .figure > .figure-media.embed-responsive-2by3 {
    padding-bottom: 150%; }
  .figure > .figure-media.embed-responsive-square {
    padding-bottom: 100%; }

.figure-video {
  overflow: hidden;
  /*
	 * Ausrrichtung
	 */ }
  .figure-video.left {
    float: left;
    margin: 0 3% 15px 0;
    width: 47%; }
  .figure-video.right {
    float: right;
    margin: 0 0 15px 3%;
    width: 47%; }

/**
 * Tabs (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.tab {
  display: block;
  z-index: 1; }
  .tab .nav-tabs {
    border-bottom: 0 none;
    display: flex;
    flex-flow: row wrap;
    text-transform: none; }
    .tab .nav-tabs > li {
      flex: 1 0 auto; }
      .tab .nav-tabs > li:last-child {
        margin-right: 0; }
      .tab .nav-tabs > li > a {
        background-color: #f8f8f8;
        border-color: #dedcdc;
        border-radius: 0;
        border-style: solid;
        border-width: 3px 3px 3px 0 !important;
        color: #2A89CF;
        height: 56px;
        line-height: 1.5;
        margin: 0;
        padding: 13px 0;
        text-align: center;
        text-decoration: none;
        font-weight: 700; }
        .tab .nav-tabs > li > a:hover, .tab .nav-tabs > li > a:focus {
          background-color: #f8f8f8;
          border-color: #dedcdc;
          color: #92C5EB;
          text-decoration: none; }
      .tab .nav-tabs > li:first-child > a {
        border-bottom-left-radius: 0;
        border-left: 3px solid #dedcdc !important;
        border-top-left-radius: 0; }
      .tab .nav-tabs > li:last-child > a {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
      .tab .nav-tabs > li.active > a, .tab .nav-tabs > li.active > a:hover, .tab .nav-tabs > li.active > a:focus {
        background-color: #fff;
        border-color: #dedcdc;
        border-width: 3px;
        border-style: solid;
        color: #92C5EB;
        cursor: default;
        text-decoration: none; }
  .tab .tab-pane-content {
    border: 3px solid #dedcdc;
    border-top: 0 none;
    padding: 10px 15px; }
  .tab-to-collapse .toggle-tab-collapse {
    display: none; }

/**
 * Tabellen (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Tabellenstandards
 * -------------------------------------------------------------------------- */
caption {
  padding: 10px 15px;
  color: #f4f4f4;
  text-align: left; }

table {
  background-color: transparent;
  margin: 30px 0;
  max-width: 100%;
  width: 100%; }
  table th,
  table td {
    border: 0;
    color: #000;
    line-height: 1.5;
    padding: 10px 15px;
    text-align: left;
    vertical-align: top; }
  table > thead > tr > th,
  table > thead > tr > td {
    color: #2A89CF;
    font-weight: 700;
    text-align: left;
    text-transform: none;
    font-size: 1.125rem; }
  table > tbody > tr > th {
    color: #2A89CF;
    font-weight: 400;
    text-align: left; }
  table table {
    background-color: #fff; }

/* -----------------------------------------------------------------------------
 * @Tabelle '.table'
 * -------------------------------------------------------------------------- */
.table > thead > tr > th,
.table > thead > tr > td {
  background-color: rgba(146, 197, 235, 0.2);
  border-bottom: 3px solid white;
  border-top: 0 none;
  color: #92C5EB;
  padding: 10px 15px; }

.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  border-bottom: 1px solid #c5c2c2;
  border-top: 0 none;
  padding: 10px 15px; }

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 1px solid #c5c2c2; }

/* -----------------------------------------------------------------------------
 * @Tabelle '.table-condensed'
 * -------------------------------------------------------------------------- */
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px 15px; }

/* -----------------------------------------------------------------------------
 * @Bordered
 * -------------------------------------------------------------------------- */
.table-bordered {
  border: 1px solid #dedcdc;
  /*	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td { border : $table-bordered-border; }
		}
	}

	> thead > tr {
		> th,
		> td { border-bottom-width : 1px; }
	}*/ }

/* -----------------------------------------------------------------------------
 * @Zebra
 * -------------------------------------------------------------------------- */
.table-striped > tbody > tr:nth-child(even) {
  background-color: #dedcdc; }

.table-striped > tbody > tr:nth-child(odd) {
  background-color: #f4f4f4; }

/* -----------------------------------------------------------------------------
 * @Tabelle '.table-blank'
 * -------------------------------------------------------------------------- */
.table-blank > thead > tr,
.table-blank > tbody > tr,
.table-blank > tfoot > tr {
  background-color: transparent;
  border: 0 none; }
  .table-blank > thead > tr > th,
  .table-blank > thead > tr > td,
  .table-blank > tbody > tr > th,
  .table-blank > tbody > tr > td,
  .table-blank > tfoot > tr > th,
  .table-blank > tfoot > tr > td {
    background-color: transparent;
    border: 0 none;
    padding: 0; }

/* -----------------------------------------------------------------------------
 * @Hover
 * -------------------------------------------------------------------------- */
.table-hover > tbody > tr:hover {
  background-color: #fff; }

/* -----------------------------------------------------------------------------
 * @Hintergrundfarben
 * -------------------------------------------------------------------------- */
/*
 * Kontextbezogene Varianten
 */
table > thead > tr > td.active,
table > thead > tr > th.active,
table > thead > tr.active > td,
table > thead > tr.active > th,
table > tbody > tr > td.active,
table > tbody > tr > th.active,
table > tbody > tr.active > td,
table > tbody > tr.active > th,
table > tfoot > tr > td.active,
table > tfoot > tr > th.active,
table > tfoot > tr.active > td,
table > tfoot > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  color: #f4f4f4;
  background-color: #f4f4f4; }

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  color: #e7e7e7;
  background-color: #e7e7e7; }

table > thead > tr > td.success,
table > thead > tr > th.success,
table > thead > tr.success > td,
table > thead > tr.success > th,
table > tbody > tr > td.success,
table > tbody > tr > th.success,
table > tbody > tr.success > td,
table > tbody > tr.success > th,
table > tfoot > tr > td.success,
table > tfoot > tr > th.success,
table > tfoot > tr.success > td,
table > tfoot > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  color: #2A89CF;
  background-color: #d0e5f5; }

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  color: #267bba;
  background-color: #badaf1; }

table > thead > tr > td.info,
table > thead > tr > th.info,
table > thead > tr.info > td,
table > thead > tr.info > th,
table > tbody > tr > td.info,
table > tbody > tr > th.info,
table > tbody > tr.info > td,
table > tbody > tr.info > th,
table > tfoot > tr > td.info,
table > tfoot > tr > th.info,
table > tfoot > tr.info > td,
table > tfoot > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  color: #46a6b1;
  background-color: #c2e3e7; }

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  color: #3f959f;
  background-color: #b0dbe0; }

table > thead > tr > td.warning,
table > thead > tr > th.warning,
table > thead > tr.warning > td,
table > thead > tr.warning > th,
table > tbody > tr > td.warning,
table > tbody > tr > th.warning,
table > tbody > tr.warning > td,
table > tbody > tr.warning > th,
table > tfoot > tr > td.warning,
table > tfoot > tr > th.warning,
table > tfoot > tr.warning > td,
table > tfoot > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  color: #f3bb5b;
  background-color: white; }

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  color: #f1b143;
  background-color: #f2f2f2; }

table > thead > tr > td.danger,
table > thead > tr > th.danger,
table > thead > tr.danger > td,
table > thead > tr.danger > th,
table > tbody > tr > td.danger,
table > tbody > tr > th.danger,
table > tbody > tr.danger > td,
table > tbody > tr.danger > th,
table > tfoot > tr > td.danger,
table > tfoot > tr > th.danger,
table > tfoot > tr.danger > td,
table > tfoot > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  color: #ea4b77;
  background-color: white; }

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  color: #e73466;
  background-color: #f2f2f2; }

/* -----------------------------------------------------------------------------
 * Scrollbare Tabelle
 * -----------------------------------------------------------------------------
 * Tabelle ist scrollbar auf mobilen Geraeten bzw. auf kleineren Displays.
 * -------------------------------------------------------------------------- */
.table-scrollable {
  background-color: transparent;
  margin: 30px 0;
  max-width: 100%;
  width: 100%;
  position: relative; }
  .table-scrollable table {
    margin: 0; }
  .table-scrollable .table-scrollable-wrap {
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto; }

/**
 * Listen (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
ul,
ol {
  border: 0 none;
  list-style: none;
  margin: 0;
  padding: 0; }
  ul li,
  ol li {
    border: 0 none;
    list-style: none;
    margin: 0;
    padding: 0; }

dl {
  border: 0 none;
  list-style: none;
  margin: 0;
  padding: 0; }
  dl dt,
  dl dd {
    border: 0 none;
    margin: 0;
    padding: 0; }

ul,
ul ul,
ul ol,
ul dl,
ol,
ol ul,
ol ol,
ol dl,
dl,
dl ul,
dl ol,
dl dl {
  margin: 20px 0;
  padding: 0; }

/*
 * Listentyp der un- und geordneten Liste uberschreiben.
 */
ul > li,
ol > li {
  margin: 5px 0;
  padding-left: 20px;
  position: relative; }
  ul > li::before,
  ol > li::before {
    color: #92C5EB;
    left: 0;
    position: absolute;
    top: -0.0625em; }

ul > li::before {
  content: "\f105";
  font-size: 1.375rem; }

ol > li {
  counter-increment: step-counter;
  padding-left: 25px; }
  ol > li::before {
    content: counter(step-counter);
    font-family: Arial, Helvetica, sans-serif;
    font-size: inherit;
    font-weight: 400;
    top: 0; }

/* =============================================================================
 *
 * Bootstrap Listen
 *
 * -----------------------------------------------------------------------------
 * Siehe http://getbootstrap.com/css/#type-lists
 * ========================================================================== */
/* -----------------------------------------------------------------------------
 * Unstyled (Bootstrap Defaults)
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Inline (Bootstrap Defaults)
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Horizontal description
 * -------------------------------------------------------------------------- */
/* =============================================================================
 *
 * Eigene Listen
 *
 * ========================================================================== */
.list-clean {
  border: 0 none;
  list-style: none;
  margin: 0;
  padding: 0; }
  .list-clean li {
    border: 0 none;
    list-style: none;
    margin: 0;
    padding: 0; }
  .list-clean > li::before {
    display: none; }

.list-inline > li::before {
  display: none; }

.list-blank > li {
  padding-left: 0; }
  .list-blank > li::before {
    display: none; }

/* -----------------------------------------------------------------------------
 * Social-Icons
 * -----------------------------------------------------------------------------
 * Verwendung Showcase, Seitenuss.
 * -------------------------------------------------------------------------- */
.list-social-icons {
  text-align: center; }
  .list-social-icons,
  .list-social-icons * {
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin: 0; }
  .list-social-icons > li {
    display: inline-block;
    margin: 0 15px;
    width: 36px; }
    .list-social-icons > li a {
      background-color: #2A89CF;
      border-radius: 50%;
      color: #fff;
      display: block;
      position: relative;
      text-align: center;
      text-indent: 150%;
      white-space: nowrap; }
      .list-social-icons > li a,
      .list-social-icons > li a > i,
      .list-social-icons > li a > i::before {
        display: block;
        height: 36px;
        overflow: hidden;
        width: 36px; }
      .list-social-icons > li a > i {
        font-size: 1.375rem;
        left: 0;
        position: absolute;
        text-indent: initial;
        top: 0; }
        .list-social-icons > li a > i::before {
          line-height: 37px; }
        .list-social-icons > li a > i.fa-xing::before, .list-social-icons > li a > i.fa-facebook::before {
          padding-right: 3px; }
      .list-social-icons > li a:hover {
        background-color: #92C5EB; }
    .list-social-icons > li:first-child {
      margin-left: 0; }
    .list-social-icons > li:last-child {
      margin-right: 0; }

/* -----------------------------------------------------------------------------
 * Liste mit Links
 * -------------------------------------------------------------------------- */
.list-links > li, .list-menu > li {
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 0;
  margin-top: 0;
  padding: 15px 0; }
  .list-links > li:last-child, .list-menu > li:last-child {
    border-bottom: 0; }
  .list-links > li::before, .list-menu > li::before {
    display: none; }

.list-links a, .list-menu a,
.list-links a.link,
.list-menu a.link,
.list-links span.link,
.list-menu span.link {
  color: #605f5f;
  display: block;
  font-weight: 400;
  position: relative;
  padding-left: 0;
  padding-right: 30px; }
  .list-links a::before, .list-menu a::before,
  .list-links a.link::before,
  .list-menu a.link::before,
  .list-links span.link::before,
  .list-menu span.link::before {
    position: absolute;
    right: 0;
    text-align: center;
    top: 4px;
    width: 30px; }
  .list-links a:hover, .list-menu a:hover, .list-links a:active, .list-menu a:active, .list-links a:focus, .list-menu a:focus,
  .list-links a.link:hover,
  .list-menu a.link:hover,
  .list-links a.link:active,
  .list-menu a.link:active,
  .list-links a.link:focus,
  .list-menu a.link:focus,
  .list-links span.link:hover,
  .list-menu span.link:hover,
  .list-links span.link:active,
  .list-menu span.link:active,
  .list-links span.link:focus,
  .list-menu span.link:focus {
    color: #92C5EB;
    text-decoration: none; }

.list-menu a,
.list-menu a.link,
.list-menu span.link {
  padding-left: 30px;
  padding-right: 0; }
  .list-menu a::before,
  .list-menu a.link::before,
  .list-menu span.link::before {
    content: "\f105" !important;
    right: auto;
    left: 0; }

/* -----------------------------------------------------------------------------
 * Liste mit Rahmen
 * -------------------------------------------------------------------------- */
.list-bordered {
  border: 1px solid #f8f8f8;
  border-top: 0 none; }
  .list-bordered > li::before {
    display: none; }
  .list-bordered > li,
  .list-bordered > dt,
  .list-bordered > dd {
    border-top: 1px solid #f8f8f8;
    margin: 0;
    padding: 15px 20px; }

/* -----------------------------------------------------------------------------
 * Liste Spaltigkeit
 * -------------------------------------------------------------------------- */
.list-col {
  margin-left: -15px;
  margin-right: -15px; }
  .list-col > li {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 50%; }
    .list-col > li::before {
      display: none; }
    .list-col > li:nth-child(2n+1) {
      clear: both; }
  .list-col-3 > li {
    width: 33.3333333333%; }
    .list-col-3 > li:nth-child(2n+1) {
      clear: none; }
    .list-col-3 > li:nth-child(3n+1) {
      clear: both; }
  .list-col-4 > li {
    width: 25%; }
    .list-col-4 > li:nth-child(2n+1) {
      clear: none; }
    .list-col-4 > li:nth-child(4n+1) {
      clear: both; }

/* -----------------------------------------------------------------------------
 * Liste (Standard-)Flex
 * -------------------------------------------------------------------------- */
.list-flex {
  display: flex;
  flex-flow: row wrap; }
  .list-flex > li {
    flex: 1 0 auto;
    padding-left: 0; }
    .list-flex > li::before {
      display: none; }

/* -----------------------------------------------------------------------------
 * Tabellenliste (dt = zeilenkopf, dd = Zeileninhalt)
 * -------------------------------------------------------------------------- */
.list-table > * {
  border-top: 1px solid #dedcdc;
  float: left;
  margin: 0;
  padding: 15px 0; }
  .list-table > *:nth-child(1), .list-table > *:nth-child(2) {
    border-top: 0; }

.list-table > li::before {
  content: '';
  display: none; }

.list-table > dt,
.list-table > li:nth-child(2n+1) {
  clear: both;
  font-weight: 400;
  padding-right: 15px;
  width: 33%; }

.list-table > dd,
.list-table > li:nth-child(2n+2) {
  width: 66%; }

/**
 * Listengruppen (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Listengruppe 'Checkboxen, Radio'
 *
 * ========================================================================== */
.list-group-checkbox-radio {
  padding-left: 15px;
  padding-right: 15px; }
  .list-group-checkbox-radio p {
    margin: 0; }
  .list-group-checkbox-radio .list-group-item {
    border-left: 0 none;
    border-right: 0 none;
    border-top: 0 none;
    border-color: #dedcdc;
    margin-bottom: 0;
    padding: 15px 15px; }
    .list-group-checkbox-radio .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-checkbox-radio .list-group-item:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
  .list-group-checkbox-radio .checkbox,
  .list-group-checkbox-radio .radio {
    display: block;
    min-height: 0;
    padding-top: 0; }
    .list-group-checkbox-radio .checkbox label,
    .list-group-checkbox-radio .radio label {
      display: block; }
  .list-group-checkbox-radio.has-grid .list-group-item {
    padding-left: 0;
    padding-right: 0; }

/**
 * Responsive Einbindungen (Komponente)
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.embed-responsive,
.aspect-ratio {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive > *:first-child,
  .aspect-ratio > *:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .embed-responsive > img:first-child,
  .aspect-ratio > img:first-child {
    height: auto; }
  .embed-responsive-16by9,
  .aspect-ratio-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive-4by3,
  .aspect-ratio-4by3 {
    padding-bottom: 75%; }
  .embed-responsive-3by4,
  .aspect-ratio-3by4 {
    padding-bottom: 133.33333%; }
  .embed-responsive-3by2,
  .aspect-ratio-3by2 {
    padding-bottom: 66.66667%; }
  .embed-responsive-2by3,
  .aspect-ratio-2by3 {
    padding-bottom: 150%; }
  .embed-responsive-square,
  .aspect-ratio-square {
    padding-bottom: 100%; }

*[data-ar] {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  *[data-ar] > *:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  *[data-ar] > img:first-child {
    height: auto; }

*[data-ar="16by9"] {
  padding-bottom: 56.25%; }

*[data-ar="4by3"] {
  padding-bottom: 75%; }

*[data-ar="3by4"] {
  padding-bottom: 133.33333%; }

*[data-ar="3by2"] {
  padding-bottom: 66.66667%; }

*[data-ar="2by3"] {
  padding-bottom: 150%; }

*[data-ar="1by1"] {
  padding-bottom: 100%; }

/**
 * Panels (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.panel {
  margin-bottom: 24px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 3px;
  box-shadow: none; }
  .panel-body {
    color: #000;
    padding: 15px; }
  .panel-heading {
    color: #000;
    background-color: #dedcdc;
    border-bottom: 2px solid transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-weight: 400;
    padding: 10px 15px; }
  .panel-title {
    font-size: inherit;
    /*		color     : inherit;

		> a,
		> small,
		> .small,
		> small > a,
		> .small > a {
			color : inherit;
		}*/ }
  .panel-footer {
    background-color: #dedcdc;
    border-top: 2px solid transparent;
    color: #000;
    padding: 10px 15px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }

/* -----------------------------------------------------------------------------
 * Collapsable panels (aka, accordion)
 * -----------------------------------------------------------------------------
 * Wrap a series of panels in `.panel-group` to turn them into an accordion with
 * the help of our collapse JavaScript plugin.
 * -------------------------------------------------------------------------- */
.panel-group .panel {
  border-radius: 3px; }

.panel-group .panel-heading {
  border-bottom: 0; }
  .panel-group .panel-heading + .panel-collapse > .panel-body,
  .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: 2px solid transparent; }

.panel-group .panel-footer {
  border-top: 0; }
  .panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 2px solid transparent; }

/* -----------------------------------------------------------------------------
 * Kontextabhaengige Varianten.
 * -------------------------------------------------------------------------- */
.panel-default {
  border-color: #f4f4f4; }
  .panel-default > .panel-heading {
    color: #b3b2b2;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #f4f4f4; }
    .panel-default > .panel-heading .badge {
      color: #f4f4f4;
      background-color: #b3b2b2; }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #f4f4f4; }

.panel-primary {
  border-color: #92C5EB; }
  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #92C5EB;
    border-color: #92C5EB; }
    .panel-primary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #92C5EB; }
    .panel-primary > .panel-heading .badge {
      color: #92C5EB;
      background-color: #fff; }
  .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #92C5EB; }

.panel-secondary {
  border-color: #2A89CF; }
  .panel-secondary > .panel-heading {
    color: #fff;
    background-color: #2A89CF;
    border-color: #2A89CF; }
    .panel-secondary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #2A89CF; }
    .panel-secondary > .panel-heading .badge {
      color: #2A89CF;
      background-color: #fff; }
  .panel-secondary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #2A89CF; }

.panel-tertiary {
  border-color: #2A89CF; }
  .panel-tertiary > .panel-heading {
    color: #fff;
    background-color: #2A89CF;
    border-color: #2A89CF; }
    .panel-tertiary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #2A89CF; }
    .panel-tertiary > .panel-heading .badge {
      color: #2A89CF;
      background-color: #fff; }
  .panel-tertiary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #2A89CF; }

.panel-success {
  border-color: #a5ceed; }
  .panel-success > .panel-heading {
    color: #2A89CF;
    background-color: #a5ceed;
    border-color: #a5ceed; }
    .panel-success > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #a5ceed; }
    .panel-success > .panel-heading .badge {
      color: #a5ceed;
      background-color: #2A89CF; }
  .panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #a5ceed; }

.panel-info {
  border-color: #b0dbe0; }
  .panel-info > .panel-heading {
    color: #46a6b1;
    background-color: #b0dbe0;
    border-color: #b0dbe0; }
    .panel-info > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #b0dbe0; }
    .panel-info > .panel-heading .badge {
      color: #b0dbe0;
      background-color: #46a6b1; }
  .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #b0dbe0; }

.panel-warning {
  border-color: #fdf6ea; }
  .panel-warning > .panel-heading {
    color: #f3bb5b;
    background-color: #fdf6ea;
    border-color: #fdf6ea; }
    .panel-warning > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #fdf6ea; }
    .panel-warning > .panel-heading .badge {
      color: #fdf6ea;
      background-color: #f3bb5b; }
  .panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #fdf6ea; }

.panel-danger {
  border-color: #fad4df; }
  .panel-danger > .panel-heading {
    color: #ea4b77;
    background-color: #fad4df;
    border-color: #fad4df; }
    .panel-danger > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #fad4df; }
    .panel-danger > .panel-heading .badge {
      color: #fad4df;
      background-color: #ea4b77; }
  .panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #fad4df; }

/**
 * IFrame (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
iframe {
  border: 0;
  height: 100%;
  width: 100%; }

/**
 * Wells (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.well {
  padding: 15px;
  margin-bottom: 15px;
  background-color: transparent;
  border: 2px solid #f8f8f8;
  border-radius: 0;
  box-shadow: none;
  /* TODO: Wells > Blockquote noch offen
	blockquote {
		border-color : #ddd;
		border-color : rgba(0, 0, 0, .15);
	}*/ }
  .well > :first-child {
    margin-top: 0; }
  .well > :last-child {
    margin-bottom: 0; }
  .well-lg {
    border-radius: 0;
    margin: 20px;
    padding: 20px; }
  .well-sm {
    border-radius: 0;
    margin: 10px;
    padding: 10px; }
  .well h1,
  .well h2,
  .well h3,
  .well h4,
  .well h5,
  .well h6 {
    border-bottom: 0;
    font-size: 1rem;
    font-weight: 700;
    margin: 10px 0;
    padding-bottom: 0; }
  .well h1,
  .well h2 {
    font-size: 1.5625rem;
    font-weight: 400; }
  .well h3 {
    font-size: 1.125rem;
    font-weight: 400; }

.well-scrollable {
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto; }
  .well-scrollable-lg {
    height: 250px; }
  .well-scrollable-sm {
    height: 170px; }

/**
 * Visitenkarten (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* -----------------------------------------------------------------------------
 * Single Vcard
 * -------------------------------------------------------------------------- */
.vcard {
  display: block;
  font-size: 1rem;
  max-width: 290px;
  padding: 10px;
  border: 3px solid #dedcdc;
  border-radius: 0;
  overflow: hidden;
  background-color: #fff;
  color: #000; }
  .vcard-inner {
    display: flex;
    flex-flow: column nowrap; }
    .vcard-inner > * {
      flex: 1 0 auto;
      overflow: hidden; }

.vcard-image {
  background-color: #dedcdc; }
  .vcard-image > .thumbnail {
    margin: 0;
    display: block; }
  .vcard-image.is-empty > .thumbnail {
    opacity: .46; }
  .vcard-image + .vcard-data {
    margin-top: 10px; }

.vcard-data > * {
  margin: 0 0 10px; }
  .vcard-data > *:last-child {
    margin-bottom: 0; }

.vcard-data a {
  display: block; }

.vcard-data .name {
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: none; }
  .vcard-data .name + .function {
    margin-top: 5px; }

.vcard-data .function {
  color: #000;
  font-size: 1.0625rem;
  font-weight: 300;
  text-transform: none; }

.vcard-data .address {
  padding-top: 10px; }

.vcard-data .reachable {
  padding-top: 10px; }
  .vcard-data .reachable > .network {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 10px 0 0; }

.vcard-data > *:first-child {
  margin-top: 0; }

.vcard-data > *:last-child {
  margin-bottom: 0; }

/* -----------------------------------------------------------------------------
 * Vcard-Gruppe
 * -------------------------------------------------------------------------- */
.vcard-group {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px !important; }
  .vcard-group > * {
    float: left;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
  .vcard-group > .vcard-group-title {
    clear: both;
    width: 100%; }
  .vcard-group > .vcard-group-item {
    float: left;
    width: 50%; }
  .vcard-group > .vcard-group-title:first-child {
    margin-top: 0; }
  .vcard-group .vcard {
    height: 100%;
    margin: 0 !important;
    max-width: none; }
  .aside .vcard-group > .vcard-group-item {
    float: none;
    width: 100%; }

/**
 * Collapse (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.collapse {
  /* -------------------------------------------------------------------------
	 * Zuweisungen der Eigenschaften/Defintionen.
	 * ---------------------------------------------------------------------- */
  display: block;
  z-index: 1; }
  .collapse-toggle {
    background-color: #f8f8f8;
    border: 3px solid #dedcdc;
    border-radius: 3px;
    color: #2A89CF;
    cursor: pointer;
    display: block;
    margin: 15px 0 0;
    line-height: 1.5;
    outline: 0 !important;
    padding: 0;
    position: relative;
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: 700; }
    .collapse-toggle > * {
      display: block;
      padding: 10px 15px;
      padding-left: 50px;
      text-decoration: none; }
    .collapse-toggle::before {
      left: 10px;
      border-radius: 0;
      bottom: 0;
      color: #2A89CF;
      content: "";
      font-size: 0.9375em;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      position: absolute;
      text-align: center;
      top: 50%;
      margin-top: -15px;
      width: 30px; }
    .collapse-toggle:hover, .collapse-toggle:focus {
      border-color: #dedcdc;
      color: #92C5EB; }
      .collapse-toggle:hover > *, .collapse-toggle:focus > * {
        background-color: #f8f8f8;
        color: #92C5EB;
        text-decoration: none; }
      .collapse-toggle:hover::before, .collapse-toggle:focus::before {
        color: #92C5EB;
        content: ""; }
    .collapse-toggle:active, .collapse-toggle.active, .collapse-toggle.collapse-toggle-active {
      border-color: #dedcdc;
      color: #92C5EB; }
      .collapse-toggle:active > *, .collapse-toggle.active > *, .collapse-toggle.collapse-toggle-active > * {
        background-color: #f8f8f8;
        color: #92C5EB;
        text-decoration: none; }
      .collapse-toggle:active::before, .collapse-toggle.active::before, .collapse-toggle.collapse-toggle-active::before {
        color: #92C5EB;
        content: "";
        line-height: 28px;
        padding-left: 0; }
    .collapse-toggle:first-child {
      margin-top: 0; }
  .collapse-panel {
    display: none; }
    .collapse-panel-content {
      border: 3px solid #f8f8f8;
      border-top: 0 none;
      padding: 15px 15px 0px; }

/**
 * Modals (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Modal: Standard
 *
 * ========================================================================== */
.modal-dialog {
  margin: 0 auto;
  max-width: 960px;
  padding: 20px;
  position: relative;
  width: auto; }
  .modal-dialog > * {
    z-index: 1; }
  .modal-dialog > .close {
    bottom: auto;
    color: #2A89CF;
    display: block;
    height: 30px;
    left: auto;
    margin-top: 0;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    z-index: 10; }

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.8); }

/* -----------------------------------------------------------------------------
 * Kopfzeile
 * -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------
 * Inhalt
 * -------------------------------------------------------------------------- */
.modal-content {
  background-color: #fff;
  border: 0 none;
  border-radius: 0;
  box-shadow: none; }

.modal-body {
  padding: 15px 15px; }

/* -----------------------------------------------------------------------------
 * Fusszeile
 * -------------------------------------------------------------------------- */
/* =============================================================================
 *
 * Modal: Special
 *
 * -----------------------------------------------------------------------------
 * Benoetigt wird ein zweiter 'modal-content'-Block fuer die Anzeige des
 * Ladestatus.
 *
 * <div class="modal-content modal-content-loading">
 * 		<div class="modal-header">
 * 			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
 * 				<span aria-hidden="true"><i class="fa fa-close"></i></span>
 * 			</button>
 * 		</div>
 * 		<span class="indicator"></span>
 * 	</div>
 * ========================================================================== */
.modal-special .modal-header {
  display: none; }

.modal-special .modal-body {
  z-index: 1; }

.modal-special .modal-title {
  display: none; }

.modal-special .modal-content-loading {
  display: none;
  padding: 15px 15px;
  position: relative;
  text-align: center; }
  .modal-special .modal-content-loading .indicator {
    display: block;
    position: relative;
    width: 100%; }

.modal-special.is-loading .modal-content {
  display: none; }

.modal-special.is-loading .modal-content-loading {
  display: block; }

.modal-special-iframe .modal-dialog,
.modal-special-iframe .modal-content,
.modal-special-iframe .modal-body,
.modal-special-iframe .modal-body > iframe {
  height: 100%; }

.modal-special-iframe .modal-dialog > .close {
  font-size: 2.5em;
  height: 40px;
  line-height: 40px;
  right: 60px;
  top: 30px;
  width: 40px; }

/**
 * Tooltipps (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Popover (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.popover {
  color: #fff;
  background-color: #46a6b1;
  border: 0 none transparent;
  border-radius: 4px;
  box-shadow: none;
  font-size: 0.875rem;
  max-width: 250px; }
  .popover.fade.in {
    opacity: 1; }
  .popover.top {
    margin-top: -5px; }
  .popover.right {
    margin-left: 5px; }
  .popover.bottom {
    margin-top: 5px; }
  .popover.left {
    margin-left: -5px; }
  .popover .popover-title {
    border-bottom: 0 none transparent;
    border-radius: 4px 4px 0 0;
    padding: 3px 8px;
    text-align: center; }
  .popover .popover-content {
    padding: 3px 8px; }
  .popover > .arrow {
    border-width: 5px; }
    .popover > .arrow:after {
      border-width: 5px; }

/**
 * Ausrichtungen
 */
.popover.top > .arrow {
  margin-left: -5px;
  border-top-color: transparent;
  bottom: -5px; }
  .popover.top > .arrow:after {
    margin-left: -5px;
    border-top-color: #46a6b1; }

.popover.right > .arrow {
  left: -5px;
  margin-top: -5px;
  border-right-color: transparent; }
  .popover.right > .arrow:after {
    bottom: -5px;
    border-right-color: transparent; }

.popover.bottom > .arrow {
  margin-left: -5px;
  top: -5px;
  border-bottom-color: transparent; }
  .popover.bottom > .arrow:after {
    margin-left: -5px;
    border-bottom-color: transparent; }

.popover.left > .arrow {
  margin-top: -5px;
  right: -5px;
  border-left-color: transparent; }
  .popover.left > .arrow:after {
    bottom: -5px;
    border-left-color: transparent; }

/**
 * Alternative Stile
 */
.popover-primary {
  background-color: #92C5EB;
  border-color: #92C5EB;
  color: #fff; }
  .popover-primary.fade.in {
    opacity: 1; }
  .popover-primary.top > .arrow, .popover-primary.top > .arrow:after {
    border-top-color: #92C5EB; }
  .popover-primary.right > .arrow, .popover-primary.right > .arrow:after {
    border-right-color: #92C5EB; }
  .popover-primary.bottom > .arrow, .popover-primary.bottom > .arrow:after {
    border-bottom-color: #92C5EB; }
  .popover-primary.left > .arrow, .popover-primary.left > .arrow:after {
    border-left-color: #92C5EB; }
  .popover-primary .popover-title {
    background-color: #92C5EB;
    border-color: #92C5EB;
    color: #fff; }

.popover-secondary {
  background-color: #2A89CF;
  border-color: #2A89CF;
  color: #fff; }
  .popover-secondary.fade.in {
    opacity: 1; }
  .popover-secondary.top > .arrow, .popover-secondary.top > .arrow:after {
    border-top-color: #2A89CF; }
  .popover-secondary.right > .arrow, .popover-secondary.right > .arrow:after {
    border-right-color: #2A89CF; }
  .popover-secondary.bottom > .arrow, .popover-secondary.bottom > .arrow:after {
    border-bottom-color: #2A89CF; }
  .popover-secondary.left > .arrow, .popover-secondary.left > .arrow:after {
    border-left-color: #2A89CF; }
  .popover-secondary .popover-title {
    background-color: #2A89CF;
    border-color: #2A89CF;
    color: #fff; }

.popover-tertiary {
  background-color: #2A89CF;
  border-color: #2A89CF;
  color: #fff; }
  .popover-tertiary.fade.in {
    opacity: 1; }
  .popover-tertiary.top > .arrow, .popover-tertiary.top > .arrow:after {
    border-top-color: #2A89CF; }
  .popover-tertiary.right > .arrow, .popover-tertiary.right > .arrow:after {
    border-right-color: #2A89CF; }
  .popover-tertiary.bottom > .arrow, .popover-tertiary.bottom > .arrow:after {
    border-bottom-color: #2A89CF; }
  .popover-tertiary.left > .arrow, .popover-tertiary.left > .arrow:after {
    border-left-color: #2A89CF; }
  .popover-tertiary .popover-title {
    background-color: #2A89CF;
    border-color: #2A89CF;
    color: #fff; }

/**
 * Galerien (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.gallery {
  margin-bottom: 15px;
  margin-top: 15px;
  position: relative; }
  .gallery .thumbnail, .gallery .thumbnail.comp,
  .gallery .thumbnail > .image {
    display: block;
    margin: 0;
    overflow: hidden;
    width: 100%; }
    .gallery .thumbnail > img, .gallery .thumbnail.comp > img,
    .gallery .thumbnail > .image > img {
      display: none; }
  .gallery .thumbnail > picture.image > img {
    display: block; }
  .gallery .thumbnail > .caption {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    left: 0;
    padding: 5px 10px;
    position: absolute;
    right: 0; }

/* =============================================================================
 *
 * Galerieliste
 *
 * ========================================================================== */
.gallery-list {
  margin-bottom: 15px;
  margin-top: 15px; }
  .gallery-list .thumbnail {
    display: block;
    margin: 0;
    overflow: hidden; }
  .gallery-list > .item {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center; }
    .gallery-list > .item::before {
      content: '';
      display: none; }

/**
 * Googlemaps (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.googlemap {
  background-color: #f2f2f2;
  backface-visibility: hidden;
  /* Fix Render-Visibility Chrome */
  display: block;
  height: 350px;
  margin: 0;
  width: auto; }
  .googlemap[data-ar] {
    height: auto; }
  .googlemap::before, .googlemap::after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 5; }
  .googlemap::after {
    left: auto;
    right: 0; }
  .googlemap-map {
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1; }
    .googlemap-map .gmap-marker-text {
      font-family: Arial, Helvetica, Arial, sans-serif;
      font-size: 12px;
      line-height: normal; }
      .googlemap-map .gmap-marker-text .title {
        color: #2A89CF;
        font-weight: bold;
        margin: 0;
        padding-bottom: 5px; }
      .googlemap-map .gmap-marker-text .text {
        margin: 0; }
  .is-mobile .googlemap::before, .is-mobile .googlemap::after {
    display: block; }

/**
 * Artikel (Komponente)
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.article {
  position: relative;
  /*
	 * Abstaende (Margin) in Bezug auf erste und letzte Kindelemente anpassen.
	 */ }
  .article .title {
    margin-top: 0;
    overflow: hidden; }
  .article .edited,
  .article .datetime {
    color: #2A89CF;
    font-size: 0.875rem;
    display: none; }
  .article .edited > .datetime {
    display: inline;
    font-size: 1em; }
  .article > .article-header {
    background-color: #f4f4f4;
    margin-bottom: 40px;
    padding: 15px; }
    .article > .article-header > * {
      margin-top: 0; }
    .article > .article-header .edited {
      color: #807f7f; }
    .article > .article-header .subtitle {
      color: #2A89CF;
      font-size: 1.125rem;
      font-weight: 700;
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      line-height: 1.25em;
      margin-bottom: 0; }
    .article > .article-header > :last-child {
      margin-bottom: 0; }
  .article > .article-footer {
    display: none; }
  .article > .article-header .datetime, .article > .article-footer .datetime {
    display: none; }
  .article > .article-body > *:not(.gallery-list) > :first-child, .article > .article-body > *:not(.gallery-list):not(.gallery-list) > :first-child,
  .article > .article-body > .article-body-section > *:not(.gallery-list) > :first-child,
  .article > .article-body > .article-body-section > *:not(.gallery-list):not(.gallery-list) > :first-child {
    margin-top: 0; }
  .article > .article-body > *:not(.gallery-list) > :last-child, .article > .article-body > *:not(.gallery-list):not(.gallery-list) > :last-child,
  .article > .article-body > .article-body-section > *:not(.gallery-list) > :last-child,
  .article > .article-body > .article-body-section > *:not(.gallery-list):not(.gallery-list) > :last-child {
    margin-bottom: 0; }
  .article > :first-child,
  .article > .article-header > :first-child,
  .article > .article-body > :first-child,
  .article > .article-body .article-body-section-wrap > :first-child,
  .article > .article-footer > :first-child {
    margin-top: 0; }
  .article > :last-child,
  .article > .article-header > :last-child,
  .article > .article-body > :last-child,
  .article > .article-body .article-body-section-wrap > :last-child,
  .article > .article-footer > :last-child {
    margin-bottom: 0; }

/**
 * Stapel
 *
 * Komponentendefinitionen
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.stack {
  margin-bottom: 20px;
  margin-top: 20px; }
  .stack .stack-item {
    margin: 60px 0;
    position: relative; }
    .stack .stack-item *:first-child {
      margin-top: 0; }
    .stack .stack-item *:last-child {
      margin-bottom: 0; }
    .stack .stack-item:first-child {
      margin-top: 0; }
    .stack .stack-item:last-child {
      margin-bottom: 0; }

/* =============================================================================
 *
 * Artikel
 *
 * ========================================================================== */
.stack-article .stack-item {
  position: relative; }
  .stack-article .stack-item .title {
    color: #2A89CF;
    font-weight: 700;
    margin: 15px 0;
    font-size: 1.125rem;
    margin-top: 0; }
    .stack-article .stack-item .title > a {
      color: inherit;
      text-decoration: none; }
  .stack-article .stack-item .edited {
    margin: 5px 0;
    font-style: italic; }
  .stack-article .stack-item .description {
    padding: 0;
    margin: 15px 0; }
  .stack-article .stack-item .link-read-more {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    display: block;
    margin-top: 15px;
    font-size: 1.125rem;
    text-decoration: none; }
    .stack-article .stack-item .link-read-more::before {
      font-size: .75em;
      top: -1px; }
    .stack-article .stack-item .link-read-more.hover {
      color: #50a1dc;
      text-decoration: none; }
  .stack-article .stack-item > .row > * > :first-child {
    margin-top: 0; }
  .stack-article .stack-item > .row > * > :last-child {
    margin-bottom: 0; }

/**
 * Events
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
@keyframes load-more-rotate {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg); }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg); } }

/** ----------------------------------------------------------------------------
 * Kontrollbar
 * -------------------------------------------------------------------------- */
@keyframes ec-loading-indicator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.event-control {
  display: flex;
  flex-flow: row nowrap;
  font-size: 1rem;
  justify-content: space-between; }
  .event-control__item {
    flex: 0 1 auto;
    text-align: right; }
    .event-control__item:first-child {
      text-align: left; }
  .event-control__item-link {
    background-color: #2A89CF;
    border-radius: 2em;
    border: 3px solid #2A89CF;
    color: #fff;
    display: block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1;
    padding: 0.625em 0.9375em;
    text-decoration: none; }
    .event-control__item-link:hover, .event-control__item-link:focus {
      background-color: #8baac2;
      border-color: #8baac2;
      color: white;
      text-decoration: none; }
    .event-control__item-link[data-control|="remove"] {
      background-color: #807f7f;
      border-color: #807f7f;
      color: #000;
      display: none; }
      .event-control__item-link[data-control|="remove"]:hover, .event-control__item-link[data-control|="remove"]:focus {
        background-color: #949393;
        border-color: #949393;
        color: #000; }
    .event-control__item-link[data-control="add-waitinglist"] {
      background-color: #f3bb5b;
      border-color: #f3bb5b;
      color: #000;
      display: none; }
      .event-control__item-link[data-control="add-waitinglist"]:hover, .event-control__item-link[data-control="add-waitinglist"]:focus {
        background-color: #f6cb81;
        border-color: #f6cb81;
        color: #212121; }
    .event-control__item-link[data-control="detail"] {
      background-color: transparent;
      border-color: #807f7f;
      color: #807f7f; }
      .event-control__item-link[data-control="detail"]:hover, .event-control__item-link[data-control="detail"]:focus {
        background-color: transparent;
        border-color: #949393;
        color: #949393; }
    .event-control__item-link[data-control="add-basket"] {
      display: block; }
    .event-control__item-link[data-control="remove-basket"] {
      display: none; }
  .event-control.-is-loading .event-control__item {
    opacity: 0.1; }
  .event-control.-is-loading::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 10; }
  .event-control.-is-loading::after {
    animation: ec-loading-indicator 0.8s infinite linear;
    border: 0.25rem solid #2A89CF;
    border-radius: 50%;
    border-right-color: transparent !important;
    content: '';
    height: 1.5rem;
    left: 50%;
    margin: -0.75rem 0 0 -0.75rem;
    position: absolute;
    top: 50%;
    width: 1.5rem;
    z-index: 11; }

/** ----------------------------------------------------------------------------
 * Block (Box)
 * -------------------------------------------------------------------------- */
.event {
  background-color: #fff;
  cursor: default;
  display: flex;
  flex-flow: column nowrap;
  font-size: 1rem;
  position: relative;
  /**
	 * Elemente
	 */
  /**
	 * Grundgerüst
	 */
  /**
	 * Data-Attribute (spezifisch)
	 */ }
  .event > :first-child {
    margin-top: 0; }
  .event > :last-child {
    margin-bottom: 0; }
  .event__title {
    hyphens: auto; }
  .event__badge {
    background-color: #2A89CF;
    border-radius: 0.1875rem;
    color: #000;
    padding-left: 1.5em;
    padding-right: 1.5em; }
    .event__badge:empty {
      display: none; }
  .event__data {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.9375rem;
    justify-content: flex-start;
    margin-bottom: 15px;
    margin-top: 15px;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .event__data.-dates {
      background: #f4f4f4;
      padding: 15px; }
      .event__data.-dates + .-dates {
        margin-top: 30px; }
    .event__data-date {
      flex: 100%;
      margin-top: 0; }
      .event__data-date > * {
        font-weight: 700;
        margin-top: 0; }
      .event__data-date i {
        margin-right: 10px;
        position: relative;
        top: -1px; }
    .event__data-name, .event__data-value {
      align-self: auto;
      font-weight: 300;
      margin-top: 10px;
      order: 0; }
    .event__data-name {
      flex: 1 0 40%;
      padding-right: 10px; }
    .event__data-value {
      flex: 1 0 60%; }
    .event__data-value {
      flex-basis: 60%; }
      .event__data-value > [data-bind="popover"] {
        cursor: pointer;
        display: inline-block; }
        .event__data-value > [data-bind="popover"] > i {
          color: #46a6b1;
          margin-left: .25em; }
        .event__data-value > [data-bind="popover"] + [data-bind="popover"]::before {
          content: "/";
          padding: 0 .5em; }
    @media only screen and (max-width: 640px) {
      .event__data-name, .event__data-value {
        flex-basis: 100% !important;
        width: 100% !important; }
      .event__data-name {
        color: rgba(0, 0, 0, 0.7);
        font-size: .9375em; }
      .event__data-value {
        padding-left: 0;
        margin-top: 5px; } }
  .event__header, .event__body, .event__footer {
    padding: 15px; }
    .event__header > :first-child, .event__body > :first-child, .event__footer > :first-child {
      margin-top: 0; }
    .event__header > :last-child, .event__body > :last-child, .event__footer > :last-child {
      margin-bottom: 0; }
  .event__header {
    flex: 0 0 auto; }
    .event__header + .event__body {
      padding-top: 0; }
  .event__footer {
    background-color: rgba(0, 0, 0, 0.05);
    flex: 0 0 auto;
    position: relative; }
  .event__body {
    flex: 1 0 auto;
    position: relative; }
  .event [data-event-hint]:not([data-event-hint='']) {
    overflow: hidden; }
    .event [data-event-hint]:not([data-event-hint=''])::before {
      background-color: rgba(255, 255, 255, 0.9);
      bottom: 0;
      content: "";
      display: none;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10; }
    .event [data-event-hint]:not([data-event-hint=''])::after {
      backface-visibility: hidden;
      color: #2A89CF;
      content: attr(data-event-hint);
      display: none;
      font-size: 0.8125em;
      font-weight: 600;
      left: 0;
      line-height: 1.25em;
      margin: 0;
      opacity: 0.75;
      padding: 0 15px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      z-index: 11; }
  .event[data-is-basket] .event-control [data-control="add-basket"] {
    display: none; }
  .event[data-is-basket] .event-control [data-control="remove-basket"] {
    display: block; }
  .event[data-is-waiting] .event__badge {
    background-color: #f3bb5b; }
  .event[data-is-waiting] .event-control [data-control$="basket"] {
    display: none; }
  .event[data-is-waiting] .event-control [data-control="add-waitinglist"] {
    display: block; }
  .event[data-is-waiting] .event-control [data-control="remove-waitinglist"] {
    display: none; }
  .event[data-is-waiting][data-is-basket] .event-control [data-control="add-waitinglist"] {
    display: none; }
  .event[data-is-waiting][data-is-basket] .event-control [data-control="remove-waitinglist"] {
    display: block; }
  .event[data-is-fullybooked] .event__badge {
    background-color: #ea4b77; }
  .event[data-is-fullybooked] .event-control [data-control]:not([data-control="detail"]) {
    display: none; }
  .event[data-is-started] [data-event-hint]::before, .event[data-is-started] [data-event-hint]::after, .event[data-is-blocked] [data-event-hint]::before, .event[data-is-blocked] [data-event-hint]::after, .event[data-is-canceled] [data-event-hint]::before, .event[data-is-canceled] [data-event-hint]::after, .event[data-is-booked] [data-event-hint]::before, .event[data-is-booked] [data-event-hint]::after {
    display: block; }
  .event[data-is-started] .event-control [data-control]:not([data-control="detail"]), .event[data-is-blocked] .event-control [data-control]:not([data-control="detail"]), .event[data-is-canceled] .event-control [data-control]:not([data-control="detail"]), .event[data-is-booked] .event-control [data-control]:not([data-control="detail"]) {
    display: none; }

/** ----------------------------------------------------------------------------
 * Listenansicht
 * -------------------------------------------------------------------------- */
.event-stack {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px; }
  .event-stack .event {
    min-height: 100%; }
  .event-stack__item {
    align-self: stretch;
    flex-basis: 33.3333333333%;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 33.3333333333%;
    overflow: hidden;
    padding: 15px;
    width: 33.3333333333%;
    /*
		 * Schluessel existiert in der Map
		 */
    /*
		 * Schluessel existiert in der Map
		 */ }
    @media only screen and (max-width: 991px) {
      .event-stack__item {
        flex-basis: 50%;
        min-width: 50%;
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .event-stack__item {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%; } }
  .event-stack__loader {
    display: none; }

/**
 * Nachladbare Events (Boxen)
 */
.event-stack[data-endless] .event-stack__loader {
  flex-basis: 100%;
  margin-top: 30px;
  min-width: 100%;
  text-align: center;
  width: 100%;
  position: relative;
  display: block; }

.event-stack[data-endless] .event {
  min-height: 100%;
  transform: scale(0.8) translate3d(0, 4rem, 0);
  opacity: 0;
  transition: all .25s ease; }
  .event-stack[data-endless] .event[data-is-parsed] {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0); }

.event-stack[data-endless] .btn[data-load-more] .btn-text {
  visibility: hidden; }

.event-stack[data-endless] .btn[data-load-more]::after {
  animation: load-more-rotate 0.8s infinite linear;
  border-color: #fff;
  border-radius: 50%;
  border-right-color: transparent !important;
  border-style: solid;
  content: '';
  display: block;
  font-size: 1.25rem;
  height: 1em;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 1em; }

.event-stack[data-endless="loaded"] .btn[data-load-more] .btn-text {
  visibility: visible; }

.event-stack[data-endless="loaded"] .btn[data-load-more]::after {
  display: none;
  animation: none; }

.event-stack[data-endless="end-reached"] .event-stack__loader,
.event-stack[data-endless="end-reached"] .event-stack__loader > * {
  display: none; }

/** ----------------------------------------------------------------------------
 * Detailansicht
 * -------------------------------------------------------------------------- */
.event-detail .subtitle {
  margin-top: 30px; }

.event-detail h3,
.event-detail h4 {
  color: #000; }

.event-detail h4 {
  margin-bottom: 0; }

.event-detail p {
  margin-bottom: 15px;
  margin-top: 15px; }

.event-detail .event__data {
  margin: 0; }
  .event-detail .event__data-name {
    flex: 1 0 25%;
    padding-right: 10px; }
  .event-detail .event__data-value {
    flex: 1 0 75%; }
  .event-detail .event__data.-dates {
    background: #f4f4f4;
    padding: 15px; }
    .event-detail .event__data.-dates + .-dates {
      margin-top: 30px; }
    .event-detail .event__data.-dates .event__data-date {
      flex: 100%;
      margin-top: 0; }
      .event-detail .event__data.-dates .event__data-date > * {
        font-weight: 700;
        margin-top: 0; }
      .event-detail .event__data.-dates .event__data-date i {
        margin-right: 10px;
        position: relative;
        top: -1px; }
    .event-detail .event__data.-dates .event__data-name {
      flex: 1 0 15%;
      padding-right: 10px; }
    .event-detail .event__data.-dates .event__data-value {
      flex: 1 0 85%; }
  .event-detail .event__data ol,
  .event-detail .event__data ul {
    margin-bottom: 5px;
    margin-top: 5px; }
    .event-detail .event__data ol > li,
    .event-detail .event__data ul > li {
      margin-bottom: 5px;
      margin-top: 5px; }
      .event-detail .event__data ol > li:first-child,
      .event-detail .event__data ul > li:first-child {
        margin-top: 0; }
      .event-detail .event__data ol > li:last-child,
      .event-detail .event__data ul > li:last-child {
        margin-bottom: 0; }

/*!
 * Module.
 */
/**
 * Module
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Box mit weißem Hintergrund
 */
.box {
  align-content: flex-start;
  align-items: stretch;
  background-color: #fff;
  border: 2px solid #dedcdc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 1rem;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
  /*
		 * Schluessel existiert in der Map
		 */ }
  @media only screen and (max-width: 767px) {
    .box {
      max-width: none; } }
  .box > form {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;
    min-height: 100%;
    padding: 0; }
  .box__header, .box__footer {
    order: 0;
    flex: 0 1 auto;
    align-self: auto; }
  .box__header {
    padding: 30px;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box__header > * {
      margin-top: 15px; }
      .box__header > *:first-child {
        margin-top: 0; }
      .box__header > *:last-child {
        margin-bottom: 0; }
    .box__header + .box__body {
      padding-top: 0; }
    @media only screen and (max-width: 767px) {
      .box__header {
        padding: 15px; } }
  .box__body {
    align-self: auto;
    flex: 1 0 auto;
    order: 0;
    padding: 30px;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box__body > :first-child {
      margin-top: 0; }
    .box__body > :last-child {
      margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .box__body {
        padding: 15px; } }
  .box__footer {
    background-color: transparent;
    padding: 15px 30px;
    text-align: center;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box__footer:before, .box__footer:after {
      content: ' ';
      /* 1 */
      display: table;
      /* 2 */ }
    .box__footer [data-btn-prev] {
      float: left; }
    .box__footer [data-btn-next] {
      float: right; }
    @media only screen and (max-width: 767px) {
      .box__footer {
        padding-left: 15px;
        padding-right: 15px; } }

.box-stack {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -15px; }
  .box-stack__item {
    order: 0;
    flex: 0 1 50%;
    align-self: stretch;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box-stack__item > .padding-hack {
      padding: 15px;
      height: 100%; }
      .box-stack__item > .padding-hack > * {
        height: 100%; }
    @media only screen and (max-width: 767px) {
      .box-stack__item {
        flex-basis: 100%;
        width: 100%; } }
  .box-stack.-sidebar-view {
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box-stack.-sidebar-view .box {
      max-width: none; }
    .box-stack.-sidebar-view > *:nth-child(2n+1) {
      flex-basis: 66%; }
    .box-stack.-sidebar-view > *:nth-child(2n) {
      flex-basis: 34%; }
    @media only screen and (max-width: 767px) {
      .box-stack.-sidebar-view > *:nth-child(1n) {
        flex-basis: 100%; } }

/**
 * Global
 */
.module {
  align-content: stretch;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 1rem;
  justify-content: flex-start; }
  .module__header, .module__footer {
    align-self: stretch;
    flex: 0 1 auto; }
  .module__header > .wrap, .module__footer > .wrap, .module__body > .wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0 15px;
    width: 100%; }
  .module__header {
    background-color: #fff;
    border-bottom: 0.25rem solid #dedcdc; }
    .module__header > .wrap {
      padding-bottom: 1.25rem;
      padding-top: 1.25rem; }
      .module__header > .wrap > :first-child {
        margin-top: 0; }
      .module__header > .wrap > :last-child {
        margin-bottom: 0; }
    .module__header .step-progress {
      margin: 0 0 -1.5rem; }
    .module__header .title {
      margin: 0;
      font-size: 1.5625em; }
  .module__footer {
    background-color: rgba(146, 197, 235, 0.2); }
    .module__footer > .wrap {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center; }
  .module__body {
    flex: 1 0 auto;
    align-self: stretch;
    padding-bottom: 60px;
    padding-top: 60px; }
  .module[data-module-blocked] .module__footer {
    background-color: #f4f4f4; }

/**
 * Imports
 */
/**
 * Event-Suche
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Suchformular
 */
.form-event-search {
  padding-bottom: 15px;
  padding-top: 15px; }
  .form-event-search .control-label {
    font-size: 0.875em; }
  .section.-dark .form-event-search .form-control {
    background-color: #fff;
    border-color: #fff; }

/**
 * Auflistung Events
 */
.module-event-search .event-stack__item {
  flex-basis: 50%;
  min-width: 50%;
  width: 50%;
  /*
		 * Schluessel existiert in der Map
		 */ }
  @media only screen and (max-width: 767px) {
    .module-event-search .event-stack__item {
      flex-basis: 100%;
      min-width: 100%;
      width: 100%; } }

/**
 * Multisteps
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Auswahl Kategorie
 */
[data-module-steps="select-category"] .categories {
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: -15px; }
  [data-module-steps="select-category"] .categories__item {
    align-self: center;
    flex: 0 1 auto;
    margin: 0;
    order: 0;
    padding: 0; }
    [data-module-steps="select-category"] .categories__item::before {
      display: none; }
    [data-module-steps="select-category"] .categories__item > .padding-hack {
      padding: 15px; }
    [data-module-steps="select-category"] .categories__item .btn {
      display: block; }

[data-module-steps="select-direction"] .form-group {
  margin-bottom: 0; }

[data-module-steps="select-direction"] .cert-info {
  font-size: 0.875em;
  margin: 30px -30px 0;
  padding: 0; }
  [data-module-steps="select-direction"] .cert-info__item {
    border-top: 1px solid #f4f4f4;
    display: none;
    margin: 0;
    padding: 15px 30px 0; }
    [data-module-steps="select-direction"] .cert-info__item::before {
      display: none; }
    [data-module-steps="select-direction"] .cert-info__item * {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 1em; }
      [data-module-steps="select-direction"] .cert-info__item *:first-child {
        margin-bottom: 0; }
      [data-module-steps="select-direction"] .cert-info__item *:last-child {
        margin-bottom: 0; }

/**
 * Qualifikationrn
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
[data-module-user="qualification"] .collapse-panel-content {
  padding-top: 30px;
  position: relative; }

[data-module-user="qualification"] .collapse [data-ctrl-qualification-remove] {
  background: none;
  border: 0;
  color: #605f5f;
  font-size: 1.3125em;
  height: auto;
  line-height: 1.25;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px; }

/**
 * Warenkorb
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.order-stack {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -15px; }
  .order-stack__item {
    order: 0;
    flex: 0 1 100%;
    align-self: stretch; }
    .order-stack__item > .padding-hack {
      padding: 15px; }
  .order-stack .event {
    background-color: #f8f8f8;
    border: 3px solid #f4f4f4; }
    .order-stack .event__body .title {
      padding-right: 20px; }
    .order-stack .event .alert {
      border: 0;
      padding: 5px 10px;
      font-size: .75em;
      font-weight: normal; }
    .order-stack .event .btn[data-control="detail"] {
      background-color: #807f7f;
      border-color: #807f7f;
      color: #000; }
      .order-stack .event .btn[data-control="detail"]:hover, .order-stack .event .btn[data-control="detail"]:active, .order-stack .event .btn[data-control="detail"]:focus {
        background-color: #949393;
        border-color: #949393;
        color: #000; }
    .order-stack .event[data-is-booked], .order-stack .event[data-is-active] {
      background-color: rgba(42, 137, 207, 0.05);
      border-color: rgba(42, 137, 207, 0.3); }
      .order-stack .event[data-is-booked] .btn[data-control="detail"], .order-stack .event[data-is-active] .btn[data-control="detail"] {
        background-color: #2A89CF;
        border-color: #2A89CF;
        color: #fff; }
        .order-stack .event[data-is-booked] .btn[data-control="detail"]:hover, .order-stack .event[data-is-booked] .btn[data-control="detail"]:active, .order-stack .event[data-is-booked] .btn[data-control="detail"]:focus, .order-stack .event[data-is-active] .btn[data-control="detail"]:hover, .order-stack .event[data-is-active] .btn[data-control="detail"]:active, .order-stack .event[data-is-active] .btn[data-control="detail"]:focus {
          background-color: #489cda;
          border-color: #489cda;
          color: white; }
    .order-stack .event[data-is-waiting] {
      background-color: rgba(243, 187, 91, 0.05);
      border-color: rgba(243, 187, 91, 0.3); }
      .order-stack .event[data-is-waiting] .btn[data-control="detail"] {
        background-color: #f3bb5b;
        border-color: #f3bb5b;
        color: #000; }
        .order-stack .event[data-is-waiting] .btn[data-control="detail"]:hover, .order-stack .event[data-is-waiting] .btn[data-control="detail"]:active, .order-stack .event[data-is-waiting] .btn[data-control="detail"]:focus {
          background-color: #f6cb81;
          border-color: #f6cb81;
          color: #212121; }
    .order-stack .event[data-is-remove-time] {
      background-color: rgba(234, 75, 119, 0.05);
      border-color: rgba(234, 75, 119, 0.3); }
      .order-stack .event[data-is-remove-time] .btn[data-control="detail"] {
        background-color: #ea4b77;
        border-color: #ea4b77;
        color: #000; }
        .order-stack .event[data-is-remove-time] .btn[data-control="detail"]:hover, .order-stack .event[data-is-remove-time] .btn[data-control="detail"]:active, .order-stack .event[data-is-remove-time] .btn[data-control="detail"]:focus {
          background-color: #ee7093;
          border-color: #ee7093;
          color: #212121; }
    .order-stack .event[data-is-removed] {
      opacity: 0.5; }
  .order-stack [data-order-remove] {
    color: #92C5EB;
    cursor: pointer;
    font-size: 1.3125em;
    line-height: 1.25;
    position: absolute;
    right: 15px;
    top: 15px; }
  .order-stack .checkbox,
  .order-stack .radio {
    margin: 0; }
    .order-stack .checkbox-styled > label::before,
    .order-stack .radio-styled > label::before {
      background-color: #fff; }

[data-module-basket="empty"] .box__footer {
  text-align: left; }

/*!
 * Kernaufbau.
 */
/**
 * Typografie
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Ueberschriften
 *
 * ========================================================================== */
/* Ueberschriften global */
h1,
.h1 {
  clear: both;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  hyphens: auto;
  position: relative; }

h2,
.h2 {
  clear: both;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  hyphens: auto;
  position: relative; }

h3,
.h3 {
  clear: both;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  hyphens: auto;
  position: relative; }

h4,
.h4,
.aside .article > .title,
.aside .article > .ce-title {
  clear: both;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  hyphens: auto;
  position: relative; }

h5,
.h5 {
  clear: both;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  hyphens: auto;
  position: relative; }

h6,
.h6 {
  clear: both;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  hyphens: auto;
  position: relative; }

/* Ueberschrift erster Ordnung */
h1,
.h1 {
  color: #2A89CF;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 1.25;
  margin: 20px 0; }
  h1 small,
  h1 .small,
  .h1 small,
  .h1 .small {
    color: #2A89CF; }

/* Ueberschrift zweiter Ordnung */
h2,
.h2 {
  color: #2A89CF;
  font-size: 1.5625rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 1.25;
  margin: 15px 0; }
  h2 small,
  h2 .small,
  .h2 small,
  .h2 .small {
    color: #2A89CF; }

/* Ueberschrift dritter Ordnung */
h3,
.h3 {
  color: #2A89CF;
  font-size: 1.125rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 1.25;
  margin: 15px 0; }
  h3 small,
  h3 .small,
  .h3 small,
  .h3 .small {
    color: #2A89CF; }

/* Ueberschriften vierter bis sechster Ordnung */
h4,
.h4,
.aside .article > .title,
.aside .article > .ce-title,
h5,
.h5,
h6,
.h6 {
  color: #2A89CF;
  font-size: 1rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1.25;
  margin: 15px 0; }
  h4 small,
  h4 .small,
  .h4 small,
  .aside .article > .title small,
  .aside .article > .ce-title small,
  .h4 .small,
  .aside .article > .title .small,
  .aside .article > .ce-title .small,
  h5 small,
  h5 .small,
  .h5 small,
  .h5 .small,
  h6 small,
  h6 .small,
  .h6 small,
  .h6 .small {
    color: #2A89CF; }

/* =============================================================================
 *
 * Kontextabhaengige Farben
 *
 * ========================================================================== */
.text-light {
  color: #4d4d4d; }

.text-lighter {
  color: #737373; }

.text-dark {
  color: black; }

.text-darker {
  color: black; }

.text-muted {
  color: #f4f4f4; }

.text-invalid {
  color: #ea4b77; }

.text-danger {
  color: #ea4b77; }

.text-info {
  color: #46a6b1; }

.text-primary {
  color: #92C5EB; }

.text-secondary {
  color: #2A89CF; }

.text-success {
  color: #2A89CF; }

.text-warning {
  color: #f3bb5b; }

.bg-primary {
  color: #fff; }

.bg-error {
  background-color: #fad4df; }

a.bg-error:hover {
  background-color: #f5a6bc; }

.bg-danger {
  background-color: #fad4df; }

a.bg-danger:hover {
  background-color: #f5a6bc; }

.bg-info {
  background-color: #b0dbe0; }

a.bg-info:hover {
  background-color: #8ccad1; }

.bg-primary {
  background-color: #92C5EB; }

a.bg-primary:hover {
  background-color: #67aee3; }

.bg-success {
  background-color: #a5ceed; }

a.bg-success:hover {
  background-color: #7bb7e4; }

.bg-warning {
  background-color: #fdf6ea; }

a.bg-warning:hover {
  background-color: #fae2ba; }

/* =============================================================================
 *
 * Ausrichtungen
 *
 * ========================================================================== */
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/* =============================================================================
 *
 * Text
 *
 * ========================================================================== */
p {
  hyphens: auto; }

p,
blockquote,
pre,
hr {
  margin: 15px 0; }

cite {
  color: #dedcdc;
  font-style: italic; }

.large,
.text-large {
  font-size: 1.125rem; }

.xlarge,
.text-xlarge {
  font-size: 1.375rem; }

small,
.small,
.text-small {
  font-size: 0.875rem; }

.xsmall,
.text-xsmall {
  font-size: 0.8125rem; }

.large .large,
.large .xsmall,
.large small,
.large .small,
.large .text-small,
.large .text-xsmall,
small .large,
small .xsmall,
small small,
small .small,
small .text-small,
small .text-xsmall,
.small .large,
.small .xsmall,
.small small,
.small .small,
.small .text-small,
.small .text-xsmall,
.xsmall .large,
.xsmall .xsmall,
.xsmall small,
.xsmall .small,
.xsmall .text-small,
.xsmall .text-xsmall,
.text-small .large,
.text-small .xsmall,
.text-small small,
.text-small .small,
.text-small .text-small,
.text-small .text-xsmall,
.text-xsmall .large,
.text-xsmall .xsmall,
.text-xsmall small,
.text-xsmall .small,
.text-xsmall .text-small,
.text-xsmall .text-xsmall {
  font-size: 1em; }

.text-uppercase {
  text-transform: uppercase; }

/* -----------------------------------------------------------------------------
 * Blockquote
 * -------------------------------------------------------------------------- */
blockquote {
  background: rgba(146, 197, 235, 0.2);
  clear: both;
  color: #92C5EB;
  font-style: italic;
  font-weight: 300;
  hyphens: auto;
  line-height: 1.25;
  position: relative;
  font-family: inherit;
  font-size: 1.875rem; }
  blockquote, blockquote.pull-right, blockquote.pull-left {
    border: 3px solid white;
    padding: 30px 30px 30px 75px; }
  blockquote > p {
    hyphens: auto;
    margin: 0;
    padding: 0; }
  blockquote:before {
    color: inherit;
    content: '\f10d';
    font-size: 1.5em;
    left: 15px;
    position: absolute;
    top: 15px; }
  blockquote cite {
    color: inherit;
    display: block;
    font-size: .6125em;
    font-style: normal;
    font-weight: inherit;
    padding-top: 15px; }
    blockquote cite:before, blockquote cite:after {
      content: '-'; }
    blockquote cite:before {
      padding-right: 5px; }
    blockquote cite:after {
      padding-left: 5px; }

/* -----------------------------------------------------------------------------
 * Sonstiges
 * -------------------------------------------------------------------------- */
::selection {
  background: #cce3e8;
  color: #000; }

hr {
  border-top-color: #f4f4f4; }

address {
  line-height: inherit; }

/*
 * Kursiver Text
 */
.italic,
em, .em {
  font-style: italic; }

/*
 * Fetter Text
 */
strong,
.strong {
  font-weight: 700; }

strong {
  padding-left: 2px;
  padding-right: 2px; }

mark,
.mark {
  background-color: #fdf6ea;
  padding: 0.2em; }

/* Abkuerzungen etc. */
abbr[title],
abbr[data-original-title],
dfn[title],
acronym[title] {
  border-bottom: 1px dashed #e2e200;
  cursor: help; }

/* Datum, Zeit */
.time {
  display: none; }
  .time .oclock {
    display: none; }
    .time .oclock span {
      padding-right: 4px; }

/* -----------------------------------------------------------------------------
 * Code
 * -------------------------------------------------------------------------- */
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  background-color: #f4f4f4;
  border-radius: 3px;
  color: #807f7f;
  font-size: 80%; }

kbd {
  background-color: #605f5f;
  border-radius: 2px;
  box-shadow: none;
  color: #fff; }
  kbd kbd {
    background-color: transparent;
    box-shadow: none;
    font-weight: 400; }

pre {
  background-color: #fff;
  border: 1px solid #f8f8f8;
  border-radius: 3px;
  color: #f4f4f4;
  font-size: 0.9375rem;
  line-height: 1.5; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0; }

/**
 * Grundaufbau, -raster
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
html {
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 16px; }

body,
html {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  background-attachment: fixed;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
  font: normal normal 300 16px/1.5 "Roboto", Helvetica, Arial, sans-serif;
  text-align: left; }

.show-on-print {
  display: none; }

/* =============================================================================
 *
 * Grundcontainer der Seite
 *
 * ========================================================================== */
.page-max-width, .main-wrapper > .main-header, .main-wrapper > .main-meta > *, .main-wrapper > .main-breadcumb > *, .main-wrapper > .main-footer > .service,
.main-wrapper > .main-footer > .meta > .nav, .main-breadcrumb > .nav, .main-footer > .bar > .bar-wrap, .bar-account-switched__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .main-wrapper > .main-page {
    flex: 1 0 auto;
    position: relative; }
  .main-wrapper > .main-header, .main-wrapper > .main-breadcumb, .main-wrapper > .main-showcase, .main-wrapper > .main-footer {
    flex: 0 0 auto; }
  .main-wrapper > .main-meta {
    background-color: #2A89CF;
    position: relative; }
  .main-wrapper > .main-breadcumb {
    z-index: 5; }

.main-page {
  background-color: #fff; }
  .main-page.-transparent {
    background-color: #f8f8f8; }

.main-modal-iframe-wrapper {
  padding: 45px;
  /*
		 * Schluessel existiert in der Map
		 */ }
  @media only screen and (max-width: 640px) {
    .main-modal-iframe-wrapper {
      padding: 15px; } }
  .main-modal-iframe-wrapper > .main-modal-footer {
    margin-top: 30px;
    text-align: center; }
  .main-modal-iframe-wrapper .module__header > .wrap, .main-modal-iframe-wrapper .module__footer > .wrap, .main-modal-iframe-wrapper .module__body > .wrap {
    max-width: none;
    padding: 0; }
  .main-modal-iframe-wrapper .module__body {
    padding: 0; }

/* =============================================================================
 *
 * Sektionen, Bereiche
 *
 * ========================================================================== */
.section {
  padding: 60px 0; }
  .section__header, .section__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0 15px;
    width: 100%; }
    .section__header > :first-child, .section__body > :first-child {
      margin-top: 0; }
    .section__header > :last-child, .section__body > :last-child {
      margin-bottom: 0; }
  .section__header {
    margin-bottom: 15px; }
  .section.-dark {
    background-color: #f8f8f8;
    padding-bottom: 30px;
    padding-top: 30px; }

/* =============================================================================
 *
 * Info ueber aktuellen Mediaquery.
 *
 * ========================================================================== */
#dev {
  background-color: #e6e6e6;
  color: #fff;
  cursor: default;
  font-size: 11px;
  left: 50%;
  margin-left: -120px;
  opacity: .25;
  position: fixed;
  top: 0;
  width: 240px;
  z-index: 99990; }
  #dev > * {
    border-top: 1px solid #fff; }
    #dev > *:first-child {
      border-top: 0 none; }
  #dev strong,
  #dev span {
    border: 1px solid transparent;
    display: inline-block;
    font-weight: normal;
    height: 20px;
    line-height: 18px;
    padding: 0 4px; }
  #dev strong {
    background-color: #343434;
    border-color: #343434;
    color: #fff;
    width: 55px; }
  #dev span {
    margin-left: 1px;
    min-width: 35px;
    text-align: center; }
  #dev .mq > span {
    background-color: #929292;
    border-color: #929292;
    display: none; }
  #dev .themes > span {
    background-color: transparent;
    border-color: #929292;
    color: #929292;
    cursor: pointer; }
    #dev .themes > span.active {
      background-color: #ffc107;
      border-color: #ffc107;
      color: #fff; }
  #dev:hover {
    opacity: 1; }

.dev-delimiter {
  border-bottom: 1px solid #dedcdc;
  padding-bottom: 10px;
  text-transform: uppercase; }

/**
 * Seitenkopf
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.main-header {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 30px;
  position: relative;
  transition: all .25s ease;
  /* Flexverhalten */ }
  .main-header .logo {
    backface-visibility: hidden;
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    overflow: hidden;
    transform: translateZ(0);
    transform-style: flat;
    transition: all .25s ease; }
    .main-header .logo > * {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      font-size: inherit;
      height: 0;
      overflow: hidden;
      padding-top: 1em;
      text-indent: 150%;
      white-space: nowrap;
      width: 2.5em; }
    .main-header .logo.-site > * {
      background-image: url(../images/logo/logo_dsv.png); }
    .main-header .logo.-site, .main-header .logo.-client {
      font-size: 6.5rem; }
      .main-header .logo.-site:last-child, .main-header .logo.-client:last-child {
        margin-left: auto !important; }
      .main-header .logo.-site.-dsv-academy > *, .main-header .logo.-client.-dsv-academy > * {
        background: #2A89CF; }
        .main-header .logo.-site.-dsv-academy > *::before, .main-header .logo.-client.-dsv-academy > *::before {
          color: #fff;
          content: 'Platzhalter';
          display: inline-block;
          font-size: 14px;
          left: 50%;
          position: absolute;
          text-indent: 0;
          top: 50%;
          transform: translate3d(-50%, -50%, 0); }
      .main-header .logo.-site > *, .main-header .logo.-client > * {
        width: 1.4375em; }
  .main-header > .main-header-wrap {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    justify-content: end;
    line-height: 1; }
    .main-header > .main-header-wrap .logo {
      align-items: center;
      align-self: center;
      flex: 0 1 auto;
      justify-content: center;
      margin: 0;
      order: 2;
      padding: 0; }
      .main-header > .main-header-wrap .logo + .logo {
        margin-left: 30px; }
      .main-header > .main-header-wrap .logo.-client {
        order: 1; }

/**
 * Showcase
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.main-showcase {
  margin-bottom: 30px; }
  .main-showcase > .bar > .bar-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    width: 100%; }
    .main-showcase > .bar > .bar-inner > * {
      flex: 0 1 auto;
      width: 25%; }
    .main-showcase > .bar > .bar-inner > .col-gallery {
      width: 75%; }
  .main-showcase > .bar .nav {
    align-self: center;
    padding-left: 15px;
    padding-right: 45px; }
  .main-showcase > .bar .gallery {
    margin: 0; }
  .main-showcase > .bar-p {
    background: transparent url(../images/backgrounds/showcase.jpg) no-repeat top center;
    background-size: cover;
    padding-top: 45px; }
  .main-showcase > .bar-s .col-gallery {
    margin-top: -60px; }
  .main-showcase .link-intern::before {
    content: "\f138"; }
  .main-showcase .gallery-image-parts {
    background-color: rgba(0, 0, 0, 0.2); }
    .main-showcase .gallery-image-parts .swiper-slide {
      background-color: #2A89CF;
      max-height: 640px; }
    .main-showcase .gallery-image-parts .swiper-pagination {
      bottom: 75px;
      padding-right: 30px;
      text-align: right; }
    .main-showcase .gallery-image-parts .thumbnail > picture.image {
      padding-bottom: 66%; }
      .main-showcase .gallery-image-parts .thumbnail > picture.image > img {
        height: auto;
        width: 100%; }
  .main-showcase .gallery-text-parts .swiper-slide-wrapper {
    padding-left: 30px;
    padding-right: 30px; }
  .main-showcase .gallery-text-parts .article {
    background-color: #92C5EB;
    color: #fff;
    padding: 30px; }
    .main-showcase .gallery-text-parts .article > .article-header, .main-showcase .gallery-text-parts .article > .article-body {
      background-color: transparent;
      float: left;
      margin: 0;
      padding: 0;
      width: 55%; }
      .main-showcase .gallery-text-parts .article > .article-header *, .main-showcase .gallery-text-parts .article > .article-body * {
        color: #fff; }
    .main-showcase .gallery-text-parts .article > .article-header {
      padding-right: 15px;
      width: 45%; }
    .main-showcase .gallery-text-parts .article .subtitle {
      margin-bottom: 15px;
      font-weight: 600; }
    .main-showcase .gallery-text-parts .article .title {
      font-weight: 300;
      font-size: 1.875rem; }
    .main-showcase .gallery-text-parts .article a.link::before {
      content: "\f138"; }
    .main-showcase .gallery-text-parts .article p {
      margin-bottom: 10px;
      margin-top: 10px; }
      .main-showcase .gallery-text-parts .article p:last-child {
        margin: 0; }

/**
 * Breadcrumb
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.main-breadcrumb {
  background: #92C5EB url(../images/backgrounds/breadcrumb.jpg) no-repeat center;
  background-size: cover; }
  .main-breadcrumb > .nav {
    padding-bottom: 20px;
    padding-top: 20px; }

/**
 * Seitenfuss
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.main-footer {
  background-color: #dedcdc;
  color: #2A89CF; }
  .main-footer > .bar > .bar-wrap {
    line-height: 1;
    padding-bottom: 15px;
    padding-top: 15px; }
  .main-footer > .bar:nth-child(1) > .bar-wrap {
    align-content: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .main-footer > .bar:nth-child(1) > .bar-wrap > * {
      align-items: center;
      align-self: center; }
    @media only screen and (max-width: 640px) {
      .main-footer > .bar:nth-child(1) > .bar-wrap {
        flex-flow: column wrap; } }
  .main-footer > .bar:nth-child(2) {
    background-color: #f4f4f4; }
  .main-footer .nav-service li a {
    margin-bottom: 15px;
    margin-top: 15px;
    /*
		 * Schluessel existiert in der Map
		 */ }
    @media only screen and (max-width: 420px) {
      .main-footer .nav-service li a {
        margin-bottom: 10px;
        margin-top: 10px; } }
  .main-footer .social {
    font-size: 1.125rem; }
    .main-footer .social > a {
      color: #2A89CF;
      display: inline-block;
      margin: 15px;
      text-decoration: none; }
      .main-footer .social > a:first-child {
        margin-left: 0; }
      .main-footer .social > a:last-child {
        margin-right: 0; }
      .main-footer .social > a:hover, .main-footer .social > a:active, .main-footer .social > a:focus {
        color: #92C5EB; }
  .main-footer dl.sponsors-supporters {
    clear: none;
    float: left;
    width: 50%; }
    .main-footer dl.sponsors-supporters > dt {
      color: #c9c9c9;
      font-size: 1.875rem;
      margin: 0; }
    .main-footer dl.sponsors-supporters > dd {
      float: left;
      margin-top: 30px;
      padding-right: 30px; }
    .main-footer dl.sponsors-supporters a {
      display: block; }
      .main-footer dl.sponsors-supporters a img {
        filter: grayscale(100%);
        height: 70px;
        opacity: .45;
        transition: all 0.75s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        width: auto; }
      .main-footer dl.sponsors-supporters a:hover img, .main-footer dl.sponsors-supporters a:focus img {
        filter: grayscale(0);
        opacity: 1; }
    .main-footer dl.sponsors-supporters:first-child {
      padding-right: 15px; }
    .main-footer dl.sponsors-supporters:last-child {
      padding-left: 15px; }
    .main-footer dl.sponsors-supporters:first-child:last-child {
      width: 100%;
      padding-left: 0;
      padding-right: 0; }

/**
 * Navigation
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/* =============================================================================
 *
 * Hauptmenue
 *
 * ========================================================================== */
.nav-primary {
  background-color: transparent;
  color: #2A89CF;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  text-transform: uppercase;
  font-variant: normal;
  /* Standards für die gesamte Navigation. */
  /* Hauptmenüebene. */
  /* 2-te Untermenüebene. */
  /* 3-te Untermenüebene. */
  /* Globale themebasierte Farbgebungen setzen. */ }
  .nav-primary li {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
    /**
		 * Link-, Link-Event-Standards.
		 */ }
    .nav-primary li > ul {
      display: none; }
    .nav-primary li a {
      background-color: transparent;
      border: 3px solid transparent;
      border-radius: 40px;
      color: #2A89CF;
      display: block;
      padding-left: 35px;
      position: relative; }
      .nav-primary li a::before {
        bottom: 0;
        color: #92C5EB;
        content: "\f138";
        font-size: 14px;
        left: 0;
        line-height: inherit;
        padding-left: 10px;
        position: absolute;
        top: 0; }
    .nav-primary li:hover > a,
    .nav-primary li:focus > a,
    .nav-primary li.touch-hover-helper > a,
    .nav-primary li.is-open > a,
    .nav-primary li a:hover,
    .nav-primary li a:focus {
      background-color: transparent;
      color: #92C5EB; }
    .nav-primary li.active > ul {
      display: block; }
    .nav-primary li.active > a {
      background-color: transparent !important;
      color: #92C5EB !important; }
  .nav-primary > ul {
    margin-left: -13px; }
    .nav-primary > ul > li {
      margin-bottom: 5px;
      margin-top: 5px; }
      .nav-primary > ul > li > a {
        height: 40px;
        line-height: 37px;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 700; }
      .nav-primary > ul > li.active > a {
        border-color: #92C5EB; }
      .nav-primary > ul > li:first-child {
        margin-top: 0; }
      .nav-primary > ul > li:last-child {
        margin-bottom: 0; }
  .nav-primary > ul ul {
    background-color: transparent;
    display: none;
    font-size: .875em;
    text-transform: none; }
    .nav-primary > ul ul > li > a {
      border-color: transparent;
      font-weight: 300; }
      .nav-primary > ul ul > li > a::before {
        display: none; }
    .nav-primary > ul ul > li.sub > a::before {
      content: "\f105";
      display: block;
      padding-left: 20px; }
    .nav-primary > ul ul > li.sub.active > a::before {
      content: "\f107"; }
  .nav-primary > ul ul ul {
    font-size: 1em; }
    .nav-primary > ul ul ul > li {
      margin-bottom: 5px;
      margin-top: 5px; }
    .nav-primary > ul ul ul > li > a {
      border: 0 none;
      padding-left: 50px; }
  .nav-primary li.theming-sjs a::before {
    color: #db4e00; }
  .nav-primary li.theming-sjs:hover > a,
  .nav-primary li.theming-sjs:focus > a,
  .nav-primary li.theming-sjs.touch-hover-helper > a,
  .nav-primary li.theming-sjs.is-open > a,
  .nav-primary li.theming-sjs a:hover,
  .nav-primary li.theming-sjs a:focus {
    color: #db4e00; }
  .nav-primary li.theming-sjs.active > a,
  .nav-primary li.theming-sjs li.active > a {
    color: #db4e00 !important; }
  .nav-primary > ul > li.theming-sjs.active > a {
    border-color: #db4e00; }

/* -----------------------------------------------------------------------------
 * Panel-Menue
 * -------------------------------------------------------------------------- */
.nav-primary-mobile.mm-menu {
  background-color: #fff;
  border-right: 3px solid #fff;
  color: #fff;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-align: left;
  /* Navbar (Footer)
	.mm-navbar-bottom {
		border-top-color : $nav-primary-item-color-hover;
		height           : $-navbar-bottom-height;
		padding          : 0 $-padding;
		text-align       : left;

		> * {
			color          : $nav-primary-item-color;
			font-weight    : $nav-primary-font-weight;
			line-height    : $-navbar-bottom-height;
			padding        : 0;
			text-transform : $nav-primary-text-transform;
			width          : auto;

			.btn-text { padding-left : $padding-base-horizontal-xsmall; }

			&:hover { color : $nav-primary-item-color-hover; }

			&:first-child { float : left; }

			&:last-child { float : right; }

			&:first-child:last-child {
				display    : block;
				float      : none;
				text-align : center;
				width      : 100%;
			}
		}

		.user-signin {
			.btn-text {
				padding-left  : 0;
				padding-right : $padding-base-horizontal-xsmall;
			}
		}
	}
	*/
  /* Navbar
	.mm-panels .mm-panel > .mm-navbar {
		background-color : $nav-primary-item-color-active;
		color            : $nav-primary-item-color;
		height           : auto;
		left             : auto;
		line-height      : 1;
		margin           : -20px -20px 15px;
		padding          : 0 $_padding;
		position         : relative;
		right            : auto;
		top              : auto;

		> a { color : $color-white; }

		.mm-title {
			display        : block;
			font-weight    : $nav-primary-font-weight;
			padding        : $padding-base-vertical 0 $padding-base-vertical $margin-base-horizontal-large;
			text-align     : left;
			text-transform : $nav-primary-text-transform;
			z-index        : 1;
		}

		> .mm-btn {
			padding    : 0;
			width      : 100%;
			z-index    : 2;
			text-align : left;

			&,
			&::before {
				border    : 0 none;
				height    : 30px;
				margin    : -15px 0 0;
				width     : 30px;
				transform : none;
				top       : 50%;
			}

			&::before {
				@extend .fa-icon-helper;

				content     : '';
				font-size   : 1.25em;
				line-height : 30px;
			}

			&.mm-prev::before {
				content : '\f104';
				left    : $_padding;
			}
		}
	} */
  /* Panel navbar
	.mm-panels .mm-panel.mm-hasnavbar {
		padding : $-padding;

		// Im ersten Panel die Navbar ausblenden.
		&:first-child {
			padding-top    : 0;
			text-transform : $nav-primary-text-transform;

			.mm-navbar { display : none; }

			.mm-listview > li > a { font-weight : $nav-primary-font-weight; }
		}
	} */
  /* Menüeinträge
	.mm-listview li {
		border-color : $-d-border-color;

		> a,
		> span {
			background-color : $-d-bg-color;
			color            : $-d-color;
			font-size        : cast-unit($font-unit-base, $nav-primary-font-size);
			font-weight      : $nav-primary-font-weight;

			&:hover,
			&:focus,
			&.active {
				background-color : $-d-arrow-bg-color;
				color            : $-d-color;
			}
		}

		// Link: naechste Ebene.
		> .mm-next {
			background-color : $-d-arrow-bg-color;
			border-color     : $-d-border-color;
			color            : $-d-color;
		}

		&.mm-opened,
		&.mm-vertical.mm-opened {
			> .mm-next {
				background-color : $-d-arrow-bg-color;
			}

			> .mm-panel { background-color : $-d-bg-color-open; }
		}

		> .mm-panel {
			padding          : 0;
			background-color : $-d-arrow-bg-color;
		}

		&.meta {
			border-color : $-m-border-color;

			> a,
			> span {
				background-color : $-m-bg-color;
				color            : $-m-color;

				&:hover,
				&:focus,
				&.active {
					background-color : $-m-arrow-bg-color;
					color            : $-m-color;
				}
			}

			// Link: naechste Ebene.
			> .mm-next {
				background-color : $-m-arrow-bg-color;
				border-left-color : $-m-border-color;
				color            : $-m-color;
			}

			&.mm-opened,
			&.active.mm-opened {
				> .mm-next,
				> .mm-panel { background-color : $-m-bg-color-open; }
			}
		}
	} */
  /*	.mm-listview .mm-listview li {
		border-bottom  : 0 none;

		font-style    : $nav-primary-font-style;
		font-weight   : $nav-primary-font-weight;

		> a,
		> span {
			font-weight    : $nav-primary-sub-font-weight;
			text-transform : $nav-primary-sub-text-transform;

			&:hover,
			&:focus,
			&.active {
				background-color : $-d-arrow-bg-color;
				color            : $-d-color;
			}
		}


	}*/
  /* Menüeintrage erste Ebene
	.mm-panels > .mm-panel > .mm-listview > li {} */ }
  .nav-primary-mobile.mm-menu.mm-hasnavbar-top-1 .mm-panels {
    top: 54px; }
    .nav-primary-mobile.mm-menu.mm-hasnavbar-top-1 .mm-panels > .mm-panel:before {
      display: none; }
    .nav-primary-mobile.mm-menu.mm-hasnavbar-top-1 .mm-panels > .mm-panel > .mm-listview:first-child,
    .nav-primary-mobile.mm-menu.mm-hasnavbar-top-1 .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
      padding-top: 0;
      margin-top: 0; }
  .nav-primary-mobile.mm-menu .mm-navbar {
    /* Navbar (Header) */ }
    .nav-primary-mobile.mm-menu .mm-navbar > * {
      color: #92C5EB;
      padding: 0; }
    .nav-primary-mobile.mm-menu .mm-navbar.mm-hasbtns {
      padding: 0 20px; }
    .nav-primary-mobile.mm-menu .mm-navbar-top {
      background-color: #fff;
      border-bottom: 0 none;
      color: #92C5EB;
      height: 54px;
      line-height: 1;
      text-align: left; }
      .nav-primary-mobile.mm-menu .mm-navbar-top .mm-title {
        font-size: 1.125rem;
        font-weight: 700;
        height: 54px;
        line-height: 54px;
        text-transform: none; }
      .nav-primary-mobile.mm-menu .mm-navbar-top .mm-close {
        height: 24px;
        left: initial;
        margin-top: -12px;
        padding-left: 0;
        position: absolute;
        right: 20px;
        top: 50%;
        width: 24px; }
        .nav-primary-mobile.mm-menu .mm-navbar-top .mm-close:before, .nav-primary-mobile.mm-menu .mm-navbar-top .mm-close:after {
          border-width: 4px;
          border-color: #92C5EB;
          height: 12px;
          width: 12px; }
        .nav-primary-mobile.mm-menu .mm-navbar-top .mm-close:before {
          right: 0; }
        .nav-primary-mobile.mm-menu .mm-navbar-top .mm-close:after {
          left: 0;
          right: auto; }
  .nav-primary-mobile.mm-menu .mm-panels > .mm-panel::after {
    display: none; }
  html.mm-opened .mm-page {
    overflow: hidden; }
  .nav-primary-mobile.mm-menu .mm-listview {
    border: 0 none;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    padding: 0;
    margin-bottom: 0;
    /* Standards für Menüeinträge */
    /* Untermenüebene(n) */ }
    .nav-primary-mobile.mm-menu .mm-listview li {
      border-bottom: 3px solid #deecf6;
      /* Link: naechste Ebene. */ }
      .nav-primary-mobile.mm-menu .mm-listview li::before, .nav-primary-mobile.mm-menu .mm-listview li:after {
        display: none !important; }
      .nav-primary-mobile.mm-menu .mm-listview li > a,
      .nav-primary-mobile.mm-menu .mm-listview li > span {
        background-color: #92C5EB;
        color: #fff;
        font-size: 1.125rem;
        font-weight: 700;
        height: 54px;
        line-height: 54px;
        margin: 0;
        padding: 0 20px;
        white-space: normal;
        z-index: 1; }
        .nav-primary-mobile.mm-menu .mm-listview li > a:hover,
        .nav-primary-mobile.mm-menu .mm-listview li > span:hover {
          background-color: #92C5EB;
          color: #fff; }
      .nav-primary-mobile.mm-menu .mm-listview li > a.active, .nav-primary-mobile.mm-menu .mm-listview li > a.active:hover,
      .nav-primary-mobile.mm-menu .mm-listview li > span.active,
      .nav-primary-mobile.mm-menu .mm-listview li > span.active:hover {
        background-color: #82b8e1;
        color: #fff; }
      .nav-primary-mobile.mm-menu .mm-listview li > .mm-next {
        color: #fff;
        padding: 0;
        width: 54px;
        z-index: 2; }
        .nav-primary-mobile.mm-menu .mm-listview li > .mm-next:before {
          display: none; }
        .nav-primary-mobile.mm-menu .mm-listview li > .mm-next:after {
          background-color: #82b8e1;
          border: 0 none;
          border-left: 3px solid #deecf6;
          content: "\f138";
          height: 54px;
          line-height: 54px;
          margin: 0;
          right: 0;
          text-align: center;
          top: 0;
          transform: none;
          width: 54px; }
      .nav-primary-mobile.mm-menu .mm-listview li.is-fullsize-click > .mm-next {
        background-color: transparent;
        left: 0;
        width: auto; }
      .nav-primary-mobile.mm-menu .mm-listview li.mm-opened > .mm-next, .nav-primary-mobile.mm-menu .mm-listview li.mm-vertical.mm-opened > .mm-next {
        color: #fff;
        background-color: #82b8e1; }
        .nav-primary-mobile.mm-menu .mm-listview li.mm-opened > .mm-next:after, .nav-primary-mobile.mm-menu .mm-listview li.mm-vertical.mm-opened > .mm-next:after {
          content: "\f13a"; }
      .nav-primary-mobile.mm-menu .mm-listview li.mm-opened > .mm-panel, .nav-primary-mobile.mm-menu .mm-listview li.mm-vertical.mm-opened > .mm-panel {
        padding: 0;
        background-color: #deecf6; }
      .nav-primary-mobile.mm-menu .mm-listview li.mm-opened.is-fullsize-click > .mm-next, .nav-primary-mobile.mm-menu .mm-listview li.mm-vertical.mm-opened.is-fullsize-click > .mm-next {
        background-color: transparent; }
      .nav-primary-mobile.mm-menu .mm-listview li:last-child {
        border-bottom: 0 none; }
    .nav-primary-mobile.mm-menu .mm-listview .mm-listview {
      padding: 20px 0; }
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > a,
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > span {
        background-color: transparent;
        color: #2A89CF;
        font-size: 1.0625em;
        font-weight: 300;
        height: 38px;
        line-height: 38px;
        padding: 0 40px;
        text-transform: none; }
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > a:hover, .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > a:focus, .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > a.active,
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > span:hover,
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > span:focus,
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > span.active {
          background-color: transparent;
          color: #2A89CF; }
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > a.active,
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > span.active {
          font-weight: 400; }
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > .mm-next {
        background-color: transparent;
        padding: 0; }
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li > .mm-next::after {
          background-color: transparent;
          height: 38px;
          line-height: 38px; }
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview li.mm-opened > .mm-next {
        background-color: transparent;
        color: #2A89CF; }
        .nav-primary-mobile.mm-menu .mm-listview .mm-listview li.mm-opened > .mm-next::after {
          background-color: transparent; }
    .nav-primary-mobile.mm-menu .mm-listview .mm-listview .mm-listview {
      padding-bottom: 15px;
      padding-top: 15px; }
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview .mm-listview li > a,
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview .mm-listview li > span {
        padding-left: 60px; }
      .nav-primary-mobile.mm-menu .mm-listview .mm-listview .mm-listview li > .mm-next {
        padding: 0; }
  .nav-primary-mobile.mm-menu .mm-listview li.meta {
    border-color: #8bc0e8;
    /* Link: naechste Ebene. */ }
    .nav-primary-mobile.mm-menu .mm-listview li.meta > a,
    .nav-primary-mobile.mm-menu .mm-listview li.meta > span {
      background-color: #2A89CF;
      color: #fff; }
      .nav-primary-mobile.mm-menu .mm-listview li.meta > a:hover,
      .nav-primary-mobile.mm-menu .mm-listview li.meta > span:hover {
        background-color: #2A89CF;
        color: #fff; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta > a.active, .nav-primary-mobile.mm-menu .mm-listview li.meta > a.active:hover,
    .nav-primary-mobile.mm-menu .mm-listview li.meta > span.active,
    .nav-primary-mobile.mm-menu .mm-listview li.meta > span.active:hover {
      background-color: #499cda;
      color: #fff; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta > .mm-next {
      color: #fff; }
      .nav-primary-mobile.mm-menu .mm-listview li.meta > .mm-next:after {
        background-color: #499cda;
        border-color: #8bc0e8; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta.is-fullsize-click > .mm-next {
      background-color: transparent; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta.mm-opened > .mm-next, .nav-primary-mobile.mm-menu .mm-listview li.meta.mm-vertical.mm-opened > .mm-next {
      color: #fff;
      background-color: #499cda; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta.mm-opened > .mm-panel, .nav-primary-mobile.mm-menu .mm-listview li.meta.mm-vertical.mm-opened > .mm-panel {
      background-color: #8bc0e8; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta.mm-opened.is-fullsize-click > .mm-next, .nav-primary-mobile.mm-menu .mm-listview li.meta.mm-vertical.mm-opened.is-fullsize-click > .mm-next {
      background-color: transparent; }
    .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li {
      border-color: #8bc0e8; }
      .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > a,
      .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > span {
        color: #fff; }
        .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > a:hover, .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > a:focus, .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > a.active,
        .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > span:hover,
        .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > span:focus,
        .nav-primary-mobile.mm-menu .mm-listview li.meta .mm-listview li > span.active {
          color: #fff; }
  .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs {
    border-color: #f18041;
    /* Link: naechste Ebene. */ }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > a,
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > span {
      background-color: #db4e00;
      color: #fff; }
      .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > a:hover,
      .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > span:hover {
        background-color: #db4e00;
        color: #fff; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > a.active, .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > a.active:hover,
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > span.active,
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > span.active:hover {
      background-color: #ba4707;
      color: #fff; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > .mm-next {
      color: #fff; }
      .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs > .mm-next:after {
        background-color: #ba4707;
        border-color: #f18041; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.is-fullsize-click > .mm-next {
      background-color: transparent; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.mm-opened > .mm-next, .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.mm-vertical.mm-opened > .mm-next {
      color: #fff;
      background-color: #ba4707; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.mm-opened > .mm-panel, .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.mm-vertical.mm-opened > .mm-panel {
      background-color: #f18041; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.mm-opened.is-fullsize-click > .mm-next, .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs.mm-vertical.mm-opened.is-fullsize-click > .mm-next {
      background-color: transparent; }
    .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li {
      border-color: #f18041; }
      .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > a,
      .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > span {
        color: #fff; }
        .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > a:hover, .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > a:focus, .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > a.active,
        .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > span:hover,
        .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > span:focus,
        .nav-primary-mobile.mm-menu .mm-listview li.theming-sjs .mm-listview li > span.active {
          color: #fff; }

/* =============================================================================
 *
 * Meta
 *
 * ========================================================================== */
.nav-meta {
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  font-variant: normal;
  /*
	 * Hauptmenueebene.
	 */
  /**
	 * 2-te Untermenüebene.
	 */ }
  .nav-meta li > a {
    color: #fff; }
  .nav-meta li:hover > a,
  .nav-meta li:focus > a,
  .nav-meta li.touch-helper > a,
  .nav-meta li > a:hover,
  .nav-meta li > a:focus {
    color: #92C5EB; }
  .nav-meta li.active > a {
    color: #92C5EB !important; }
  .nav-meta li:hover > ul,
  .nav-meta li:focus > ul,
  .nav-meta li.touch-helper > ul {
    display: block; }
  .nav-meta > ul {
    border: 0 none;
    list-style: none;
    margin: 0;
    position: relative; }
    .nav-meta > ul li {
      border: 0 none;
      list-style: none;
      margin: 0;
      padding: 0; }
    .nav-meta > ul > li {
      display: inline-block;
      margin: 0 25px;
      padding: 0; }
      .nav-meta > ul > li > a {
        background-color: transparent;
        display: block;
        padding: 25px 0;
        font-weight: 700; }
      .nav-meta > ul > li:hover > a,
      .nav-meta > ul > li:focus > a,
      .nav-meta > ul > li.touch-hover-helper > a,
      .nav-meta > ul > li > a:hover,
      .nav-meta > ul > li > a:focus {
        background-color: transparent; }
      .nav-meta > ul > li.active > a {
        background-color: transparent !important; }
      .nav-meta > ul > li:first-child {
        margin-left: 0; }
      .nav-meta > ul > li:last-child {
        margin-right: 0; }
        .nav-meta > ul > li:last-child > ul {
          left: auto;
          right: 0; }
          .nav-meta > ul > li:last-child > ul::before {
            left: auto;
            right: 15px; }
  .nav-meta > ul ul {
    background-color: rgba(42, 137, 207, 0.9);
    display: none;
    font-size: .9375em;
    left: 0;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    text-transform: none;
    top: 100%;
    z-index: 10;
    text-transform: none; }
    .nav-meta > ul ul > li > a {
      background-color: transparent;
      color: #fff;
      display: block;
      padding: 10px 20px;
      position: relative;
      white-space: nowrap;
      font-weight: 300; }
    .nav-meta > ul ul > li:hover > a,
    .nav-meta > ul ul > li:focus > a,
    .nav-meta > ul ul > li.touch-hover-helper > a,
    .nav-meta > ul ul > li > a:hover,
    .nav-meta > ul ul > li > a:focus {
      background-color: transparent;
      color: #92C5EB; }
    .nav-meta > ul ul > li.active > a {
      background-color: transparent !important;
      color: #92C5EB !important; }
  .nav-meta > ul ul ul {
    left: 100%;
    top: -5px;
    width: 200px; }
    .nav-meta > ul ul ul > li > a {
      white-space: normal; }

/* =============================================================================
 *
 * Service
 *
 * ========================================================================== */
.nav-service > ul > li {
  display: inline-block;
  margin: 0 15px;
  /*
		 * Schluessel existiert in der Map
		 */
  /*
		 * Schluessel existiert in der Map
		 */ }
  .nav-service > ul > li a {
    background-color: transparent;
    color: #2A89CF; }
    .nav-service > ul > li a:hover, .nav-service > ul > li a:focus, .nav-service > ul > li a:active, .nav-service > ul > li a.active {
      background-color: transparent;
      color: #92C5EB; }
  @media only screen and (max-width: 640px) {
    .nav-service > ul > li {
      margin-left: 10px;
      margin-right: 10px; } }
  @media only screen and (max-width: 420px) {
    .nav-service > ul > li {
      display: block;
      text-align: center;
      margin: 0; } }
  .nav-service > ul > li:last-child {
    margin-right: 0; }
  .nav-service > ul > li:first-child {
    margin-left: 0; }

/**
 * Randspalte
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.aside {
  color: #7fbae5; }
  .aside > * {
    margin-bottom: 40px;
    margin-top: 40px; }
    .aside > *:first-child {
      margin-top: 0; }
    .aside > *:last-child {
      margin-bottom: 0; }
  .aside .link-intern::before,
  .aside .link-page::before {
    content: "\f138"; }
  .aside .thumbnail {
    display: block;
    float: none;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
    .aside .thumbnail > .zoom {
      display: none !important; }
  .aside .vcard {
    background-color: transparent;
    border: 0 none;
    padding: 0; }
  .aside .article {
    border: 3px solid #dedcdc;
    padding: 15px;
    position: relative; }
    .aside .article > .title,
    .aside .article > .ce-title {
      color: #123a58; }
    .aside .article > :first-child {
      margin-top: 0 !important; }
    .aside .article > :last-child {
      margin-bottom: 0 !important; }

/* -----------------------------------------------------------------------------
 * DCE-Modifikationen
 * -------------------------------------------------------------------------- */
.aside .dce {
  margin-top: 0; }
  .aside .dce-tb .dce-row > .dce-column {
    width: 100%;
    margin-bottom: 15px; }
    .aside .dce-tb .dce-row > .dce-column:last-child {
      margin-bottom: 0; }

/**
 * Seiteninhalte
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
[data-comp]:not(.form-control),
.comp:not(.form-control) {
  margin-bottom: 30px; }

[data-comp]:last-child:not(.form-control),
.comp:last-child:not(.form-control) {
  margin-bottom: 0; }

.-is-running {
  animation: fade-in-out 2s infinite; }

.scroll-to-top {
  color: #2A89CF;
  cursor: pointer;
  display: none;
  margin-top: -20px;
  position: fixed;
  right: 15px;
  top: 50%;
  z-index: 30; }
  .scroll-to-top > i {
    background-color: #f4f4f4;
    border: 3px solid #dedcdc;
    border-radius: 100%;
    font-size: 1em;
    height: 40px;
    line-height: 32px;
    opacity: 0.8;
    text-align: center;
    transition: opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    width: 40px; }
  .scroll-to-top:hover > i {
    opacity: 1; }

.bar-account-switched {
  background-color: #46a6b1;
  color: #fff;
  font-size: 0.875rem;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  z-index: 999;
  /*
		 * Schluessel existiert in der Map
		 */ }
  @supports (position: sticky) {
    .bar-account-switched {
      left: 0;
      position: sticky;
      width: 100%; } }
  .bar-account-switched a, .bar-account-switched a:hover, .bar-account-switched a:active, .bar-account-switched a:focus {
    color: inherit; }
  @media only screen and (max-width: 991px) {
    .bar-account-switched {
      font-size: 0.8125rem; } }

.user-bar {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  line-height: 1;
  text-transform: none; }
  .user-bar__col {
    flex: 1 0 auto; }
    .user-bar__col > * {
      display: inline-block;
      margin-right: 40px; }
      .user-bar__col > *:last-child {
        margin-right: 0; }
    .user-bar__col.-menu {
      text-transform: uppercase; }
      .user-bar__col.-menu .link {
        color: #fff;
        font-size: 1.65rem;
        font-weight: 600;
        line-height: normal; }
        .user-bar__col.-menu .link i {
          display: none; }
        .user-bar__col.-menu .link .btn__text {
          padding-left: 0; }
        .user-bar__col.-menu .link:hover, .user-bar__col.-menu .link:active, .user-bar__col.-menu .link:focus, .user-bar__col.-menu .link.active {
          text-decoration: none;
          color: #92C5EB; }
    .user-bar__col.-controls {
      flex: 0 1 auto; }
  .user-bar .link,
  .user-bar .btn {
    border: 0 none;
    color: #fff;
    height: auto;
    line-height: 1.75;
    padding: 25px 0;
    position: relative; }
    .user-bar .link i + .btn__text,
    .user-bar .btn i + .btn__text {
      padding-left: 15px; }
    .user-bar .link i + i,
    .user-bar .btn i + i {
      padding-left: 5px; }
    .user-bar .link > .badge,
    .user-bar .btn > .badge {
      background-color: #46a6b1;
      font-size: 1em;
      margin: 0 0 0 5px;
      padding: 3px 6px 2px;
      position: relative;
      top: -10px; }
    .user-bar .link:hover, .user-bar .link:active, .user-bar .link:focus, .user-bar .link.active,
    .user-bar .btn:hover,
    .user-bar .btn:active,
    .user-bar .btn:focus,
    .user-bar .btn.active {
      text-decoration: none;
      color: #92C5EB; }
  .user-bar .dropdown-toggle {
    display: inline-block;
    width: 100%; }
  .user-bar .dropdown-menu {
    background-color: rgba(42, 137, 207, 0.8);
    border: 0 none;
    border-radius: 0;
    color: #fff;
    margin: 0;
    min-width: 100%;
    padding: 15px;
    text-align: center;
    width: auto; }
    .user-bar .dropdown-menu .divider {
      background-color: rgba(255, 255, 255, 0.1);
      margin-bottom: 5px;
      margin-top: 5px; }
    .user-bar .dropdown-menu > li > a {
      background-color: transparent;
      color: #fff; }
      .user-bar .dropdown-menu > li > a:hover, .user-bar .dropdown-menu > li > a:active, .user-bar .dropdown-menu > li > a:focus {
        background-color: transparent;
        text-decoration: underline; }
  .user-bar .dropdown-custom__content > :first-child {
    margin-top: 0; }
  .user-bar .dropdown-custom__content > :last-child {
    margin-bottom: 0; }
  .user-bar .dropdown-custom__content .info {
    text-align: right; }
  .user-bar .dropdown-custom__link {
    border: 3px solid #92C5EB;
    border-radius: 3.5rem;
    color: #92C5EB !important;
    display: block;
    font-weight: 700;
    height: 3.5rem;
    line-height: 3rem;
    margin: 5px 0;
    padding: 0 15px;
    text-decoration: none;
    white-space: nowrap; }
    .user-bar .dropdown-custom__link:hover, .user-bar .dropdown-custom__link:focus {
      border-color: #d2e5f4;
      color: #d2e5f4 !important; }

.step-progress {
  font-size: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; }
  .step-progress__item {
    flex: 0 1 50%;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    font-weight: 400; }
    .step-progress__item > * {
      color: rgba(0, 0, 0, 0.3);
      display: block;
      padding-bottom: 1.25em; }
    .step-progress__item::before, .step-progress__item::after {
      background-color: #dedcdc;
      bottom: 0;
      content: '';
      overflow: hidden;
      position: absolute;
      top: auto;
      white-space: nowrap; }
    .step-progress__item::before {
      border: 2px solid #fff;
      border-radius: 1em;
      bottom: -0.3125rem;
      height: 0.875rem;
      left: 0;
      text-indent: 150%;
      width: 0.875rem;
      z-index: 3; }
    .step-progress__item::after {
      height: 0.25rem;
      left: 7px;
      right: 0;
      z-index: 2; }
    .step-progress__item.-done > * {
      color: #807f7f; }
    .step-progress__item.-done::before, .step-progress__item.-done::after {
      background-color: #807f7f; }
    .step-progress__item.-active > * {
      color: #2A89CF; }
    .step-progress__item.-active::before {
      background-color: #2A89CF; }

/* =============================================================================
 *
 * Formulare
 *
 * ========================================================================== */
.form .form-hint, .form .form-hint-default {
  background-color: rgba(128, 127, 127, 0.25);
  color: #807f7f; }

.form .form-hint-info, .form .form-hint-required {
  background-color: rgba(70, 166, 177, 0.25);
  color: #46a6b1; }

.form .form-hint-warning {
  background-color: rgba(243, 187, 91, 0.25);
  color: #f3bb5b; }

.form .form-hint-danger {
  background-color: rgba(234, 75, 119, 0.25);
  color: #ea4b77; }

.form .form-hint-success {
  background-color: rgba(42, 137, 207, 0.25);
  color: #2A89CF; }

.form.form-box-style {
  background-color: #f4f4f4;
  padding: 25px;
  margin-bottom: 45px; }
  .form.form-box-style .form-control {
    background-color: #fff; }
  .form.form-box-style .bootstrap-select .dropdown-toggle, .form.form-box-style .bootstrap-select .dropdown-toggle:focus {
    background-color: #fff; }
  .form.form-box-style .bootstrap-select.open > .dropdown-toggle {
    background-color: #fff; }
  .form.form-box-style .form-submit-ctrl > .btn {
    min-width: 130px; }

.form-searchbox .form-heading {
  margin: 0 0 20px;
  white-space: nowrap; }

.form-searchbox .input-group .input-group-btn {
  padding-left: 30px; }

.form-searchbox .input-group .form-control {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.form-searchbox .input-group .btn {
  border-bottom-left-radius: 56px;
  border-top-left-radius: 56px;
  font-size: 1.125rem;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px; }
  .form-searchbox .input-group .btn > i {
    display: none; }

/* =============================================================================
 *
 * Artikel
 *
 * ========================================================================== */
.article {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;
  /**
	 * Text, Logo
	 */
  /**
	 * Einleitung Startseite
	 */ }
  .article > * {
    align-self: stretch;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    padding: 0; }
    .article > * > *:first-child {
      margin-top: 0; }
    .article > * > *:last-child {
      margin-bottom: 0; }
  .article__text {
    flex-basis: calc(75% - 15px);
    margin-right: 15px;
    min-width: calc(75% - 15px);
    order: 1;
    width: calc(75% - 15px); }
    .article__text:first-child:last-child {
      flex-basis: 100%;
      margin-right: 0;
      min-width: 100%;
      width: 100%; }
  .article__media {
    flex-basis: calc(25% - 15px);
    margin-left: 15px;
    min-width: calc(25% - 15px);
    order: 2;
    width: calc(25% - 15px); }
    .article__media > * {
      display: block;
      max-width: none;
      min-height: 100%; }
  .article.-client-info {
    /*
		 * Schluessel existiert in der Map
		 */
    /*
		 * Schluessel existiert in der Map
		 */
    /*
		 * Schluessel existiert in der Map
		 */ }
    @media only screen and (max-width: 767px) {
      .article.-client-info .article__text:not(:first-child:last-child) {
        flex-basis: calc(70% - 15px);
        min-width: calc(70% - 15px);
        width: calc(70% - 15px); }
      .article.-client-info .article__media {
        margin-top: 15px;
        flex-basis: calc(30% - 15px);
        min-width: calc(30% - 15px);
        width: calc(30% - 15px); } }
    @media only screen and (max-width: 640px) {
      .article.-client-info .article__text:not(:first-child:last-child) {
        flex-basis: 100%;
        margin: 30px 0 0;
        min-width: 100%;
        order: 2;
        width: 100%; }
      .article.-client-info .article__media {
        flex-basis: 40%;
        min-width: 40%;
        order: 1;
        width: 40%; } }
    @media only screen and (max-width: 420px) {
      .article.-client-info .article__media {
        flex-basis: 60%;
        min-width: 60%;
        width: 60%; } }
    .client-display {
      display: none;
      margin: 15px 0; }
      .client-display > [data-client] {
        border-top: 1px solid #eee;
        display: none;
        margin: 0;
        padding: 30px 0 15px; }
        .client-display > [data-client] > * {
          margin: 0; }
  .article.-intro .article__text {
    flex-basis: calc(60% - 15px);
    min-width: calc(60% - 15px);
    width: calc(60% - 15px);
    /*
		 * Schluessel existiert in der Map
		 */ }
    @media only screen and (max-width: 767px) {
      .article.-intro .article__text {
        flex-basis: 100%;
        margin: 0;
        min-width: 100%;
        width: 100%; } }
  .article.-intro .article__media {
    flex-basis: calc(40% - 15px);
    min-width: calc(40% - 15px);
    width: calc(40% - 15px);
    /*
		 * Schluessel existiert in der Map
		 */
    /*
		 * Schluessel existiert in der Map
		 */ }
    @media only screen and (max-width: 767px) {
      .article.-intro .article__media {
        flex-basis: 60%;
        margin: 30px 0 0;
        min-width: 60%;
        width: 60%; } }
    @media only screen and (max-width: 640px) {
      .article.-intro .article__media {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%; } }

/*
 * Module.
 */
/**
 * Module
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Box mit weißem Hintergrund
 */
.box {
  align-content: flex-start;
  align-items: stretch;
  background-color: #fff;
  border: 2px solid #dedcdc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 1rem;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
  /*
		 * Schluessel existiert in der Map
		 */ }
  @media only screen and (max-width: 767px) {
    .box {
      max-width: none; } }
  .box > form {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;
    min-height: 100%;
    padding: 0; }
  .box__header, .box__footer {
    order: 0;
    flex: 0 1 auto;
    align-self: auto; }
  .box__header {
    padding: 30px;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box__header > * {
      margin-top: 15px; }
      .box__header > *:first-child {
        margin-top: 0; }
      .box__header > *:last-child {
        margin-bottom: 0; }
    .box__header + .box__body {
      padding-top: 0; }
    @media only screen and (max-width: 767px) {
      .box__header {
        padding: 15px; } }
  .box__body {
    align-self: auto;
    flex: 1 0 auto;
    order: 0;
    padding: 30px;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box__body > :first-child {
      margin-top: 0; }
    .box__body > :last-child {
      margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .box__body {
        padding: 15px; } }
  .box__footer {
    background-color: transparent;
    padding: 15px 30px;
    text-align: center;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box__footer:before, .box__footer:after {
      content: ' ';
      /* 1 */
      display: table;
      /* 2 */ }
    .box__footer [data-btn-prev] {
      float: left; }
    .box__footer [data-btn-next] {
      float: right; }
    @media only screen and (max-width: 767px) {
      .box__footer {
        padding-left: 15px;
        padding-right: 15px; } }

.box-stack {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -15px; }
  .box-stack__item {
    order: 0;
    flex: 0 1 50%;
    align-self: stretch;
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box-stack__item > .padding-hack {
      padding: 15px;
      height: 100%; }
      .box-stack__item > .padding-hack > * {
        height: 100%; }
    @media only screen and (max-width: 767px) {
      .box-stack__item {
        flex-basis: 100%;
        width: 100%; } }
  .box-stack.-sidebar-view {
    /*
		 * Schluessel existiert in der Map
		 */ }
    .box-stack.-sidebar-view .box {
      max-width: none; }
    .box-stack.-sidebar-view > *:nth-child(2n+1) {
      flex-basis: 66%; }
    .box-stack.-sidebar-view > *:nth-child(2n) {
      flex-basis: 34%; }
    @media only screen and (max-width: 767px) {
      .box-stack.-sidebar-view > *:nth-child(1n) {
        flex-basis: 100%; } }

/**
 * Global
 */
.module {
  align-content: stretch;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 1rem;
  justify-content: flex-start; }
  .module__header, .module__footer {
    align-self: stretch;
    flex: 0 1 auto; }
  .module__header > .wrap, .module__footer > .wrap, .module__body > .wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0 15px;
    width: 100%; }
  .module__header {
    background-color: #fff;
    border-bottom: 0.25rem solid #dedcdc; }
    .module__header > .wrap {
      padding-bottom: 1.25rem;
      padding-top: 1.25rem; }
      .module__header > .wrap > :first-child {
        margin-top: 0; }
      .module__header > .wrap > :last-child {
        margin-bottom: 0; }
    .module__header .step-progress {
      margin: 0 0 -1.5rem; }
    .module__header .title {
      margin: 0;
      font-size: 1.5625em; }
  .module__footer {
    background-color: rgba(146, 197, 235, 0.2); }
    .module__footer > .wrap {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center; }
  .module__body {
    flex: 1 0 auto;
    align-self: stretch;
    padding-bottom: 60px;
    padding-top: 60px; }
  .module[data-module-blocked] .module__footer {
    background-color: #f4f4f4; }

/**
 * Imports
 */
/**
 * Event-Suche
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Suchformular
 */
.form-event-search {
  padding-bottom: 15px;
  padding-top: 15px; }
  .form-event-search .control-label {
    font-size: 0.875em; }
  .section.-dark .form-event-search .form-control {
    background-color: #fff;
    border-color: #fff; }

/**
 * Auflistung Events
 */
.module-event-search .event-stack__item {
  flex-basis: 50%;
  min-width: 50%;
  width: 50%;
  /*
		 * Schluessel existiert in der Map
		 */ }
  @media only screen and (max-width: 767px) {
    .module-event-search .event-stack__item {
      flex-basis: 100%;
      min-width: 100%;
      width: 100%; } }

/**
 * Multisteps
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Auswahl Kategorie
 */
[data-module-steps="select-category"] .categories {
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: -15px; }
  [data-module-steps="select-category"] .categories__item {
    align-self: center;
    flex: 0 1 auto;
    margin: 0;
    order: 0;
    padding: 0; }
    [data-module-steps="select-category"] .categories__item::before {
      display: none; }
    [data-module-steps="select-category"] .categories__item > .padding-hack {
      padding: 15px; }
    [data-module-steps="select-category"] .categories__item .btn {
      display: block; }

[data-module-steps="select-direction"] .form-group {
  margin-bottom: 0; }

[data-module-steps="select-direction"] .cert-info {
  font-size: 0.875em;
  margin: 30px -30px 0;
  padding: 0; }
  [data-module-steps="select-direction"] .cert-info__item {
    border-top: 1px solid #f4f4f4;
    display: none;
    margin: 0;
    padding: 15px 30px 0; }
    [data-module-steps="select-direction"] .cert-info__item::before {
      display: none; }
    [data-module-steps="select-direction"] .cert-info__item * {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 1em; }
      [data-module-steps="select-direction"] .cert-info__item *:first-child {
        margin-bottom: 0; }
      [data-module-steps="select-direction"] .cert-info__item *:last-child {
        margin-bottom: 0; }

/**
 * Qualifikationrn
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
[data-module-user="qualification"] .collapse-panel-content {
  padding-top: 30px;
  position: relative; }

[data-module-user="qualification"] .collapse [data-ctrl-qualification-remove] {
  background: none;
  border: 0;
  color: #605f5f;
  font-size: 1.3125em;
  height: auto;
  line-height: 1.25;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px; }

/**
 * Warenkorb
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.order-stack {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -15px; }
  .order-stack__item {
    order: 0;
    flex: 0 1 100%;
    align-self: stretch; }
    .order-stack__item > .padding-hack {
      padding: 15px; }
  .order-stack .event {
    background-color: #f8f8f8;
    border: 3px solid #f4f4f4; }
    .order-stack .event__body .title {
      padding-right: 20px; }
    .order-stack .event .alert {
      border: 0;
      padding: 5px 10px;
      font-size: .75em;
      font-weight: normal; }
    .order-stack .event .btn[data-control="detail"] {
      background-color: #807f7f;
      border-color: #807f7f;
      color: #000; }
      .order-stack .event .btn[data-control="detail"]:hover, .order-stack .event .btn[data-control="detail"]:active, .order-stack .event .btn[data-control="detail"]:focus {
        background-color: #949393;
        border-color: #949393;
        color: #000; }
    .order-stack .event[data-is-booked], .order-stack .event[data-is-active] {
      background-color: rgba(42, 137, 207, 0.05);
      border-color: rgba(42, 137, 207, 0.3); }
      .order-stack .event[data-is-booked] .btn[data-control="detail"], .order-stack .event[data-is-active] .btn[data-control="detail"] {
        background-color: #2A89CF;
        border-color: #2A89CF;
        color: #fff; }
        .order-stack .event[data-is-booked] .btn[data-control="detail"]:hover, .order-stack .event[data-is-booked] .btn[data-control="detail"]:active, .order-stack .event[data-is-booked] .btn[data-control="detail"]:focus, .order-stack .event[data-is-active] .btn[data-control="detail"]:hover, .order-stack .event[data-is-active] .btn[data-control="detail"]:active, .order-stack .event[data-is-active] .btn[data-control="detail"]:focus {
          background-color: #489cda;
          border-color: #489cda;
          color: white; }
    .order-stack .event[data-is-waiting] {
      background-color: rgba(243, 187, 91, 0.05);
      border-color: rgba(243, 187, 91, 0.3); }
      .order-stack .event[data-is-waiting] .btn[data-control="detail"] {
        background-color: #f3bb5b;
        border-color: #f3bb5b;
        color: #000; }
        .order-stack .event[data-is-waiting] .btn[data-control="detail"]:hover, .order-stack .event[data-is-waiting] .btn[data-control="detail"]:active, .order-stack .event[data-is-waiting] .btn[data-control="detail"]:focus {
          background-color: #f6cb81;
          border-color: #f6cb81;
          color: #212121; }
    .order-stack .event[data-is-remove-time] {
      background-color: rgba(234, 75, 119, 0.05);
      border-color: rgba(234, 75, 119, 0.3); }
      .order-stack .event[data-is-remove-time] .btn[data-control="detail"] {
        background-color: #ea4b77;
        border-color: #ea4b77;
        color: #000; }
        .order-stack .event[data-is-remove-time] .btn[data-control="detail"]:hover, .order-stack .event[data-is-remove-time] .btn[data-control="detail"]:active, .order-stack .event[data-is-remove-time] .btn[data-control="detail"]:focus {
          background-color: #ee7093;
          border-color: #ee7093;
          color: #212121; }
    .order-stack .event[data-is-removed] {
      opacity: 0.5; }
  .order-stack [data-order-remove] {
    color: #92C5EB;
    cursor: pointer;
    font-size: 1.3125em;
    line-height: 1.25;
    position: absolute;
    right: 15px;
    top: 15px; }
  .order-stack .checkbox,
  .order-stack .radio {
    margin: 0; }
    .order-stack .checkbox-styled > label::before,
    .order-stack .radio-styled > label::before {
      background-color: #fff; }

[data-module-basket="empty"] .box__footer {
  text-align: left; }

/*!
 * Ansichten.
 */
/**
 * Ansichtenimport
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/**
 * Startseite
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.view-error {
  align-content: center;
  align-items: flex-start;
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 30px 15px 15px; }
  .view-error::before {
    background-color: #92C5EB;
    background-image: url(../images/backgrounds/breadcrumb.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 15px;
    left: 0;
    right: 0;
    position: fixed;
    top: 0; }
  .view-error .box {
    align-self: center;
    flex: 0 1 auto;
    max-width: 400px;
    width: 100%; }

@media screen and (max-height: 420px) {
  .view-error .box {
    align-self: start; } }

/**
 * Startseite
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
.view-index .section.-type-events .heading {
  margin-top: 60px; }
  .view-index .section.-type-events .heading:first-child {
    margin-top: 0; }

.view-index .section.-type-events .event {
  border: 3px solid #f4f4f4; }

/*!
 * Mediaqueries.
 */
/**
 * Large Devices, Wide Screens.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (min-width: 1200px) {
  #dev > .mq > .lg {
    display: inline-block; }
  .display-lg-block {
    display: block; }
  /* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */
  .checkbox-inline.display-lg-block,
  .radio-inline.display-lg-block {
    margin-left: 0; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
  /* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
  /* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */ }

/**
 * Medium Devices, Desktops.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (max-width: 1199px) {
  #dev > .mq > .md {
    display: inline-block; }
  .display-md-block {
    display: block; }
  /* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */
  .checkbox-inline.display-md-block,
  .radio-inline.display-md-block {
    margin-left: 0; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
  /* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
  /* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */ }

/**
 * Small Devices, Tablets.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (max-width: 991px) {
  #dev > .mq > .sm {
    display: inline-block; }
  .display-sm-block {
    display: block; }
  .nav-meta,
  .nav-primary {
    display: none; }
  /* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */
  .mm-menu.mm-offcanvas {
    width: 50%;
    max-width: none; }
  /* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */
  .checkbox-inline.display-sm-block,
  .radio-inline.display-sm-block {
    margin-left: 0; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ----------------------------------------------------------------------

	.gallery[data-bind="gallery-showcase"] .swiper-slide {}*/
  /* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
  .googlemap:not([class*="responsive-"]) {
    height: 400px; }
  /* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
  .main-page > .main {
    width: 100%; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_header'
	 * ---------------------------------------------------------------------- */
  .main-header {
    font-size: .75rem;
    padding: 15px 0; }
    .main-header .logo.-site {
      font-size: 5rem; }
    .main-header .logo.-client {
      font-size: 6.5rem; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
  .main-showcase > .bar > .bar-inner > .col-gallery {
    width: 100%; }
  .main-showcase > .bar-p {
    padding-top: 0; }
  .main-showcase > .bar-s .col-gallery {
    margin-top: -30px; }
  .main-showcase .gallery-image-parts .swiper-pagination {
    bottom: 45px;
    padding-right: 15px; }
  .main-showcase .gallery-image-parts .thumbnail > picture.image {
    padding-bottom: 56.25%; }
  .main-showcase .gallery-text-parts .swiper-slide-wrapper {
    padding-left: 15px;
    padding-right: 15px; }
  .main-showcase .gallery-text-parts .article {
    padding: 20px; }
  /* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */
  /* =========================================================================
	 *
	 * Views
	 *
	 * ====================================================================== */ }

/**
 * Extra Small Devices, Phones.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (max-width: 767px) {
  #dev > .mq > .xs {
    display: inline-block; }
  .display-xs-block {
    display: block; }
  /* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */
  .mm-menu.mm-offcanvas {
    width: 70%; }
  .fb-custom .fancybox-slide--iframe > .fancybox-content {
    height: 98% !important;
    width: 96% !important; }
  /* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */
  .form-group > .col-xs-12 {
    margin-bottom: 15px; }
    .form-group > .col-xs-12:last-child {
      margin-bottom: 0; }
  .checkbox-inline.display-xs-block,
  .radio-inline.display-xs-block {
    margin-left: 0; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_buttons'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_pagination'
 	 * ---------------------------------------------------------------------- */
  .nav-pagination,
  .pagination {
    text-align: center; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
  .googlemap:not([class*="responsive-"]) {
    height: 300px; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_articles'
	 * ---------------------------------------------------------------------- */
  .article .edited,
  .article .datetime {
    font-size: .9375em; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_stacks'
	 * ---------------------------------------------------------------------- */
  .stack .stack-item .col-xs-12 {
    margin-bottom: 60px 0; }
    .stack .stack-item .col-xs-12:last-child {
      margin-bottom: 0; }
  /* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
  .main-page {
    padding-bottom: 0; }
    .main-page > .main > .content,
    .main-page > .main > .aside {
      display: block;
      float: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%; }
    .main-page > .main > .content {
      margin-bottom: 60px; }
    .main-page > .main > .aside {
      background-color: #f8f8f8;
      margin-left: -15px;
      margin-right: -15px;
      padding: 45px 0;
      width: auto; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
	 * ---------------------------------------------------------------------- */
  .aside > * {
    float: left;
    margin: 15px 0;
    padding: 0 15px;
    position: relative;
    width: 33.3333333333%; }
    .aside > *, .aside > *:first-child {
      margin: 15px 0; }
    .aside > *:nth-child(3n+1) {
      clear: both; }
  .aside .article {
    background-color: #fff;
    height: 100%; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_elements'
	 * ---------------------------------------------------------------------- */
  /* Formulare */
  .form-searchbox .input-group .btn {
    padding-left: 0;
    padding-right: 0;
    width: 56px; }
    .form-searchbox .input-group .btn > .btn-text {
      display: none; }
    .form-searchbox .input-group .btn > i {
      display: block; }
  /* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */
  /* =========================================================================
	 *
	 * Typo3
	 *
	 * ====================================================================== */ }

/**
 * Eigener Custom-Breakpoints (640px).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (max-width: 640px) {
  #dev > .mq > .c640 {
    display: inline-block; }
  .display-c640-block {
    display: block; }
  .hidden-c640 {
    display: none; }
  .col-c640-1, .col-c640-2, .col-c640-3, .col-c640-4, .col-c640-5, .col-c640-6, .col-c640-7, .col-c640-8, .col-c640-9, .col-c640-10, .col-c640-11, .col-c640-12 {
    float: left;
    margin-left: 0;
    margin-right: 0;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
  .col-c640-1 {
    width: 8.33333%; }
  .col-c640-2 {
    width: 16.66667%; }
  .col-c640-3 {
    width: 25%; }
  .col-c640-4 {
    width: 33.33333%; }
  .col-c640-5 {
    width: 41.66667%; }
  .col-c640-6 {
    width: 50%; }
  .col-c640-7 {
    width: 58.33333%; }
  .col-c640-8 {
    width: 66.66667%; }
  .col-c640-9 {
    width: 75%; }
  .col-c640-10 {
    width: 83.33333%; }
  .col-c640-11 {
    width: 91.66667%; }
  .col-c640-12 {
    width: 100%; }
  /* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */
  .mm-menu.mm-offcanvas {
    width: 100%; }
  /* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */
  .checkbox-inline.display-c640-block,
  .radio-inline.display-c640-block {
    margin-left: 0; }
  /* Formulartypen */
  .form-horizontal .control-label,
  .form-horizontal .radio,
  .form-horizontal .checkbox,
  .form-horizontal .radio-inline,
  .form-horizontal .checkbox-inline {
    padding-top: 8px; }
  .form-horizontal .form-group-lg .control-label {
    padding-top: 18px; }
  .form-horizontal .form-group-sm .control-label {
    padding-top: 8px; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_navs'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_paginations'
 	 * ---------------------------------------------------------------------- */
  .pagination > li.prev > a > span,
  .pagination > li.next > a > span {
    display: none; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_videos-audio'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
  /* Tabellenliste (dt = zeilenkopf, dd = Zeileninhalt) */
  .list-table > * {
    float: none;
    padding-left: 15px;
    padding-right: 15px; }
  .list-table > dt,
  .list-table > li:nth-child(2n+1) {
    padding-bottom: 10px;
    width: 100%; }
  .list-table > dd,
  .list-table > li:nth-child(2n+2) {
    border-top: 0 none;
    padding-top: 0;
    width: 100%; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
  /* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */
  .nav-primary-mobile.mm-menu {
    border-right: 0 none; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
  .main-header .logo.-site {
    font-size: 4rem; }
  .main-header .logo.-client {
    font-size: 5.5rem; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */
  .main-footer dl.sponsors-supporters {
    clear: both;
    float: none;
    width: 100%; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */
  .aside > * {
    width: 50%; }
    .aside > *:nth-child(3n+1) {
      clear: none; }
    .aside > *:nth-child(2n+1) {
      clear: both; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
  .main-showcase > .bar-s .col-gallery {
    margin-top: 0; }
  .main-showcase .gallery-text-parts .swiper-slide-wrapper {
    padding: 0; }
  .main-showcase .gallery-text-parts .article > .article-header, .main-showcase .gallery-text-parts .article > .article-body {
    float: none;
    width: 100%;
    padding: 0; }
  .main-showcase .gallery-text-parts .article > .article-header {
    margin-bottom: 10px; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_elements'
	 * ---------------------------------------------------------------------- */
  .user-bar__col > * {
    margin-right: 20px; }
  .user-bar__col.-menu .link > i {
    background-color: #807f7f;
    color: #fff;
    border-radius: 1em;
    display: block;
    padding: .5em 0;
    text-align: center;
    width: 2em; }
  .user-bar__col.-menu .link > .btn__text {
    display: none; }
  /* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */
  /* =========================================================================
	 *
	 * Views
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'views/index/_index'
	 * ---------------------------------------------------------------------- */ }

/**
 * Eigener Custom-Breakpoints (420px).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
/*
		 * Schluessel existiert in der Map
		 */
@media only screen and (max-width: 420px) {
  #dev > .mq > .c420 {
    display: inline-block; }
  .display-c420-block {
    display: block; }
  .hidden-c420 {
    display: none; }
  .col-c420-1, .col-c420-2, .col-c420-3, .col-c420-4, .col-c420-5, .col-c420-6, .col-c420-7, .col-c420-8, .col-c420-9, .col-c420-10, .col-c420-11, .col-c420-12 {
    float: left;
    margin-left: 0;
    margin-right: 0;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
  .col-c420-1 {
    width: 8.33333%; }
  .col-c420-2 {
    width: 16.66667%; }
  .col-c420-3 {
    width: 25%; }
  .col-c420-4 {
    width: 33.33333%; }
  .col-c420-5 {
    width: 41.66667%; }
  .col-c420-6 {
    width: 50%; }
  .col-c420-7 {
    width: 58.33333%; }
  .col-c420-8 {
    width: 66.66667%; }
  .col-c420-9 {
    width: 75%; }
  .col-c420-10 {
    width: 83.33333%; }
  .col-c420-11 {
    width: 91.66667%; }
  .col-c420-12 {
    width: 100%; }
  /* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */
  /* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */
  .checkbox-inline.display-c420-block,
  .radio-inline.display-c420-block {
    margin-left: 0; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_collapse'
 	 * ---------------------------------------------------------------------- */
  .tab-to-collapse .nav-tabs {
    display: none; }
  .tab-to-collapse .toggle-tab-collapse {
    display: block; }
  /* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_articles'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'components/_vcards'
	 * ---------------------------------------------------------------------- */
  .vcard {
    max-width: none; }
    .vcard-group > .vcard-group-item {
      float: none;
      width: 100%; }
  /* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
  .main-header .logo.-site {
    font-size: 2.5rem; }
  .main-header .logo.-client {
    font-size: 4rem; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */
  .aside > * {
    width: 100%; }
  /* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------
	 * @ 'core/_elements'
	 * ---------------------------------------------------------------------- */
  .user-bar .dropdown {
    position: static; }
    .user-bar .dropdown-menu {
      left: 15px;
      right: 15px;
      min-width: 0; }
  /* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */
  /* =========================================================================
	 *
	 * Views
	 *
	 * ====================================================================== */
  /* -------------------------------------------------------------------------
	 * @ 'views/index/_index'
	 * ---------------------------------------------------------------------- */ }
