/**
 * Showcase
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

.main-showcase {
	$-slider-image-max-height        : 640px;
	$-slider-image-pagination-offset : $gap-base;
	$-slider-text-offset             : ($gap-base-large * 2);

	margin-bottom : $gap-base-large;

	> .bar {
		> .bar-inner {
			display         : flex;
			flex-flow       : row nowrap;
			justify-content : flex-end;
			position        : relative;
			margin-left     : auto;
			margin-right    : auto;
			max-width       : $website-max-width;
			width           : $website-default-width;

			> * {
				flex       : 0 1 auto;
				width      : 25%;
			}

			> .col-gallery {

				width      : 75%;
			}
		}

		.nav {
			align-self    : center;
			padding-left  : $gap-base;
			padding-right : ($gap-base-large * 2) - $gap-base;
		}

		.gallery { margin : 0; }

		&-p {
			background      : transparent url(../images/backgrounds/showcase.jpg) no-repeat top center;
			background-size : cover;
			padding-top : ($gap-base-large * 2) - $gap-base;
		}

		&-s {
			.col-gallery {
				margin-top : -#{$-slider-text-offset};
			}
		}
	}

	// Interne Links bekommen ein anders Icon.
	.link-intern::before { content : "\f138"; }

	.gallery-image-parts {
		background-color : rgba($color-black, 0.2);

		.swiper {
			&-slide {
				background-color : $brand-tertiary;
				max-height       : $-slider-image-max-height;
			}

			&-pagination {
				bottom        : ($-slider-image-pagination-offset + $-slider-text-offset);
				padding-right : $gap-base-large;
				text-align    : right;
			}
		}

		.thumbnail > picture.image {
			padding-bottom: 66%;

			> img {
				height : auto;
				width  : 100%;
			}
		}
	}

	.gallery-text-parts {
		.swiper-slide-wrapper {
			padding-left  : $gap-base-large;
			padding-right : $gap-base-large;
		}

		.article {
			background-color : $brand-primary;
			color            : $color-white;
			padding          : $gap-base-large;

			> .article {
				&-header,
				&-body {
					background-color : transparent;
					float            : left;
					margin           : 0;
					padding          : 0;
					width            : 55%;

					* { color : $color-white; }
				}

				&-header {
					padding-right : $gap-base;
					width         : 45%;
				}
			}

			.subtitle {
				margin-bottom : $gap-base;
				font-weight : $font-weight-semibold;
			}

			.title {
				font-weight : $h1-font-weight;
				font-size   : cast-unit($font-unit-base, 30px);
			}

			a.link::before { content : "\f138"; }

			p {
				margin-bottom : $gap-base-small;
				margin-top    : $gap-base-small;

				&:last-child { margin : 0; }
			}
		}
	}
}