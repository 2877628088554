/**
 * Multisteps
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Auswahl Kategorie
 */

[data-module-steps="select-category"] {
	.categories {
		align-content   : flex-start;
		align-items     : center;
		display         : flex;
		flex-direction  : row;
		flex-wrap       : wrap;
		justify-content : center;
		margin          : -#{$gap-base};

		&__item {
			align-self : center;
			flex       : 0 1 auto;
			margin     : 0;
			order      : 0;
			padding    : 0;

			&::before { display : none; }

			> .padding-hack {
				padding : $gap-base;
			}

			.btn {
				display : block;
			}
		}
	}
}

[data-module-steps="select-direction"] {
	.form-group {
		margin-bottom : 0;
	}

	.cert-info {
		font-size : cast-unit('em', $font-size-small);
		margin    : $gap-base-large -#{$gap-base-large} 0;
		padding   : 0;

		&__item {
			border-top : 1px solid $color-gray-light;
			display    : none;
			margin     : 0;
			padding    : $gap-base $gap-base-large 0;

			&::before {
				display : none;
			}

			* {
				margin-top    : $gap-base-xsmall;
				margin-bottom : $gap-base-xsmall;
				font-size     : 1em;

				&:first-child {
					margin-bottom : 0;
				}

				&:last-child {
					margin-bottom : 0;
				}
			}
		}
	}
}